import {useEffect, useState} from "react";
import Nav, {ContentWrapper} from "../components/layouts/Nav";
import LoadingIndicator from "../components/LoadingIndicator";
import {SectionHeader} from "../components/Typography";
import {Analysis, AnalysisDescription} from "../types";
import {GetAnalysisDescriptions, GetMySamples} from "../api";
import Time from "../components/Time";
import styles from "./samples.module.css";
import Box from "../components/Box";
import clsx from "clsx";
import {ChevronRight} from "../Svg";

interface AnalysisWithDescription extends Analysis, AnalysisDescription {
  drawTime: string;
  parsedValue: number;
  parsedValueIsLte: boolean;
  valueStatus: "low" | "high" | "normal" | "no-reference";
}

const Samples = () => {
  const [analysisSummaries, setAnalysisSummaries] = useState<
    AnalysisWithDescription[] | null
  >(null);

  const loadAnalysisSummaries = async () => {
    const samples = await GetMySamples();
    const analysesDescriptions = await GetAnalysisDescriptions();
    const analyses: Record<string, AnalysisWithDescription> = {};
    samples.forEach((sample) => {
      sample.analyses.forEach((analysis) => {
        if (!analyses[analysis.name]) {
          const parsedValue = Number.parseFloat(
            analysis.value.replace("<", "")
          );
          const parsedValueIsLte = analysis.value[0] === "<";
          if (isNaN(parsedValue)) {
            return;
          }
          const description = analysesDescriptions.find(
            (ad) => ad.name === analysis.name
          );
          if (!description || description.visible === false) {
            return;
          }
          analyses[analysis.name] = {
            ...analysis,
            ...description,
            drawTime: sample.drawTime,
            parsedValue,
            parsedValueIsLte,
            valueStatus:
              description.referenceValuesVisible === false
                ? "no-reference"
                : parsedValue > description.referenceValueHigh
                ? "high"
                : parsedValue < description.referenceValueLow
                ? "low"
                : "normal",
          };
        }
      });
    });
    setAnalysisSummaries(Object.keys(analyses).map((k) => analyses[k]));
  };

  useEffect(() => {
    loadAnalysisSummaries();
  }, []);

  const navigateToAnalysis = async (as: AnalysisWithDescription) => {
    window.location.href = "/samples/" + as.testMethodCode;
  };

  if (analysisSummaries === null) {
    return (
      <Nav highlightedSection="quiz">
        <LoadingIndicator />
      </Nav>
    );
  }

  return (
    <Nav highlightedSection="quiz">
      <ContentWrapper>
        <SectionHeader>Blodprover</SectionHeader>
        {analysisSummaries.length === 0 && <p>Du har inga blodprover ännu</p>}
        {analysisSummaries.length > 0 && (
          <>
            {analysisSummaries.map((as) => (
              <div
                key={as.name}
                className={styles.analysisSummary}
                onClick={() => navigateToAnalysis(as)}>
                <div className={styles.analysisSummaryHeader}>
                  <h3>{as.name}</h3>
                  <Time dateTime={as.drawTime} options={{dateStyle: "short"}} />
                </div>
                <Box noHeaderColor noPadding>
                  <div className={styles.columnContainer}>
                    <div className={styles.valueColumn}>
                      <div
                        className={clsx(styles.valueIndicatorBall, {
                          [styles.high]: as.valueStatus === "high",
                          [styles.low]: as.valueStatus === "low",
                          [styles.normal]: as.valueStatus === "normal",
                          [styles.noReference]:
                            as.valueStatus === "no-reference",
                        })}></div>
                      {as.parsedValueIsLte ? "<" : ""}
                      {as.parsedValue}{" "}
                      <span dangerouslySetInnerHTML={{__html: as.unit}}></span>
                    </div>
                    <div className={styles.referenceValueColumn}>
                      {as.referenceValuesVisible && (
                        <>
                          {as.referenceValueLow}-{as.referenceValueHigh}{" "}
                          <span
                            dangerouslySetInnerHTML={{__html: as.unit}}></span>
                        </>
                      )}
                    </div>
                    <div className={styles.indicatorColumn}>
                      {as.valueStatus === "high" && "Högt"}
                      {as.valueStatus === "low" && "Lågt"}
                      {as.valueStatus === "normal" && "Normalt"}
                      <ChevronRight />
                    </div>
                  </div>
                </Box>
              </div>
            ))}

            <div className={styles.legend}>
              <div className={styles.legendItem}>
                <div
                  className={clsx(styles.valueIndicatorBall, {
                    [styles.high]: true,
                  })}></div>
                Utanför referensvärde
              </div>

              <div className={styles.legendItem}>
                <div
                  className={clsx(styles.valueIndicatorBall, {
                    [styles.normal]: true,
                  })}></div>
                Inom referensvärde
              </div>

              <div className={styles.legendItem}>
                <div
                  className={clsx(styles.valueIndicatorBall, {
                    [styles.noReference]: true,
                  })}></div>
                Se beskrivning
              </div>
            </div>
          </>
        )}
      </ContentWrapper>
    </Nav>
  );
};

export default Samples;
