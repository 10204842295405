import React from "react";

export interface ConditionalWrapperProps {
  condition: boolean;
  ifTrue: React.FC;
  ifFalse?: React.FC;
}

export const ConditionalWrapper: React.FC<
  ConditionalWrapperProps & React.HTMLAttributes<HTMLDivElement>
> = ({condition, ifTrue, ifFalse, children, ...rest}) => {
  const f: React.FC = condition
    ? ifTrue
    : ifFalse
    ? ifFalse
    : ({children}) => <>{children}</>;
  return <div {...rest}>{f({children})}</div>;
};
