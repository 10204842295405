import clsx from "clsx";
import React from "react";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {Button} from "./Buttons";
import Time from "./Time";
import {ChevronRight} from "../Svg";
import {Calendar, DateRange, Slot} from "../types";
import {
  clearTime,
  DayNamesShort,
  buildRanges,
  Milliseconds,
  millisecondsFromNanos,
  mobileBreakpoint,
} from "../util";
import styles from "./slotPicker.module.css";
import {ConditionalWrapper} from "./ConditionalWrapper";

export interface SlotPickerProps {
  calendar: Calendar;
  slots: Slot[];
}

export const SlotPicker: React.VFC<SlotPickerProps> = ({calendar, slots}) => {
  const hourString = (date: Date): string => {
    return `${date.getHours().toString().padStart(2, "0")}:${date
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
  };
  const filteredSlots = slots.filter(
    (slot) => new Date(slot.start).getTime() > new Date().getTime()
  );
  const incrementHasSlots = Object.fromEntries(
    filteredSlots.map((slot) => [hourString(new Date(slot.start)), true])
  );
  const dayHasSlots = Object.fromEntries(
    filteredSlots.map((slot) => [
      clearTime(new Date(slot.start)).toISOString(),
      true,
    ])
  );
  const dateFromRangeAndIncrement = (date: Date, hourString: string) => {
    const copy = new Date(date.getTime());
    const split = hourString.split(":");
    copy.setHours(Number(split[0]));
    copy.setMinutes(Number(split[1]));
    return copy;
  };
  const slotMapKey = (d: string) => {
    return new Date(d).getTime().toString();
  };
  const slotMap = Object.fromEntries(
    filteredSlots.map((slot) => [slotMapKey(slot.start), slot])
  );
  const getSlotFromDateAndIncrement = (range: DateRange, increment: string) => {
    return slotMap[
      slotMapKey(
        dateFromRangeAndIncrement(range.start, increment).toISOString()
      )
    ];
  };
  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  const slotSizeInMs = millisecondsFromNanos(
    calendar.bookingPageConfiguration.increment
  );
  const timeIncrements = buildRanges(
    clearTime(new Date()),
    (24 * Milliseconds.Hour) / slotSizeInMs,
    slotSizeInMs
  )
    .map((range) => hourString(range.start))
    .filter((increment) => incrementHasSlots[increment]);

  const timeIncrementsForRange = (range: DateRange) => {
    return timeIncrements.map((timeIncrement): {hour: string; slot?: Slot} => ({
      hour: timeIncrement,
      slot: getSlotFromDateAndIncrement(range, timeIncrement),
    }));
  };

  const stickyOnScroll = () => {
    if (window.innerWidth <= mobileBreakpoint) {
      return;
    }
    const stickyElements = document.getElementsByClassName(
      styles.stickyOnScroll
    );

    for (let i = 0; i < stickyElements.length; i++) {
      const item = stickyElements.item(i);
      if (!item) {
        continue;
      }
      const parentTop = item.parentElement?.getBoundingClientRect().top;
      if (!parentTop) {
        continue;
      }
      if (parentTop <= 0) {
        stickyElements.item(i)?.classList.add(styles.isSticky);
      } else {
        stickyElements.item(i)?.classList.remove(styles.isSticky);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickyOnScroll);
    return () => {
      window.removeEventListener("scroll", stickyOnScroll);
    };
  });

  const createConfirmationPageLink = (slot: Slot) => {
    const searchParams = new URLSearchParams();
    searchParams.set("reason", calendar.reason);
    searchParams.set("start", slot.start);
    searchParams.set("kind", slot.kind);
    searchParams.set("profileId", slot.profileId);
    calendar.patientProfile &&
      searchParams.set("patientProfileId", calendar.patientProfile.id);
    calendar.booking && searchParams.set("bookingId", calendar.booking.id);
    searchParams.set(
      "returnUrl",
      window.location.pathname + window.location.search
    );
    if (calendar.showProfileInConfirmationPage) {
      searchParams.set("showProfile", "true");
    }
    return `/booking/confirm?${searchParams.toString()}`;
  };

  return (
    <div className={styles.slotPicker}>
      {filteredSlots.length === 0 && (
        <div className={styles.noSlots}>
          <h3>Inga lediga tider</h3>
          <p>Tyvärr är vi fullbokade inom den valda perioden.</p>
          <Button
            outlined
            title={
              <>
                <span style={{marginTop: "-13px", height: "0px"}}>
                  Visa tider längre fram
                </span>{" "}
                <ChevronRight />
              </>
            }
            href={calendar.nextPageURL}
          />
        </div>
      )}
      {calendar.ranges.map((range) => (
        <div
          key={range.start.getTime()}
          className={clsx(styles.slotPickerRange, {
            [styles.todayHighlight]: isToday(range.start),
            [styles.available]:
              dayHasSlots[clearTime(range.start).toISOString()],
          })}>
          <div className={styles.stickyOnScroll}>
            {isToday(range.start) && (
              <span className={styles.todayBadge}>Idag</span>
            )}
            <div className={styles.dateShort}>
              <span className={styles.dayName}>
                {DayNamesShort[range.start.getDay()]}
              </span>
              <span className={styles.dateNumber}>{range.start.getDate()}</span>
            </div>
            <div className={styles.dateLong}>
              <Time
                dateTime={range.start.toISOString()}
                options={{dateStyle: "full", timeStyle: undefined}}></Time>
            </div>
            {!dayHasSlots[clearTime(range.start).toISOString()] && (
              <div className={styles.fullyBookedNotice}>
                {range.start.getTime() - new Date().getTime() >
                30 * Milliseconds.Day
                  ? "Inga tider inlagda ännu"
                  : "Fullbokat"}
              </div>
            )}
          </div>
          <div
            className={clsx(styles.slotList, {
              [styles.todayHighlight]: isToday(range.start),
            })}>
            {timeIncrementsForRange(range).map((timeIncrement) => (
              <ConditionalWrapper
                key={range.start.getTime() + timeIncrement.hour}
                condition={timeIncrement.slot !== undefined}
                ifTrue={({children}) => (
                  <Link
                    className={clsx(styles.slot, {
                      [styles.available]: timeIncrement.slot !== undefined,
                    })}
                    to={createConfirmationPageLink(timeIncrement.slot!)}>
                    {children}
                  </Link>
                )}
                ifFalse={({children}) => (
                  <div
                    className={clsx(styles.slot, {
                      [styles.available]: timeIncrement.slot !== undefined,
                    })}>
                    {children}
                  </div>
                )}>
                {timeIncrement.hour}
              </ConditionalWrapper>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
