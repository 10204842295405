import {useEffect, useState} from "react";
import {GetMySamples, GetQuizResults} from "../api";
import Box from "../components/Box";
import {Button} from "../components/Buttons";
import Nav, {ContentWrapper} from "../components/layouts/Nav";
import LoadingIndicator from "../components/LoadingIndicator";
import {AlternatePromo} from "../components/Promos";
import QuizList from "../components/QuizList";
import {SectionHeader} from "../components/Typography";
import {VisibleIf} from "../components/Util";
import {QuizResults, Sample} from "../types";
import {BloodTest} from "../Svg";

const MyHealth = () => {
  const [quizResults, setQuizResults] = useState<QuizResults[] | null>(null);
  const [samples, setSamples] = useState<Sample[] | null>(null);

  useEffect(() => {
    GetQuizResults().then(setQuizResults);
    GetMySamples().then(setSamples);
  }, []);

  return (
    <Nav highlightedSection="quiz">
      <ContentWrapper>
        <SamplesSection samples={samples} />
        <QuizResultsSection quizResults={quizResults} />
      </ContentWrapper>
    </Nav>
  );
};

const SamplesSection: React.VFC<{samples: Sample[] | null}> = ({samples}) => {
  return (
    <>
      {!samples && <LoadingIndicator />}
      {samples && (
        <>
          <VisibleIf condition={samples.length > 0}>
            <div style={{marginBottom: "20px"}}>
              <AlternatePromo title="Blodprovsresultat">
                <p>Du har resultat från blodprover som du kan se.</p>

                <Button
                  secondary
                  startIcon={<BloodTest />}
                  fullWidth
                  href="/samples"
                  title={`Visa mina resultat`}></Button>
              </AlternatePromo>
            </div>
          </VisibleIf>
        </>
      )}
    </>
  );
};

const QuizResultsSection: React.VFC<{quizResults: QuizResults[] | null}> = ({
  quizResults,
}) => {
  return (
    <>
      {!quizResults && <LoadingIndicator />}
      {quizResults && (
        <>
          <VisibleIf condition={quizResults.length === 0}>
            <AlternatePromo title="Är du i klimakteriet?">
              <p>
                Få en översikt av ditt mående och guide till behandlingar i
                klimakteriet med Womnis självskattningstest.
              </p>
              <Button
                primary
                title="Gör självskattningstestet"
                fullWidth
                centered
                href="https://quiz.womni.se"></Button>
            </AlternatePromo>
          </VisibleIf>
          <VisibleIf condition={quizResults.length > 0}>
            <SectionHeader>Självskattningstest</SectionHeader>
            <p>Här samlas de självskattningstest du tidigare har genomfört.</p>
            <Box noPadding>
              <QuizList quizResults={quizResults} />
            </Box>
          </VisibleIf>
        </>
      )}
    </>
  );
};

export default MyHealth;
