import { useParams } from "react-router-dom";

import useLogger from "../hooks/useLogger";
import { useEffect, useState } from "react";
import LoadingModal from "../components/LoadingModal";
import { QuizResults } from "../types";
import { GetQuizResultsById } from "../api";
import styles from "./quizResults.module.css";
import Box from "../components/Box";
import { ChevronRight } from "../Svg";
import { ContentWrapper } from "../components/layouts/Nav";
import clsx from "clsx";
import treatments from "../resources/treatments.json";
import SubnavPage from "../components/layouts/SubnavPage";
import Time from "../components/Time";

interface Params {
  id: string;
}

const QuizResultsPage = () => {
  const { id } = useParams<Params>();
  const logger = useLogger();
  const [quizResults, setQuizResults] = useState<QuizResults | null>(null);

  useEffect(() => {
    GetQuizResultsById(id).then(setQuizResults).catch(logger.error);
  }, [id, logger]);

  return (
    <SubnavPage backLink="/quiz" title="Självskattningstest för Klimakteriet ">
      <ContentWrapper>
        {quizResults === null && <LoadingModal />}{" "}
        {quizResults && (
          <>
            <Box noPadding>
              <div className={styles.preamble}>
                Testet genomfördes{" "}
                <b>
                  <Time
                    dateTime={quizResults.createdAt}
                    options={{ dateStyle: "long", timeStyle: undefined }}
                  />
                </b>
                <p>
                  Här kommer en sammanställning över hur du rankade ditt mående
                  i Womnis självskattningstest. Klicka på symptomen för att läsa
                  mer om möjliga behandlingar.
                </p>
              </div>
              <SymptomChart quizResults={quizResults} />
            </Box>
          </>
        )}
      </ContentWrapper>
    </SubnavPage>
  );
};

const symptomLabelMap: Record<string, string> = {
  "3.2": "Vallningar",
  "4.2": "Sömnsvårigheter",
  "5.2": "Psykisk ohälsa",
  "6.2": "Symptom från underlivet",
  "7.2": "Hjärtproblem",
  "8.2": "Urinblåsan",
  "9.2": "Sexlust",
  "10.2": "Kroppsförändringar",
  "11.2": "Huvudvärk/Migrän",
  "12.2": "Led/Muskelbesvär",
};

interface Treatment {
  title: string;
  description: string;
  link: string;
}

const answerTreatmentsMap: Record<string, string[]> = {
  "3.2": ["1", "2", "3", "4"],
  "4.2": ["5", "4", "6"],
  "5.2": ["7"],
  "6.2": ["8", "9", "10"],
  "7.2": ["11", "4"],
  "8.2": ["8", "9", "10"],
  "9.2": ["12"],
  "10.2": ["2", "3", "13"],
  "11.2": ["11", "4"],
  "12.2": ["11", "4"],
};

const SymptomChart: React.VFC<{ quizResults: QuizResults }> = ({
  quizResults,
}) => {
  const [expandedAnswers, setExpandedAnswers] = useState<
    Record<string, boolean>
  >({});

  const answerPercent = (k: string): number => {
    const answer = quizResults.answers[k];
    if (!answer || !answer.answer || isNaN(Number(answer.answer))) return 0;
    return Math.round((answer.answer / 7) * 100);
  };

  const getTreatments = (k: string) => {
    if (!answerTreatmentsMap[k]) return [];
    return answerTreatmentsMap[k].map(
      (treatmentId) =>
        (treatments as unknown as Record<string, Treatment>)[treatmentId]
    );
  };

  const toggleExpanded = (k: string) => {
    if (!expandedAnswers[k]) expandedAnswers[k] = true;
    else expandedAnswers[k] = false;
    setExpandedAnswers({ ...expandedAnswers });
  };

  return (
    <>
      <div className={styles.symptomChart}>
        {Object.keys(symptomLabelMap).map((k) => (
          <div className={styles.symptomChartItem} key={k}>
            <div
              className={clsx(styles.clearfix, styles.symptomHeader)}
              onClick={() => toggleExpanded(k)}
            >
              <div
                className={clsx(styles.collapseContainer, {
                  [styles.expanded]: expandedAnswers[k] === true,
                })}
              >
                <ChevronRight />
              </div>
              <h3>{symptomLabelMap[k]}</h3>
              <div className={styles.symptomChartContainer}>
                <div
                  className={styles.symptomChartValue}
                  style={{ width: answerPercent(k) + "%" }}
                ></div>
              </div>
            </div>

            {expandedAnswers[k] && (
              <div className={styles.treatments}>
                {getTreatments(k).map((treatment) => (
                  <div key={treatment.link} className={styles.treatment}>
                    <h4>{treatment.title}</h4>
                    <p>{treatment.description}</p>
                    <a href={treatment.link}>Läs mer här</a>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default QuizResultsPage;
