import Nav, { ContentWrapper } from "../components/layouts/Nav";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import css from "./questionnaire.module.css";
import { Button } from "../components/Buttons";
import { GetQuestionnaire, PostQuestionnaireResponse } from "../api";
import useLogger from "../hooks/useLogger";
import { Questionnaire, QuestionnaireResponse } from "../types";
import QuestionnaireForm from "../components/QuestionnaireForm";
import SubnavPage from "../components/layouts/SubnavPage";
import Box from "../components/Box";

interface Params {
  id: string;
}

const QuestionnairePage = () => {
  const { id } = useParams<Params>();
  const logger = useLogger();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(
    null
  );

  useEffect(() => {
    GetQuestionnaire(id).then(setQuestionnaire);
  }, [id]);

  const [questionnaireResponse, setQuestionnaireResponse] =
    useState<QuestionnaireResponse | null>(null);

  if (!questionnaire) {
    return <Nav highlightedSection="quiz">Ogiltig länk</Nav>;
  }

  const submitQuestionnaire = async (answers: Record<string, string>) => {
    if (!window.confirm("Är du säker på att du vill skicka in dina svar?"))
      return;
    try {
      const questionnaireResponse = await PostQuestionnaireResponse({
        questionnaire,
        answers,
      });
      setQuestionnaireResponse(questionnaireResponse);
    } catch (error) {
      logger.error(error);
      alert("Något gick fel, var god försök igen");
    }
  };

  return (
    <SubnavPage backLink="/" title={questionnaire.title}>
      {!questionnaireResponse && (
        <ContentWrapper>
          <QuestionnaireForm
            questionnaire={questionnaire}
            onSubmit={submitQuestionnaire}
          />
        </ContentWrapper>
      )}
      {questionnaireResponse && (
        <ContentWrapper>
          <Box>
            <h1>Tack</h1>
            <h3>Dina svar är nu inskickade.</h3>
            <div className={css.ctaContainer}>
              <Button href="/" primary title="Gå till startsidan" />
              <Button
                outlined
                href={`/questionnaire-responses/${questionnaireResponse.id}`}
                title="Ändra dina svar"
              />
            </div>
          </Box>
        </ContentWrapper>
      )}
    </SubnavPage>
  );
};

export default QuestionnairePage;
