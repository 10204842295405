import { ChevronLeft, ChevronRight } from "../../Svg";
import { Button, ButtonGroup } from "../../components/Buttons";
import { FormSection } from "../../components/Forms";
import Nav, { ContentWrapper } from "../../components/layouts/Nav";

const Elements = () => {
  return (
    <Nav highlightedSection="home">
      <ContentWrapper>
        <h1>Buttons</h1>
        <FormSection>
          <Button title="Regular button" />
          <Button title="Link button" href="https://womni.se" />
          <Button title="Link button internal" href="/" />
        </FormSection>
        <FormSection>
          <Button outlined title="Regular button" />
          <Button outlined title="Link button" href="https://womni.se" />
          <Button outlined title="Link button internal" href="/" />
        </FormSection>
        <FormSection>
          <Button primary title="Regular button" />
          <Button primary title="Link button" href="https://womni.se" />
          <Button primary title="Link button internal" href="/" />
        </FormSection>
        <FormSection>
          <Button outlined disabled title="Regular button" />
          <Button
            outlined
            disabled
            title="Link button"
            href="https://womni.se"
          />
          <Button outlined disabled title="Link button internal" href="/" />
        </FormSection>
        <FormSection>
          <ButtonGroup>
            <Button outlined title={<ChevronLeft />} />
            <Button outlined title={<ChevronRight />} href="/" />
          </ButtonGroup>
        </FormSection>
      </ContentWrapper>
    </Nav>
  );
};

export default Elements;
