import Nav from "../components/layouts/Nav";
import React, { useEffect, useState } from "react";

import css from "./questionnaireForm.module.css";
import { Button } from "./Buttons";
import { Question, Questionnaire } from "../types";
import clsx from "clsx";
import { RadioButton, RadioButtonList } from "./Forms";
import { isMobile } from "react-device-detect";

interface QuestionnaireFormProps {
  questionnaire: Questionnaire;
  previousAnswers?: Record<string, any>;
  onSubmit: (answers: Record<string, any>) => void;
}

type onAnswerChanged = (question: Question, value: any) => void;

const QuestionnaireForm: React.VFC<QuestionnaireFormProps> = ({
  questionnaire,
  onSubmit,
  previousAnswers,
}) => {
  const [answers, setAnswers] = useState<Record<string, string>>(
    previousAnswers || {}
  );
  const submitTitle = previousAnswers ? "Spara ändringar" : "Skicka in svar";
  if (!questionnaire) {
    return <Nav highlightedSection="home">Ogiltig länk</Nav>;
  }

  const onAnswerChanged: onAnswerChanged = (question, value) => {
    answers[question.id] = value;
    setAnswers(answers);
  };

  const submitAnswers = async () => {
    onSubmit(answers);
  };

  return (
    <div className={css.questionnaireContainer}>
      <div>
        {questionnaire.questions.map((question) => (
          <QuestionSection
            key={question.id}
            question={question}
            onChange={onAnswerChanged}
            answers={answers}
          />
        ))}
      </div>
      <div className={css.ctaContainer}>
        <Button
          fullWidth={isMobile}
          centered={isMobile}
          title={submitTitle}
          primary
          onClick={submitAnswers}
        />
      </div>
    </div>
  );
};

interface QuestionProps {
  question: Question;
  onChange: onAnswerChanged;
  answers: Record<string, any>;
}

const QuestionSection: React.VFC<QuestionProps> = ({
  question,
  answers,
  onChange,
}) => {
  const { id, title, kind, questions, selectables, info } = question;
  const [value, setValueState] = useState<any>(answers[id]);
  const setValue = (value: any) => {
    onChange(question, value);
    setValueState(value);
  };

  const isExpanded = (question: Question) => {
    return question.questions?.some((p) => isVisible(p));
  };

  const isVisible = (question: Question) => {
    if (typeof question.visibleIf === "undefined") {
      return true;
    }
    if (Array.isArray(question.visibleIf)) {
      return question.visibleIf.includes(value);
    }
    return question.visibleIf === value;
  };

  return (
    <div className={css.questionSection}>
      <h3>{title}</h3>
      <div
        className={clsx(css.answerSection, {
          [css.expanded]: isExpanded(question),
        })}
      >
        {kind === "boolean" && (
          <RadioButtonList>
            <RadioButton
              name={id}
              id={`${id}-true`}
              onChange={() => setValue(true)}
              checked={value === true}
              label="Ja"
            />
            <RadioButton
              name={id}
              id={`${id}-false`}
              onChange={() => setValue(false)}
              checked={value === false}
              label="Nej"
            />
          </RadioButtonList>
        )}
        {kind === "numeric" && (
          <input
            type="number"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        )}
        {kind === "text" && (
          <textarea onChange={(e) => setValue(e.target.value)}>
            {value}
          </textarea>
        )}
        {kind === "bmi" && (
          <Bmi question={question} answers={answers} onChange={onChange} />
        )}
        {kind === "multi-select" && (
          <RadioButtonList>
            {selectables?.map((selectable, i) => (
              <RadioButton
                key={i}
                name={id}
                id={`${id}-${selectable}`}
                onChange={() => setValue(selectable)}
                checked={value === selectable}
                label={selectable}
              />
            ))}
          </RadioButtonList>
        )}
        {info && <div className={css.info}>{info}</div>}
      </div>

      {questions &&
        questions.map((question, i) => (
          <React.Fragment key={i}>
            {isVisible(question) && (
              <QuestionSection
                question={question}
                onChange={onChange}
                answers={answers}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

const Bmi: React.VFC<QuestionProps> = ({ question, onChange, answers }) => {
  const { id } = question;
  const answer = answers[id];
  const [weight, setWeight] = useState<number | undefined>(
    answer && answer.weight
  );
  const [height, setHeight] = useState<number | undefined>(
    answer && answer.height
  );
  const [bmi, setBmi] = useState<number | undefined>(answer && answer.bmi);
  useEffect(() => {
    if (!weight || !height) return;
    setBmi(Math.round(weight / Math.pow(height / 100, 2)));
  }, [weight, height]);

  useEffect(() => {
    onChange(question, {
      height,
      weight,
      bmi,
    });
  }, [bmi, onChange, height, weight]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <div>
        <h5>Ange din vikt(kg)</h5>
        <input
          type="number"
          onChange={(e) => setWeight(Number(e.target.value))}
          value={weight}
        />
      </div>
      <br />
      <div>
        <h5>Ange din längd(cm)</h5>
        <input
          type="number"
          onChange={(e) => setHeight(Number(e.target.value))}
          value={height}
        />
      </div>
      <br />
      {bmi && <h5>Ditt BMI: {bmi}</h5>}
    </div>
  );
};

export default QuestionnaireForm;
