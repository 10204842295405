import { useState, useEffect } from "react";
import {
  PostAuthBankID,
  PostAuthBankIDCancel,
  PostAuthBankIDCollect,
} from "../api";
import QrCode from "qrcode.react";
import { BankIDLoginSession, ErrorResponse } from "../types";
import FocusPage from "../components/layouts/FocusPage";
import DialogBox from "../components/DialogBox";
import { BankIDButton, Button } from "../components/Buttons";
import { BrowserView, MobileView, isIOS } from "react-device-detect";
import styles from "./login.module.css";

type LoginState =
  | LoginStateInit
  | LoginStateSameDevice
  | LoginStateOtherDevice
  | LoginStateError
  | LoginStateRedirecting;

interface LoginStateError {
  type: "error";
  error: ErrorResponse;
  qrCodeUsed: boolean;
}

interface ActiveSessionState {
  session: BankIDLoginSession;
}

interface LoginStateSameDevice extends ActiveSessionState {
  type: "same-device";
  redirect: boolean;
}

interface LoginStateOtherDevice extends ActiveSessionState {
  type: "other-device";
  qrSource: string;
}

interface LoginStateRedirecting extends ActiveSessionState {
  type: "redirecting";
}

interface LoginStateInit {
  type: "init";
}

const shouldRedirect = isIOS;

const Login = () => {
  const [loginState, setLoginState] = useState<LoginState>({ type: "init" });

  const initializeSession = async () => {
    try {
      const session = await PostAuthBankID();
      const urlParams = new URLSearchParams(window.location.search);
      session.redirectURL = urlParams.get("redirectURL") || "";
      return session;
    } catch (error: any) {
      setLoginState({
        type: "error",
        error: { error: error.message },
        qrCodeUsed: loginState.type === "other-device",
      });
    }
  };

  const launchBankIDOtherDevice = async () => {
    const session = await initializeSession();
    if (!session) return;
    setLoginState({ type: "other-device", session, qrSource: session.qrData });
  };

  const getRedirectURL = (session: BankIDLoginSession): string => {
    if (!shouldRedirect) return "";
    const url = new URL(window.location.href);
    url.searchParams.set("orderRef", session.orderRef);
    url.searchParams.set("autoStartToken", session.autoStartToken);
    return url.toString();
  };

  const launchBankIDSameDevice = async () => {
    const session = await initializeSession();
    if (!session) return;
    setLoginState({ type: "same-device", session, redirect: shouldRedirect });
    const redirectURL = getRedirectURL(session);
    window.location.assign(
      `bankid:///?autostarttoken=${
        session.autoStartToken
      }&redirect=${encodeURIComponent(redirectURL)}`
    );
  };

  const cancelSession = async () => {
    if (
      loginState.type === "same-device" ||
      loginState.type === "other-device"
    ) {
      PostAuthBankIDCancel(loginState.session.orderRef!);
    }
    setLoginState({ type: "init" });
  };

  const friendlyErrorMessage = (error: string, qrCodeUsed: boolean): string => {
    if (error.includes("userCancel")) {
      return "Inloggningen avbröts";
    }
    if (error.includes("expiredTransaction")) {
      return "BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din Bank. Försök sedan igen.";
    }
    if (error.includes("startFailed")) {
      if (qrCodeUsed) {
        return "Misslyckades att läsa av QR koden. Starta BankID-appen och läs av QR koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen måste du installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.";
      }
      return "BankID-appen verkar inte finnas i din dator eller telefon. Installera den och hämta ett BankID hos din internetbank. Installera appen från din appbutik eller https://install.bankid.com.";
    }
    return "Ett okänt fel inträffade, var god försök igen.";
  };

  useEffect(() => {
    if (
      (loginState.type === "same-device" && loginState.redirect === false) ||
      loginState.type === "other-device"
    ) {
      const collect = async () => {
        const [response, error] = await PostAuthBankIDCollect(
          loginState.session.orderRef! // TODO: This should not be nullable
        );
        if (error) {
          setLoginState({
            type: "error",
            error,
            qrCodeUsed: loginState.type === "other-device",
          });
        } else {
          if (response?.status === "done") {
            setLoginState({ type: "redirecting", session: loginState.session });
            if (loginState.session.redirectURL !== "") {
              window.location.assign(loginState.session.redirectURL);
            } else {
              window.location.assign("/");
            }
          }
          if (
            response?.status === "pending" &&
            loginState.type === "other-device"
          ) {
            setLoginState({
              type: "other-device",
              session: loginState.session,
              qrSource: response.qrData,
            });
          }
        }
      };
      const timer = setInterval(collect, 1000);
      return () => clearInterval(timer);
    }
  }, [loginState]);

  // Check for returning
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const autoStartToken = urlParams.get("autoStartToken");
    const orderRef = urlParams.get("orderRef");
    if (autoStartToken && orderRef) {
      setLoginState({
        type: "same-device",
        redirect: false,
        session: { orderRef, autoStartToken, redirectURL: "", qrData: "" },
      });
    }
  }, []);

  return (
    <FocusPage>
      <DialogBox>
        {loginState.type === "error" && (
          <>
            <div className={styles.loginErrorContainer}>
              {friendlyErrorMessage(
                loginState.error.error,
                loginState.qrCodeUsed
              )}
            </div>
            <Button
              fullWidth
              outlined
              onClick={cancelSession}
              title="Tillbaka till inloggning"
            ></Button>
          </>
        )}
        {loginState.type === "init" && (
          <div className={styles.loginChooser}>
            <h2>Logga in på Womni</h2>
            <p>Vänligen legitimera dig med BankID för att fortsätta.</p>
            <MobileView>
              <div className={styles.mobileBankIDButtonContainer}>
                <BankIDButton
                  onClick={launchBankIDSameDevice}
                  title="Starta BankID"
                />
              </div>
              <Button
                outlined
                fullWidth
                title="BankID på annan enhet"
                onClick={launchBankIDOtherDevice}
              ></Button>
            </MobileView>
            <BrowserView>
              <div className={styles.mobileBankIDButtonContainer}>
                <BankIDButton
                  onClick={launchBankIDOtherDevice}
                  title="Logga in med BankID på din mobil"
                />
              </div>
              <Button
                outlined
                fullWidth
                title="BankID på denna enheten"
                onClick={launchBankIDSameDevice}
              ></Button>
            </BrowserView>
          </div>
        )}
        {loginState.type === "other-device" && (
          <div className={styles.ongoingSessionContainer}>
            <div className={styles.qrCodeContainer}>
              <QrCode
                renderAs="svg"
                value={loginState.qrSource}
                className={styles.qrCode}
                width={"200px"}
                height={"200px"}
              />
            </div>
            <div>
              <ul>
                <li>Öppna appen för BankID.</li>
                <li>Tryck på QR-symbolen i appen för BankID.</li>
                <li>Rikta kameran mot QR-koden.</li>
                <li>Följ instruktionerna i appen för att logga in.</li>
              </ul>
            </div>
            <div className={styles.cancelButtonContainer}>
              <Button
                fullWidth
                outlined
                onClick={cancelSession}
                title="Avbryt"
              ></Button>
            </div>
          </div>
        )}
        {loginState.type === "same-device" && (
          <div className={styles.ongoingSessionContainer}>
            <h2>Startar BankID</h2>
            <div className={styles.cancelButtonContainer}>
              <Button
                fullWidth
                outlined
                onClick={cancelSession}
                title="Avbryt"
              ></Button>
            </div>
          </div>
        )}
        {loginState.type === "redirecting" && (
          <div className={styles.ongoingSessionContainer}>
            <h2>Loggar in på womni...</h2>
            <div className={styles.cancelButtonContainer}>
              <Button outlined onClick={cancelSession} title="Avbryt"></Button>
            </div>
          </div>
        )}
      </DialogBox>
    </FocusPage>
  );
};

export default Login;
