import clsx from "clsx";
import React from "react";
import css from "./forms.module.css";

export const FormSection: React.FC = ({children}) => {
  return <div className={css.formSection}>{children}</div>;
};

export const Input: React.VFC<React.InputHTMLAttributes<any>> = ({
  children,
  ...rest
}) => {
  return (
    <input className={css.input} {...rest}>
      {children}
    </input>
  );
};

export const Select: React.VFC<React.SelectHTMLAttributes<any>> = ({
  children,
  ...rest
}) => {
  return (
    <select className={css.select} {...rest}>
      {children}
    </select>
  );
};

export const TextArea: React.VFC<React.TextareaHTMLAttributes<any>> = ({
  children,
  ...rest
}) => {
  return <textarea className={css.textArea} {...rest} />;
};

export const ValidationMessage: React.VFC<React.ComponentProps<"div">> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(className, css.validationMessage)} {...rest}>
      {children}
    </div>
  );
};

export const Label: React.VFC<React.LabelHTMLAttributes<any>> = ({
  children,
  ...rest
}) => {
  return (
    <label className={css.label} {...rest}>
      {children}
    </label>
  );
};

export const Checkbox: React.VFC<React.ComponentProps<"input">> = ({
  id,
  title,
  dangerouslySetInnerHTML,
  ...rest
}) => {
  return (
    <div className={css.checkboxContainer}>
      <label htmlFor={id}>
        <input type="checkbox" id={id} {...rest} />
        <span className={css.checkmark} />
        {dangerouslySetInnerHTML && (
          <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
        )}
        {dangerouslySetInnerHTML && <span>{title}</span>}
      </label>
    </div>
  );
};

export interface RadioButtonProps extends React.InputHTMLAttributes<any> {
  label: React.ReactNode;
  description?: string;
}

export const RadioButton: React.VFC<RadioButtonProps> = ({
  children,
  id,
  label,
  description,
  ...rest
}) => {
  return (
    <div className={css.radioButtonContainer}>
      <Label htmlFor={id}>
        <Input type="radio" id={id} {...rest} />
        {label}
        {description && (
          <>
            <div></div>
            <div>{description}</div>
          </>
        )}
      </Label>
    </div>
  );
};

export const RadioButtonList: React.FC<React.ComponentProps<"div">> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(className, css.radioButtonListContainer)} {...rest}>
      {children}
    </div>
  );
};
