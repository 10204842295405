import {ContentWrapper} from "../../components/layouts/Nav";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import {GetBookingFromId, GetBookingPageConfigurations} from "../../api";
import {Booking, BookingPageConfiguration} from "../../types";
import Box from "../../components/Box";

import styles from "./rescheduleBooking.module.css";
import {Button} from "../../components/Buttons";
import SubnavPage from "../../components/layouts/SubnavPage";
import LoadingIndicator from "../../components/LoadingIndicator";
import {localizeMedicalRoles} from "../../util";

interface Params {
  bookingId: string;
}

const RescheduleBooking = () => {
  const {bookingId} = useParams<Params>();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [bookingPageConfiguration, setBookingPageConfiguration] =
    useState<BookingPageConfiguration | null>(null);
  const loadBooking = async () => {
    const booking = await GetBookingFromId(bookingId);
    setBooking(booking);
    const configurations = await GetBookingPageConfigurations();
    const configuration = configurations.find(
      (c) => c.kind === booking.slotKind
    );
    setBookingPageConfiguration(configuration || null);
  };

  useEffect(() => {
    loadBooking();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!booking) {
    return (
      <SubnavPage backLink={`/bookings`} title="Omboka möte">
        <LoadingIndicator />
      </SubnavPage>
    );
  }

  return (
    <SubnavPage backLink={`/bookings/${booking.id}`} title="Omboka möte">
      <ContentWrapper>
        <Box noPadding>
          <div className={styles.columnContainer}>
            <div className={styles.leftCol}>
              <div className={styles.hintMessage}>
                Vill du ha mötet med samma person?
              </div>
              <div className={styles.hintMessageTriangle}></div>
              <Button
                outlined
                centered
                primary
                fullWidth
                title={`Omboka möte med ${booking.bookedPerson.firstName} ${booking.bookedPerson.lastName}`}
                href={`/calendar/${booking.slotKind}?&bookingId=${bookingId}&profileId=${booking.bookedPerson.id}`}
              />
            </div>
            <div className={styles.rightCol}>
              <div className={styles.hintMessage}>
                Hittar du inga tider som passar med{` `}
                {booking.bookedPerson.firstName} {booking.bookedPerson.lastName}
                ?
              </div>
              <div className={styles.hintMessageTriangle}></div>
              <Button
                outlined
                centered
                fullWidth
                title="Visa alla tillgängliga tider"
                href={`/calendar/${booking.slotKind}?&bookingId=${bookingId}`}
              />
              {bookingPageConfiguration && (
                <div className={styles.roleHint}>
                  Visar lediga tider för samtliga{" "}
                  {bookingPageConfiguration.medicalRoles
                    .map(localizeMedicalRoles)
                    .join(",")}
                </div>
              )}
            </div>
          </div>
        </Box>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default RescheduleBooking;
