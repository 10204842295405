import {useContext, useState} from "react";
import Box, {BoxSpacing} from "../components/Box";
import {RadioButton, RadioButtonList} from "../components/Forms";
import {ContentWrapper} from "../components/layouts/Nav";
import {BookingsContext} from "../contexts/BookingsContext";
import {MedicalRole} from "../types";
import {Button} from "../components/Buttons";
import {localizeMedicalRoles, localizeMedicalRolesPlural} from "../util";
import styles from "./chooseRevisitCalendar.module.css";
import {EmptyProfile} from "../Svg";
import {isMobile} from "react-device-detect";
import SubnavPage from "../components/layouts/SubnavPage";

const ChooseRevisitCalendar = () => {
  const [serviceKind, setServiceKind] = useState<MedicalRole | null>(null);
  const {bookings} = useContext(BookingsContext);
  const cache: Record<string, boolean> = {};
  const reason =
    bookings
      ?.filter((b) => b.cancelled === false)
      .sort(
        (a, b) =>
          new Date(b.startsAt).getTime() - new Date(a.startsAt).getTime()
      )
      .map((b) => b.reason)
      .find(() => true) || "menopause";
  const prevBookedPersons =
    bookings &&
    serviceKind &&
    bookings
      .filter((booking) =>
        booking.bookedPerson.medicalRoles.includes(serviceKind)
      )
      .map((booking) => booking.bookedPerson)
      .filter((v) => {
        if (cache[v.id] !== true) {
          cache[v.id] = true;
          return true;
        }
        return false;
      });

  const slotKind =
    serviceKind === "medical-doctor" ? "doctor-revisit" : "midwife-revisit";

  return (
    <SubnavPage backLink="/" title="Boka återbesök">
      <ContentWrapper>
        <Box>
          <h3> Önskar du återbesök med läkare eller barnmorska?</h3>
          <br />
          <RadioButtonList>
            <RadioButton
              label="Barmorska"
              checked={serviceKind === "midwife"}
              onChange={() => setServiceKind("midwife")}
            />
            <RadioButton
              label="Läkare"
              checked={serviceKind === "medical-doctor"}
              onChange={() => setServiceKind("medical-doctor")}
            />
          </RadioButtonList>
        </Box>
        <BoxSpacing />
        {serviceKind && (
          <Box noPadding>
            {prevBookedPersons &&
              prevBookedPersons.map((person) => (
                <div className={styles.revisitChooser} key={person.id}>
                  {!isMobile && (
                    <Button
                      primary
                      className={styles.revisitBookButton}
                      href={`/calendar/${slotKind}?reason=${reason}&profileId=${
                        person.id
                      }&subNav=true&backLink=${encodeURIComponent(
                        "/choose-revisit-calendar"
                      )}`}
                      title={`Boka återbesök med ${person.firstName}`}
                    />
                  )}
                  {person.profilePicture && (
                    <img
                      src={person.profilePicture.small}
                      alt={`${person.firstName} ${person.lastName}`}
                      className={styles.profilePicture}
                    />
                  )}
                  {!person.profilePicture && (
                    <div className={styles.profilePicture}>
                      <EmptyProfile />
                    </div>
                  )}
                  <h4>
                    {person.firstName} {person.lastName}
                  </h4>
                  <span>
                    {person.medicalRoles.map(localizeMedicalRoles).join(", ")}
                  </span>
                  {isMobile && (
                    <Button
                      primary
                      fullWidth
                      centered
                      className={styles.revisitBookButtonMobile}
                      href={`/calendar/${slotKind}?reason=${reason}&profileId=${
                        person.id
                      }&subNav=true&backLink=${encodeURIComponent(
                        "/choose-revisit-calendar"
                      )}`}
                      title={`Boka återbesök med ${person.firstName}`}
                    />
                  )}
                </div>
              ))}
            <div className={styles.revisitChooser}>
              {!isMobile && (
                <Button
                  secondary
                  className={styles.revisitBookButton}
                  href={`/calendar/${slotKind}?&reason=${reason}&subNav=true&backLink=${encodeURIComponent(
                    "/choose-revisit-calendar"
                  )}`}
                  title={`Boka snarast möjliga tid`}
                />
              )}
              <div className={styles.profilePicture}>
                <EmptyProfile />
              </div>
              <h4>
                {" "}
                Visa närmast tillgänglig tid hos samtliga{" "}
                {localizeMedicalRolesPlural(serviceKind).toLowerCase()}
              </h4>
              {isMobile && (
                <Button
                  secondary
                  fullWidth
                  centered
                  className={styles.revisitBookButtonMobile}
                  href={`/calendar/${slotKind}?&reason=${reason}&subNav=true&backLink=${encodeURIComponent(
                    "/choose-revisit-calendar"
                  )}`}
                  title={`Boka snarast möjliga tid`}
                />
              )}
            </div>
          </Box>
        )}
      </ContentWrapper>
    </SubnavPage>
  );
};

export default ChooseRevisitCalendar;
