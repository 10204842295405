import styles from "./loadingModal.module.css";
const LoadingModal = () => {
  return (
    <div className={styles.loadingModal}>
      <h3>Laddar...</h3>
    </div>
  );
};

export default LoadingModal;
