import React from "react";

interface SVGProps extends React.ComponentProps<"svg"> {}

export const Logo: React.VFC<SVGProps> = (props) => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 465.12 112.94" {...props}>
      <g>
        <g>
          <path
            d="M27.35,45.47l-4.22-8.31c-3.01-5.89-10.28-8.19-16.25-5.14l-0.24,0.12C0.68,35.2-1.71,42.44,1.3,48.33l12.02,23.51
                l20.79,41.1l13.72-27.12L27.38,45.47L27.35,45.47z"
          />
          <path
            d="M84.58,78.97L67.62,45.59l-0.07-0.13l0,0l-5.42-10.67c-3.01-5.89-10.23-8.22-16.11-5.21l-0.51,0.26
                c-5.89,3.01-8.22,10.23-5.21,16.11l11.3,22.09l22.71,44.9l13.72-27.12l-3.48-6.85L84.58,78.97z"
          />
          <path
            d="M106.72,43.91c-0.8-5.23-5.08-9.5-10.3-10.3c-8.39-1.28-15.52,5.84-14.23,14.24c0.8,5.23,5.08,9.5,10.31,10.3
                C100.89,59.43,108.01,52.3,106.72,43.91z"
          />
        </g>
        <path
          d="M137.2,73.2c0,19.86,6.28,36.44,12.77,39.73c-19.02,0-40.44-15.67-40.44-39.73c0-24.07,21.42-39.74,40.44-39.74
            C142.4,36.8,137.2,53.33,137.2,73.2z M194.2,73.2c0,24.06-21.42,39.73-40.44,39.73c6.49-3.3,12.77-19.87,12.77-39.73
            c0-19.87-5.2-36.39-12.77-39.74C172.78,33.46,194.2,49.13,194.2,73.2z"
        />
        <path
          d="M204.92,52.74l24.49-19.28v79.47h-24.49V52.74z M275.61,52.13l0,60.8h-24.49V46.86l-17.22,0.15l12.27-9.66
            c5.5-4.33,13.23-5.27,19.97-1.71C272.14,38.82,275.61,45.34,275.61,52.13z M321.81,52.21l0,60.72h-24.49V46.55l-16.66,0.02
            l11.71-9.22c5.53-4.36,13.33-5.29,20.07-1.64C318.39,38.94,321.81,45.45,321.81,52.21z"
        />
        <path
          d="M406.62,52.16l0,60.77h-24.49V46.87l-17.22,0.15l12.27-9.66c5.49-4.32,13.18-5.26,19.91-1.74
            C403.14,38.78,406.62,45.35,406.62,52.16z M448.34,99.73h16.78l-11.52,9.07c-2.18,1.72-4.67,3.07-7.38,3.67
            c-11.86,2.65-22.37-6.31-22.37-17.72V47.18h24.49V99.73z M449.23,28.92c0.06-0.49,0.15-0.98,0.15-1.49c0-0.89-0.11-1.75-0.29-2.59
            c-0.65-6.21-3.69-12.15-8.57-16.59c-4.89-4.45-13.93-9.45-29.12-8c-15.11,1.43-30.2,10.17-44.85,26c-2.15,2.33-4.72,4.89-7.61,7.63
            c-2.24,2.08-4.5,4.12-6.67,6.01l-16.33,12.86v60.19h24.49V37.57l0.38-0.36c3.28-3.07,6.13-5.91,8.47-8.44
            c14.01-15.13,28.3-23.48,42.47-24.82c13.86-1.3,21.95,3.11,26.28,7.06c1.79,1.63,3.27,3.49,4.45,5.51
            c-1.6-0.77-3.37-1.24-5.26-1.24c-6.72,0-12.16,5.45-12.16,12.16s5.45,12.16,12.16,12.16c6.2,0,11.26-4.66,12.01-10.66l0,0V28.92z"
        />
      </g>
    </svg>
  );
};

export const EmptyProfile: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="PersonIcon"
      fill="#666"
      width={40}
      height={40}
    >
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
    </svg>
  );
};

export const BankID: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      version="1.1"
      id="Lager_1"
      x="0px"
      y="0px"
      viewBox="0 0 325.91199 308.79999"
      enable-background="new 0 0 595.3 841.9"
      width="325.91199"
      height="308.79999"
      {...props}
    >
      <g id="g117" transform="translate(-137.2,-262.6)">
        <path
          fill="#479cbe"
          d="m 241.9,423.6 13.2,-83.2 c -5.2,0 -14.1,0 -14.1,0 -6.6,0 -15.1,-3.7 -17.6,-10.5 -0.8,-2.3 -2.7,-10.2 8.2,-17.9 3.9,-2.7 6.4,-5.7 6.9,-8 0.5,-2.4 -0.1,-4.5 -1.8,-6.1 -2.4,-2.3 -7.1,-3.6 -13.1,-3.6 -10.1,0 -17.2,5.8 -17.9,10 -0.5,3.1 1.9,5.6 4,7.2 6.3,4.7 7.8,11.5 3.9,17.9 -4,6.6 -12.7,10.9 -22,11 0,0 -9.2,0 -14.4,0 -1.2,8.1 -20.8,132.3 -22.3,142.1 H 233 c 0.7,-4.4 4.3,-27.9 9.2,-58.9 z"
          id="path95"
        />
        <path
          fill="#00a5c3"
          d="M 346.5,267.6 H 267 l -10.6,67.3 h 13.5 c 7.4,0 14.4,-3.4 17.4,-8.3 1,-1.6 1.4,-3 1.4,-4.3 0,-2.8 -1.9,-4.9 -3.8,-6.3 -5.2,-3.9 -6.3,-8 -6.3,-10.9 0,-0.6 0,-1.1 0.1,-1.6 1.1,-7.1 10.7,-14.8 23.4,-14.8 7.6,0 13.4,1.8 16.9,5.1 3.1,2.9 4.3,7 3.4,11.3 -1.1,5.1 -6.2,9.3 -9.1,11.4 -7.7,5.4 -6.7,10.1 -6.2,11.5 1.6,4.2 7.7,6.9 12.4,6.9 H 340 c 0,0 0,0 0,0.1 28,0.2 43,13.1 38.3,43.1 -4.4,27.9 -25.8,39.9 -51.3,40.1 l -10.1,64.4 h 14.9 c 62.9,0 114.3,-40.4 124.4,-104.2 12.5,-79.2 -37.7,-110.8 -109.7,-110.8 z"
          id="path97"
        />
        <path
          fill="#235971"
          d="M 346.5,267.6 H 267 l -10.6,67.3 h 13.5 c 7.4,0 14.4,-3.4 17.4,-8.3 1,-1.6 1.4,-3 1.4,-4.3 0,-2.8 -1.9,-4.9 -3.8,-6.3 -5.2,-3.9 -6.3,-8 -6.3,-10.9 0,-0.6 0,-1.1 0.1,-1.6 1.1,-7.1 10.7,-14.8 23.4,-14.8 7.6,0 13.4,1.8 16.9,5.1 3.1,2.9 4.3,7 3.4,11.3 -1.1,5.1 -6.2,9.3 -9.1,11.4 -7.7,5.4 -6.7,10.1 -6.2,11.5 1.6,4.2 7.7,6.9 12.4,6.9 H 340 c 0,0 0,0 0,0.1 28,0.2 43,13.1 38.3,43.1 -4.4,27.9 -25.8,39.9 -51.3,40.1 l -10.1,64.4 h 14.9 c 62.9,0 114.3,-40.4 124.4,-104.2 12.5,-79.2 -37.7,-110.8 -109.7,-110.8 z"
          id="path99"
        />
        <g id="g109">
          <path
            fill="#235971"
            d="m 150.7,511.2 h 31.9 c 13.6,0 16.9,6.9 15.9,13.2 -0.8,5.1 -4.3,8.9 -10.3,11.4 7.6,2.9 10.6,7.4 9.5,14.5 -1.4,8.9 -9.1,15.5 -19.2,15.5 h -36.3 z m 21.1,22.6 c 6.2,0 9.1,-3.3 9.7,-7.2 0.6,-4.2 -1.3,-7.1 -7.5,-7.1 h -5.5 l -2.2,14.3 z m -3.4,23.6 c 6.4,0 10.1,-2.6 11,-7.9 0.7,-4.6 -1.9,-7.3 -8.1,-7.3 H 165 l -2.4,15.3 h 5.8 z"
            id="path101"
          />
          <path
            fill="#235971"
            d="m 242.4,566.2 c -8.3,0.6 -12.3,-0.3 -14.3,-3.9 -4.4,2.7 -9.3,4.1 -14.5,4.1 -9.4,0 -12.7,-4.9 -11.8,-10.3 0.4,-2.6 1.9,-5.1 4.3,-7.2 5.2,-4.5 18,-5.1 23,-8.5 0.4,-3.8 -1.1,-5.2 -5.8,-5.2 -5.5,0 -10.1,1.8 -18,7.2 l 1.9,-12.4 c 6.8,-4.9 13.4,-7.2 21,-7.2 9.7,0 18.3,4 16.7,14.6 l -1.9,12 c -0.7,4.2 -0.5,5.5 4.2,5.6 z M 228,547.4 c -4.4,2.8 -12.6,2.3 -13.5,8.1 -0.4,2.7 1.3,4.7 4,4.7 2.6,0 5.8,-1.1 8.4,-2.9 -0.2,-1 -0.1,-2 0.2,-3.9 z"
            id="path103"
          />
          <path
            fill="#235971"
            d="m 257.9,523.5 h 16.6 l -0.9,5.5 c 5.3,-4.5 9.3,-6.2 14.5,-6.2 9.3,0 13.6,5.7 12.1,15 l -4.3,27.9 h -16.6 l 3.6,-23.1 c 0.7,-4.2 -0.6,-6.2 -3.8,-6.2 -2.6,0 -5,1.4 -7.3,4.5 l -3.8,24.7 h -16.6 z"
            id="path105"
          />
          <path
            fill="#235971"
            d="m 313.1,511.2 h 16.6 l -4.2,26.8 15.9,-14.5 h 20.5 l -20.4,18 16.4,24.2 H 337 l -12.6,-19.5 h -0.2 l -3,19.5 h -16.6 z"
            id="path107"
          />
        </g>
        <g id="g115">
          <path
            fill="#479cbe"
            d="M 371.9,511.2 H 391 l -8.4,54.5 h -19.1 z"
            id="path111"
          />
          <path
            fill="#479cbe"
            d="m 400.3,511.2 h 27.3 c 21.1,0 27.2,15.3 25.2,28 -1.9,12.4 -11.7,26.5 -30.2,26.5 h -30.8 z m 17.7,41.5 c 9.3,0 14.4,-4.6 15.9,-14.3 1.1,-7.2 -1.1,-14.3 -11.4,-14.3 h -5.1 l -4.4,28.6 z"
            id="path113"
          />
        </g>
      </g>
    </svg>
  );
};

export const BankIDSmall: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 315.9 298.8"
      height="24"
      width="23"
      {...props}
    >
      <path d="M99.7 156l13.2-83.2H98.8c-6.6 0-15.1-3.7-17.6-10.5-.8-2.3-2.7-10.2 8.2-17.9 3.9-2.7 6.4-5.7 6.9-8 .5-2.4-.1-4.5-1.8-6.1-2.4-2.3-7.1-3.6-13.1-3.6-10.1 0-17.2 5.8-17.9 10-.5 3.1 1.9 5.6 4 7.2 6.3 4.7 7.8 11.5 3.9 17.9-4 6.6-12.7 10.9-22 11H35c-1.2 8.1-20.8 132.3-22.3 142.1h78.1c.7-4.4 4.3-27.9 9.2-58.9h-.3zM204.3 0h-79.5l-10.6 67.3h13.5c7.4 0 14.4-3.4 17.4-8.3 1-1.6 1.4-3 1.4-4.3 0-2.8-1.9-4.9-3.8-6.3-5.2-3.9-6.3-8-6.3-10.9 0-.6 0-1.1.1-1.6 1.1-7.1 10.7-14.8 23.4-14.8 7.6 0 13.4 1.8 16.9 5.1 3.1 2.9 4.3 7 3.4 11.3-1.1 5.1-6.2 9.3-9.1 11.4-7.7 5.4-6.7 10.1-6.2 11.5 1.6 4.2 7.7 6.9 12.4 6.9h20.5v.1c28 .2 43 13.1 38.3 43.1-4.4 27.9-25.8 39.9-51.3 40.1L174.7 215h14.9c62.9 0 114.3-40.4 124.4-104.2C326.5 31.6 276.3 0 204.3 0zM8.5 243.6h31.9c13.6 0 16.9 6.9 15.9 13.2-.8 5.1-4.3 8.9-10.3 11.4 7.6 2.9 10.6 7.4 9.5 14.5-1.4 8.9-9.1 15.5-19.2 15.5H0l8.5-54.6zm21.1 22.6c6.2 0 9.1-3.3 9.7-7.2.6-4.2-1.3-7.1-7.5-7.1h-5.5l-2.2 14.3h5.5zm-3.4 23.6c6.4 0 10.1-2.6 11-7.9.7-4.6-1.9-7.3-8.1-7.3h-6.3l-2.4 15.3 5.8-.1zM100.2 298.6c-8.3.6-12.3-.3-14.3-3.9-4.4 2.7-9.3 4.1-14.5 4.1-9.4 0-12.7-4.9-11.8-10.3.4-2.6 1.9-5.1 4.3-7.2 5.2-4.5 18-5.1 23-8.5.4-3.8-1.1-5.2-5.8-5.2-5.5 0-10.1 1.8-18 7.2l1.9-12.4c6.8-4.9 13.4-7.2 21-7.2 9.7 0 18.3 4 16.7 14.6l-1.9 12c-.7 4.2-.5 5.5 4.2 5.6l-4.8 11.2zm-14.4-18.8c-4.4 2.8-12.6 2.3-13.5 8.1-.4 2.7 1.3 4.7 4 4.7 2.6 0 5.8-1.1 8.4-2.9-.2-1-.1-2 .2-3.9l.9-6zM115.7 255.9h16.6l-.9 5.5c5.3-4.5 9.3-6.2 14.5-6.2 9.3 0 13.6 5.7 12.1 15l-4.3 27.9h-16.6l3.6-23.1c.7-4.2-.6-6.2-3.8-6.2-2.6 0-5 1.4-7.3 4.5l-3.8 24.7h-16.6l6.5-42.1zM170.9 243.6h16.6l-4.2 26.8 15.9-14.5h20.5l-20.4 18 16.4 24.2h-20.9l-12.6-19.5h-.2l-3 19.5h-16.6l8.5-54.5z"></path>
      <g>
        <path d="M229.7 243.6h19.1l-8.4 54.5h-19.1l8.4-54.5zM258.1 243.6h27.3c21.1 0 27.2 15.3 25.2 28-1.9 12.4-11.7 26.5-30.2 26.5h-30.8l8.5-54.5zm17.7 41.5c9.3 0 14.4-4.6 15.9-14.3 1.1-7.2-1.1-14.3-11.4-14.3h-5.1l-4.4 28.6h5z"></path>
      </g>
    </svg>
  );
};

export const ChevronRight: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

export const ChevronLeft: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  );
};

export const HomeIcon: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      {...props}
      viewBox="0 0 495.398 495.398"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <path
              d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391
				v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158
				c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747
				c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"
            />
            <path
              d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401
				c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79
				c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const MessagesIcon: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.60175 4.20114C2.14997 3.47258 3.02158 3 4 3H20C20.9784 3 21.85 3.47258 22.3982 4.20113L12 11.7635L1.60175 4.20114Z" />
      <path d="M1 6.2365V18C1 19.6523 2.34772 21 4 21H20C21.6523 21 23 19.6523 23 18V6.23649L13.1763 13.381C12.475 13.891 11.525 13.891 10.8237 13.381L1 6.2365Z" />
    </svg>
  );
};

export const FormIcon: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58579 2.58579C3 3.17157 3 4.11438 3 6V16C3 18.8284 3 20.2426 3.87868 21.1213C4.51998 21.7626 5.44655 21.9359 7 21.9827V19C7 18.4477 7.44772 18 8 18C8.55228 18 9 18.4477 9 19L9 22H15V19C15 18.4477 15.4477 18 16 18C16.5523 18 17 18.4477 17 19L17 21.9827C18.5534 21.9359 19.48 21.7626 20.1213 21.1213C21 20.2426 21 18.8284 21 16V6C21 4.11438 21 3.17157 20.4142 2.58579C19.8284 2 18.8856 2 17 2H7C5.11438 2 4.17157 2 3.58579 2.58579ZM8 8C7.44772 8 7 8.44772 7 9C7 9.55228 7.44772 10 8 10H16C16.5523 10 17 9.55228 17 9C17 8.44772 16.5523 8 16 8H8ZM8 14L16 14C16.5523 14 17 13.5523 17 13C17 12.4477 16.5523 12 16 12L8 12C7.44772 12 7 12.4477 7 13C7 13.5523 7.44772 14 8 14Z"
      />
    </svg>
  );
};

export const UserIcon: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" />
      <path d="M12.0002 14.5C6.99016 14.5 2.91016 17.86 2.91016 22C2.91016 22.28 3.13016 22.5 3.41016 22.5H20.5902C20.8702 22.5 21.0902 22.28 21.0902 22C21.0902 17.86 17.0102 14.5 12.0002 14.5Z" />
    </svg>
  );
};

export const BackArrow: React.VFC<SVGProps> = (props) => {
  return (
    <svg width="48" height="48" {...props} viewBox="0 0 512 512">
      <g>
        <g>
          <g>
            <path
              d="M256,0C114.618,0,0,114.618,0,256s114.618,256,256,256s256-114.618,256-256S397.382,0,256,0z M256,469.333
				c-117.818,0-213.333-95.515-213.333-213.333S138.182,42.667,256,42.667S469.333,138.182,469.333,256S373.818,469.333,256,469.333
				z"
            />
            <path
              d="M313.752,134.248c-8.331-8.331-21.839-8.331-30.17,0L176.915,240.915c-8.331,8.331-8.331,21.839,0,30.17l106.667,106.667
				c8.331,8.331,21.839,8.331,30.17,0c8.331-8.331,8.331-21.839,0-30.17L222.17,256l91.582-91.582
				C322.083,156.087,322.083,142.58,313.752,134.248z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const BloodTest: React.VFC<SVGProps> = (props) => {
  return (
    <svg width="48" height="48" {...props} viewBox="0 0 310.088 310.088">
      <g>
        <g>
          <path
            d="M265.825,134.882c6.036,6.036,15.821,6.036,21.852,0c6.036-6.036,6.036-15.821,0-21.852l-99.553-99.558
       c-6.036-6.036-15.821-6.036-21.852,0c-6.036,6.036-6.036,15.821,0,21.852l3.011,3.011L19.369,188.248
       c-25.825,25.825-25.825,67.702,0,93.527c25.825,25.825,67.702,25.825,93.527,0L262.81,131.861L265.825,134.882z M100.972,269.855
       c-19.21,19.21-50.468,19.21-69.673,0.005c-19.21-19.21-19.215-50.468-0.005-69.678l90.117-90.117l129.311,10.045L100.972,269.855z
       "
          />
          <path
            d="M279.69,171.802c0,0-30.397,42.112-30.397,69.939c0,16.788,13.609,30.397,30.397,30.397
       c16.788,0,30.397-13.609,30.397-30.397C310.088,213.914,279.69,171.802,279.69,171.802z"
          />
        </g>
      </g>
    </svg>
  );
};

export const UploadFile: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      {...props}
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M544 864V672h128L512 480 352 672h128v192H320v-1.6c-5.376.32-10.496 1.6-16 1.6A240 240 0 0 1 64 624c0-123.136 93.12-223.488 212.608-237.248A239.808 239.808 0 0 1 512 192a239.872 239.872 0 0 1 235.456 194.752c119.488 13.76 212.48 114.112 212.48 237.248a240 240 0 0 1-240 240c-5.376 0-10.56-1.28-16-1.6v1.6H544z" />
    </svg>
  );
};

export const Pill: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6569 2.75736C15 0.414213 18.799 0.414214 21.1421 2.75736C23.4853 5.1005 23.4853 8.8995 21.1421 11.2426L11.2426 21.1421C8.89949 23.4853 5.1005 23.4853 2.75736 21.1421C0.414214 18.799 0.414213 15 2.75736 12.6569L12.6569 2.75736ZM19.7279 9.82843L15.4853 14.0711L9.82843 8.41421L14.0711 4.17157C15.6332 2.60948 18.1658 2.60948 19.7279 4.17157C21.29 5.73367 21.29 8.26633 19.7279 9.82843Z"
      />
    </svg>
  );
};

export const QuestionMark: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      {...props}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22Zm0-2a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,20ZM8,8.994a3.907,3.907,0,0,1,2.319-3.645,4.061,4.061,0,0,1,3.889.316,4,4,0,0,1,.294,6.456,3.972,3.972,0,0,0-1.411,2.114,1,1,0,0,1-1.944-.47,5.908,5.908,0,0,1,2.1-3.2,2,2,0,0,0-.146-3.23,2.06,2.06,0,0,0-2.006-.14,1.937,1.937,0,0,0-1.1,1.8A1,1,0,0,1,8,9Z" />
    </svg>
  );
};

export const Heart: React.VFC<SVGProps> = (props) => {
  return (
    <svg width="48" height="48" {...props} viewBox="0 0 24 24">
      <path d="M12.39 20.87a.696.696 0 0 1-.78 0C9.764 19.637 2 14.15 2 8.973c0-6.68 7.85-7.75 10-3.25 2.15-4.5 10-3.43 10 3.25 0 5.178-7.764 10.664-9.61 11.895z" />
    </svg>
  );
};

export const TimeIcon: React.VFC<SVGProps> = (props) => {
  return (
    <svg width="48" height="48" {...props} viewBox="0 0 24 24">
      <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.35 15.57C16.21 15.81 15.96 15.94 15.7 15.94C15.57 15.94 15.44 15.91 15.32 15.83L12.22 13.98C11.45 13.52 10.88 12.51 10.88 11.62V7.52C10.88 7.11 11.22 6.77 11.63 6.77C12.04 6.77 12.38 7.11 12.38 7.52V11.62C12.38 11.98 12.68 12.51 12.99 12.69L16.09 14.54C16.45 14.75 16.57 15.21 16.35 15.57Z" />
    </svg>
  );
};

export const Wave: React.VFC<SVGProps> = (props) => {
  return (
    <svg {...props} viewBox="0 0 1440 320">
      <path
        fillOpacity="1"
        d="M0,224L80,229.3C160,235,320,245,480,234.7C640,224,800,192,960,192C1120,192,1280,224,1360,240L1440,256L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
      ></path>
    </svg>
  );
};

export const Star: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="800px"
      height="800px"
      viewBox="0 0 1920 1920"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1915.918 737.475c-10.955-33.543-42.014-56.131-77.364-56.131h-612.029l-189.063-582.1v-.112C1026.394 65.588 995.335 43 959.984 43c-35.237 0-66.41 22.588-77.365 56.245L693.443 681.344H81.415c-35.35 0-66.41 22.588-77.365 56.131-10.955 33.544.79 70.137 29.478 91.03l495.247 359.831-189.177 582.212c-10.955 33.657 1.13 70.25 29.817 90.918 14.23 10.278 30.946 15.487 47.66 15.487 16.716 0 33.432-5.21 47.775-15.6l495.134-359.718 495.021 359.718c28.574 20.781 67.087 20.781 95.662.113 28.687-20.668 40.658-57.261 29.703-91.03l-189.176-582.1 495.36-359.83c28.574-20.894 40.433-57.487 29.364-91.03"
        fillRule="evenodd"
      />
    </svg>
  );
};

export const CheckIcon: React.VFC<SVGProps> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2373 0.902344C12.2706 0.902344 9.37049 1.78208 6.90376 3.4303C4.43702 5.07852 2.51443 7.4212 1.37912 10.1621C0.243804 12.903 -0.053246 15.919 0.525532 18.8287C1.10431 21.7384 2.53292 24.4112 4.63071 26.5089C6.7285 28.6067 9.40124 30.0353 12.311 30.6141C15.2207 31.1929 18.2367 30.8958 20.9776 29.7605C23.7184 28.6252 26.0611 26.7026 27.7093 24.2359C29.3576 21.7692 30.2373 18.8691 30.2373 15.9023C30.2373 11.9241 28.657 8.10879 25.8439 5.29574C23.0309 2.4827 19.2156 0.902344 15.2373 0.902344ZM15.2373 27.9991C12.8448 27.9991 10.506 27.2896 8.5167 25.9604C6.5274 24.6312 4.97692 22.742 4.06135 20.5316C3.14577 18.3212 2.90622 15.8889 3.37297 13.5424C3.83973 11.1958 4.99184 9.04039 6.6836 7.34863C8.37536 5.65687 10.5308 4.50476 12.8773 4.038C15.2239 3.57125 17.6561 3.8108 19.8665 4.72638C22.0769 5.64196 23.9662 7.19243 25.2954 9.18173C26.6246 11.171 27.3341 13.5098 27.3341 15.9023C27.3341 19.1106 26.0596 22.1875 23.791 24.4561C21.5224 26.7246 18.4456 27.9991 15.2373 27.9991Z"
        fill="#9051A3"
      />
      <path
        d="M13.7859 17.7215L10.4569 14.3925L8.40527 16.4441L12.7601 20.7989C13.0323 21.0708 13.4012 21.2234 13.7859 21.2234C14.1706 21.2234 14.5395 21.0708 14.8117 20.7989L22.5537 13.057L20.502 11.0054L13.7859 17.7215Z"
        fill="#9051A3"
      />
    </svg>
  );
};
