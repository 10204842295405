import clsx from "clsx";
import {isMobile} from "react-device-detect";
import {EmptyProfile} from "../Svg";
import {Booking} from "../types";
import {localizeMedicalRoles} from "../util";
import {Button} from "./Buttons";
import {AlternatePromo} from "./Promos";
import Time from "./Time";

import styles from "./upcomingBookingsPromo.module.css";

interface UpcomingBookingsPromoProps {
  booking: Booking;
}
const UpcomingBookingsPromo: React.VFC<UpcomingBookingsPromoProps> = ({
  booking,
}) => {
  return (
    <AlternatePromo title="Ditt nästa möte">
      <div
        className={clsx(styles.booking, {
          [styles.cancelled]: booking.cancelled,
          [styles.mobile]: isMobile,
          [styles.desktop]: !isMobile,
        })}>
        <div className={styles.profile}>
          <div className={styles.profilePicture}>
            {booking.bookedPerson.profilePicture && (
              <img
                src={booking.bookedPerson.profilePicture.small}
                alt={booking.bookedPerson.firstName}
              />
            )}
            {!booking.bookedPerson.profilePicture && <EmptyProfile />}
          </div>
          <div className={styles.profileInfo}>
            <Time
              dateTime={booking.startsAt}
              options={{dateStyle: "medium", timeStyle: "short"}}
            />
            <h3>
              {booking.bookedPerson.firstName} {booking.bookedPerson.lastName}
            </h3>
            <div className={styles.role}>
              {booking.bookedPerson.medicalRoles
                .map(localizeMedicalRoles)
                .join(",")}
            </div>
          </div>
        </div>
        {booking.meetingKind === "phone" && !booking.cancelled && (
          <p>
            Eftersom du har valt telefonmöte så kommer du att bli uppringd på{" "}
            {booking.telephoneNumber} när mötet börjar.
          </p>
        )}
        {booking.meetingKind === "video" && !booking.cancelled && (
          <Button
            primary
            fullWidth={isMobile}
            centered={isMobile}
            onClick={(evt) => {
              evt.stopPropagation();
            }}
            title="Starta videomöte"
            href={`${process.env.REACT_APP_MEET_URL}/${booking.id}`}
          />
        )}
      </div>
    </AlternatePromo>
  );
};
export default UpcomingBookingsPromo;
