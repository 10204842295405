import { Booking } from "../types";
import { isMobile } from "react-device-detect";
import Time from "./Time";

import styles from "./bookingList.module.css";
import clsx from "clsx";
import { ChevronRight, EmptyProfile, TimeIcon } from "../Svg";
import { localizeMedicalRoles } from "../util";
import { Link } from "react-router-dom";

interface BookingListProps {
  bookings: Booking[];
}
const BookingList: React.VFC<BookingListProps> = ({ bookings }) => {
  return (
    <>
      {bookings.map((booking, i) => (
        <Link
          key={i}
          to={`/bookings/${booking.id}`}
          className={clsx(styles.booking, {
            [styles.cancelled]: booking.cancelled,
            [styles.mobile]: isMobile,
            [styles.desktop]: !isMobile,
          })}
        >
          {booking.cancelled && (
            <div className={styles.cancelledNotice}>
              Avbokat<div className={styles.triangle}></div>
            </div>
          )}
          <div className={styles.leftCol}>
            <div className={styles.profilePicture}>
              {booking.bookedPerson.profilePicture && (
                <img
                  src={booking.bookedPerson.profilePicture.small}
                  alt={booking.bookedPerson.firstName}
                />
              )}
              {!booking.bookedPerson.profilePicture && <EmptyProfile />}
            </div>
            <TimeIcon className={styles.timeIcon} />
            <Time
              dateTime={booking.startsAt}
              options={{ dateStyle: "medium", timeStyle: "short" }}
            />
            <h3>
              {booking.bookedPerson.firstName} {booking.bookedPerson.lastName}
            </h3>
            <div className={styles.role}>
              {booking.bookedPerson.medicalRoles
                .map(localizeMedicalRoles)
                .join(",")}
            </div>
          </div>
          <div className={styles.rightCol}>
            <ChevronRight />
          </div>
        </Link>
      ))}
    </>
  );
};

export default BookingList;
