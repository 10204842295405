import { Message } from "../types";
import Box from "./Box";

import useOnScreen from "../hooks/useOnScreen";
import styles from "./messageList.module.css";
import { useEffect, useRef, useState } from "react";
import { PostMessageViewed } from "../api";
import clsx from "clsx";
import useLogger from "../hooks/useLogger";
import Time from "./Time";
import { localizeMedicalRoles } from "../util";

interface MessageListProps {
  messages: Message[];
}
const MessageList: React.VFC<MessageListProps> = ({ messages }) => {
  return (
    <>
      {messages.map((message, i) => (
        <MessageView message={message} key={i} />
      ))}
    </>
  );
};

interface MessageViewProps {
  message: Message;
}

const MessageView: React.VFC<MessageViewProps> = ({ message }) => {
  const ref = useRef<HTMLDivElement>(null);
  const logger = useLogger();
  const [hasPostedVisibility, setHasPostedVisibility] = useState(false);
  const isVisible = useOnScreen(ref);
  useEffect(() => {
    if (isVisible && !hasPostedVisibility && !message.viewedByReceiver) {
      setHasPostedVisibility(true);
      PostMessageViewed(message.id).catch(logger.error);
    }
  }, [isVisible, hasPostedVisibility]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box noPadding={true}>
        <div
          className={clsx(styles.messageContainer, {
            [styles.unread]: !message.viewedByReceiver,
          })}
        >
          <div className={styles.messageHeader}>
            <Time
              dateTime={message.created}
              options={{ dateStyle: "medium", timeStyle: "short" }}
            />
            <br />
            <h3>
              {message.sender.firstName} {message.sender.lastName}
            </h3>
            {message.sender.medicalRoles.map(localizeMedicalRoles).join(", ")}
          </div>
          <div className={styles.messageBody}>{message.body}</div>
        </div>
      </Box>

      <div ref={ref} style={{ marginBottom: "20px" }}></div>
    </>
  );
};

export default MessageList;
