import styles from "./subnavPage.module.css";
import { BackArrow } from "../../Svg";
import { Link } from "react-router-dom";

interface Props {
  backLink: string;
  title: string;
}

const SubnavPage: React.FC<Props> = ({ children, backLink, title }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <Link to={backLink}>
            <BackArrow />
          </Link>
          <h3>{title}</h3>
        </div>
      </div>
      {children}
    </>
  );
};

export default SubnavPage;
