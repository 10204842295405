import {Invoice, InvoiceState} from "../types";
import styles from "./invoiceTable.module.css";
import {Button} from "./Buttons";
import {FormattedDate} from "./Util";
import {GetInvoice} from "../api";
import {useState} from "react";
import LoadingIndicator from "./LoadingIndicator";

const stateLabels: Record<InvoiceState, string> = {
  Paid: "Betald",
  Unpaid: "Obetald",
  Overdue: "Obetald",
  Unattested: "Obetald",
  Sending: "Obetald",
  CollectorEnded: "Obetald",
  Collecting: "Obetald",
  Collection: "Obetald",
  Factoring: "Obetald",
  Sold: "Obetald",
  Ended: "Avslutad",
  Credited: "Krediterad",
  FactoringDenied: "Obetald",
  PartlyPaid: "Delvis betald",
  Reminder_1: "Påminnelse 1",
  Reminder_2: "Påminnelse 2",
  Reminder_3: "Påminnelse 3",
  Reminder_Many: "Påminnelse skickad",
  Deleted: "Borttagen",
};

interface InvoiceTableProps {
  invoices: Invoice[];
}

const InvoiceTable: React.VFC<InvoiceTableProps> = ({invoices}) => {
  const [isLoading, setIsLoading] = useState(false);
  const openInvoice = async (invoice: Invoice) => {
    if (!invoice.billogramId) return;
    setIsLoading(true);
    try {
      const invoiceFull = await GetInvoice(invoice.billogramId);
      window.open(invoiceFull.url, "_blank");
    } catch (error) {}
    setIsLoading(false);
  };
  if (isLoading) {
    return (
      <>
        <LoadingIndicator />;
      </>
    );
  }
  return (
    <table className={styles.invoiceTable}>
      <thead>
        <tr>
          <td>Status</td>
          <td className={styles.hideMobile}>Skickad</td>
          <td>Förfaller</td>
          <td align="right">Belopp</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {invoices.map((invoice, index) => (
          <tr key={index}>
            <td>{stateLabels[invoice.paymentStatus] || "Obetald"}</td>
            <td className={styles.hideMobile}>
              <FormattedDate date={invoice.createdAt} />
            </td>
            <td>
              {invoice.dueDate && <FormattedDate date={invoice.dueDate} />}
            </td>
            <td align="right">
              {invoice.totalSum && <>{invoice.totalSum} kr</>}
            </td>
            <td align="right">
              <Button
                primary
                title="Visa"
                onClick={() => openInvoice(invoice)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoiceTable;
