import { Link } from "react-router-dom";
import Box from "../components/Box";
import { Button } from "../components/Buttons";
import Nav, { ContentWrapper } from "../components/layouts/Nav";
import { NavTable, NavTableItem } from "../components/NavTable";

const Account = () => {
  return (
    <Nav highlightedSection="account">
      <ContentWrapper>
        <Box noPadding>
          <NavTable>
            <NavTableItem>
              <Link to="/settings">Inställningar</Link>
            </NavTableItem>
            <NavTableItem>
              <Link to="/invoices">Fakturor</Link>
            </NavTableItem>
          </NavTable>
          <div style={{ marginTop: "20px" }}></div>
        </Box>
        <Button
          primary
          centered
          fullWidth
          title="Logga ut"
          href={`${
            process.env.REACT_APP_API_URL
          }/auth/logout?redirectURL=${encodeURIComponent(
            window.location.protocol + "//" + window.location.host
          )}`}
        />
      </ContentWrapper>
    </Nav>
  );
};

export default Account;
