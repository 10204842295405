import Box from "./Box";
import {SectionHeader} from "./Typography";
import styles from "./infoNav.module.css";
import {Button} from "./Buttons";
import {BloodTest, MessagesIcon, Pill, QuestionMark, UploadFile} from "../Svg";
import {useContext} from "react";
import {BookingsContext} from "../contexts/BookingsContext";
import {Milliseconds, MonthNames} from "../util";
import {TimeIcon} from "../Svg";

const InfoNav = () => {
  const {pastBookings, followUpQueue, futureBookings} =
    useContext(BookingsContext);
  const nextFollowUp =
    followUpQueue && followUpQueue.length > 0 ? followUpQueue[0] : null;
  const getFollowUpDateSummary = () => {
    if (!nextFollowUp) {
      return "";
    }
    const date = new Date(nextFollowUp.after);
    const month = MonthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month}, ${year}`;
  };

  const nonCancelledPreviousBookings = pastBookings?.filter(
    (booking) => booking.cancelled === false
  );

  // Only allow recipes
  // revisits if pat. has had a meeting within the
  // last 10 months.
  const isEligebleForRecipes: boolean =
    (nonCancelledPreviousBookings &&
      nonCancelledPreviousBookings
        .map((booking) => new Date(booking.startsAt))
        .filter((date) => {
          const diff = new Date().getTime() - date.getTime();
          return diff < Milliseconds.Day * 30 * 10;
        }).length > 0) ||
    false;

  // Only allow booking revisit
  // revisits if pat. has had a meeting within the
  // last 14 months.
  const isEligebleForRevisit: Boolean =
    (nonCancelledPreviousBookings &&
      nonCancelledPreviousBookings
        .map((booking) => new Date(booking.startsAt))
        .filter((date) => {
          const diff = new Date().getTime() - date.getTime();
          return diff < Milliseconds.Day * 30 * 14;
        }).length > 0) ||
    false;

  const isEligebleForContact: Boolean =
    (pastBookings !== null && pastBookings.length > 0) ||
    (futureBookings !== null && futureBookings.length > 0);

  return (
    <>
      <SectionHeader>Våra tjänster</SectionHeader>
      <Box noPadding={true}>
        <div className={styles.infoNavSection}>
          <div className={styles.infoNavCta}>
            {isEligebleForRevisit && (
              <Button
                secondary
                startIcon={<TimeIcon />}
                fullWidth
                href="/choose-revisit-calendar"
                title="Boka återbesök"
              />
            )}
            {!isEligebleForRevisit && (
              <Button
                secondary
                startIcon={<TimeIcon />}
                fullWidth
                href="https://womni.se/boka-tid"
                title="Boka besök"
              />
            )}
          </div>
          <div className={styles.infoNavDescription}>
            {nextFollowUp && (
              <>
                Du kommer bli kallad på återbesök {getFollowUpDateSummary()}. Om
                du önskar ha ett återbesök tidigare kan du boka det här.
              </>
            )}
            {!nextFollowUp && isEligebleForRevisit && (
              <>Boka återbesök med läkare eller barnmorska.</>
            )}
            {!nextFollowUp && !isEligebleForRevisit && (
              <>Boka besök med läkare eller barnmorska.</>
            )}
          </div>
        </div>
        <div className={styles.infoNavSection}>
          <div className={styles.infoNavCta}>
            <Button
              secondary
              startIcon={<BloodTest />}
              fullWidth
              href="https://womni.se/blodprovtagning-womni"
              title="Boka tid för blodprovtagning"
            />
          </div>
          <div className={styles.infoNavDescription}>
            Läs mer om hur blodprovstagning går till och boka tid.
          </div>
        </div>
        <div className={styles.infoNavSection}>
          <div className={styles.infoNavCta}>
            <Button
              fullWidth
              secondary
              startIcon={<UploadFile />}
              href="/upload"
              title="Ladda upp dokument"
            />
          </div>
          <div className={styles.infoNavDescription}>
            Ladda upp dokument vid behov. Exempelvis journal från
            gynundersökning eller tidigare blodprovssvar.
          </div>
        </div>
        {isEligebleForRecipes && (
          <div className={styles.infoNavSection}>
            <div className={styles.infoNavCta}>
              <Button
                fullWidth
                secondary
                startIcon={<Pill />}
                href="/prescription"
                title="Receptförnyelse"
              />
            </div>
            <div className={styles.infoNavDescription}>
              Förnya eller ändra ett recept.
            </div>
          </div>
        )}
        <div className={styles.infoNavSection}>
          <div className={styles.infoNavCta}>
            <Button
              fullWidth
              secondary
              startIcon={<QuestionMark />}
              noWrap
              href="/info/hormones"
              title="Allmänna råd hormonbehandling"
            />
          </div>
          <div className={styles.infoNavDescription}>
            Har du blivit ordinerad behandling med könshormoner? Här kan du läsa
            på om behandlingar och få svar på frågor som kommer i efterhand.
          </div>
        </div>
        {isEligebleForContact && (
          <div className={styles.infoNavSection}>
            <div className={styles.infoNavCta}>
              <Button
                fullWidth
                secondary
                startIcon={<MessagesIcon />}
                noWrap
                href="/contact"
                title="Kontakta oss"
              />
            </div>
            <div className={styles.infoNavDescription}>
              Har du frågor till oss kan du lämna ett meddelande här.
            </div>
          </div>
        )}
      </Box>
    </>
  );
};

export default InfoNav;
