import React, {useRef} from "react";
import {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {
  GetBookingFromId,
  GetBookingPageConfigurations,
  GetProfileFromId,
  GetPublicProfile,
  GetSlots,
} from "../../api";
import LoadingIndicator from "../../components/LoadingIndicator";
import Time from "../../components/Time";
import {Logo} from "../../Svg";
import {Calendar, Slot, SlotKind} from "../../types";
import {
  clearTime,
  buildRanges,
  Milliseconds,
  localizeMedicalRoles,
  getBookingReasonFromSearchParams,
  mobileBreakpoint,
  tryParseDate,
  MonthNames,
  addMillisecondsToDate,
  nanoseconds,
} from "../../util";
import styles from "./calendar.module.css";
import useOnScreen from "../../hooks/useOnScreen";
import SubnavPage from "../../components/layouts/SubnavPage";
import {SlotPicker} from "../../components/SlotPicker";
import {CalendarHeading} from "../../components/CalendarHeading";
import useLogger from "../../hooks/useLogger";

// How many days the calendar should show per page.
const calendarDaysPerPage = 7;

interface Params {
  slotKind: SlotKind;
}

interface L {
  search: string;
}

const loadCalendar = async (location: L, params: Params): Promise<Calendar> => {
  const searchParams = new URLSearchParams(location.search);
  const profileIds = searchParams.getAll("profileId");
  const patientProfileId = searchParams.get("patientProfileId");
  const bookingId = searchParams.get("bookingId");
  const {slotKind} = params;

  const bookingPageConfigurations = await GetBookingPageConfigurations();
  const bookingPageConfiguration = bookingPageConfigurations.find(
    (b) => b.kind === slotKind
  );
  if (!bookingPageConfiguration) {
    throw new Error(
      "unable to find booking page configuration for slotKind " + slotKind
    );
  }
  const defaultInitialDate = addMillisecondsToDate(
    new Date(),
    nanoseconds(bookingPageConfiguration.minNoticeNs).milliseconds()
  );
  const startDate = tryParseDate(searchParams.get("start"), defaultInitialDate);
  const ranges = buildRanges(startDate, calendarDaysPerPage, Milliseconds.Day);

  const reason = getBookingReasonFromSearchParams(
    searchParams,
    bookingPageConfiguration
  );

  const calendar: Calendar = {
    slotKind,
    ranges,
    showProfile: searchParams.get("showProfile") === "true",
    profileIds,
    booking: null,
    patientProfile: null,
    profiles: [],
    bookingPageConfiguration,
    reason,
    nextPageURL: "",
    prevPageURL: "",
    isPrevNavigationDisabled: false,
    monthNameSummary: "",
    showProfileInConfirmationPage: false,
  };

  const firstRange = ranges[0];
  const lastRange = ranges[ranges.length - 1];
  const dateDiff = lastRange.end.getTime() - firstRange.start.getTime();
  const backDate = clearTime(new Date(firstRange.start.getTime() - dateDiff));
  const firstMonthName = MonthNames[firstRange.start.getMonth()];
  const lastMonthName = MonthNames[lastRange.end.getMonth()];
  const firstYear = firstRange.start.getFullYear();
  const lastYear = lastRange.start.getFullYear();
  if (firstMonthName === lastMonthName) {
    calendar.monthNameSummary = `${firstMonthName} ${firstYear}`;
  } else if (firstYear !== lastYear) {
    calendar.monthNameSummary = `${firstMonthName} ${firstYear} - ${lastMonthName} ${lastYear}`;
  } else {
    calendar.monthNameSummary = `${firstMonthName} - ${lastMonthName} ${firstYear}`;
  }
  const prevLinkSearchParams = new URLSearchParams();
  prevLinkSearchParams.set("start", backDate.toISOString());
  prevLinkSearchParams.set("reason", calendar.reason);
  if (calendar.showProfile) {
    prevLinkSearchParams.set("showProfile", "true");
  }
  patientProfileId &&
    prevLinkSearchParams.set("patientProfileId", patientProfileId);
  calendar.profileIds.forEach((profileId) =>
    prevLinkSearchParams.set("profileId", profileId)
  );
  calendar.patientProfile &&
    prevLinkSearchParams.set("patientProfileId", calendar.patientProfile.id);
  calendar.booking &&
    prevLinkSearchParams.set("bookingId", calendar.booking.id);
  bookingId && prevLinkSearchParams.set("bookingId", bookingId);
  calendar.prevPageURL = "?" + prevLinkSearchParams.toString();
  calendar.isPrevNavigationDisabled = backDate < clearTime(new Date());

  const nextLinkSearchParams = new URLSearchParams();
  const forwardDate = clearTime(lastRange.end);
  nextLinkSearchParams.set("start", forwardDate.toISOString());
  nextLinkSearchParams.set("reason", reason);
  if (calendar.showProfile) {
    nextLinkSearchParams.set("showProfile", "true");
  }
  profileIds.forEach((profileId) =>
    nextLinkSearchParams.set("profileId", profileId)
  );
  patientProfileId &&
    nextLinkSearchParams.set("patientProfileId", patientProfileId);
  bookingId && nextLinkSearchParams.set("bookingId", bookingId);
  calendar.nextPageURL = "?" + nextLinkSearchParams.toString();

  if (bookingId) {
    calendar.booking = await GetBookingFromId(bookingId);
  }

  calendar.profiles = await Promise.all(
    profileIds.map((profileId) => GetPublicProfile(profileId))
  );
  calendar.showProfileInConfirmationPage =
    calendar.profiles.length > 0 || calendar.showProfile;

  if (patientProfileId) {
    calendar.patientProfile = await GetProfileFromId(patientProfileId);
  }

  return calendar;
};

const loadSlots = async (calendar: Calendar): Promise<Slot[]> => {
  const slots = await GetSlots({
    reason: calendar.reason,
    slotKind: calendar.bookingPageConfiguration.kind,
    from: calendar.ranges[0].start.toISOString(),
    to: calendar.ranges[calendar.ranges.length - 1].end.toISOString(),
    profileId: calendar.profileIds,
  });
  return slots;
};

const CalendarPage = () => {
  const location = useLocation();
  const params = useParams<Params>();
  const logger = useLogger();
  const [calendar, setCalendar] = useState<Calendar | null>(null);
  const [slots, setSlots] = useState<Slot[] | null>(null);
  useEffect(() => {
    setSlots(null);
    setCalendar(null);
    loadCalendar(location, params).then((c: Calendar) => {
      setCalendar(c);
      loadSlots(c).then(setSlots);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const [isSlotsExpanding, setIsSlotsExpanding] = useState(false);

  const expandSlots = async () => {
    if (window.innerWidth > mobileBreakpoint) {
      return;
    }
    if (!isVisible) {
      return;
    }
    if (!calendar) {
      return;
    }
    if (slots === null) {
      return;
    }
    if (isSlotsExpanding === true) {
      return;
    }
    setIsSlotsExpanding(true);
    const nextRange = buildRanges(
      calendar.ranges[calendar.ranges.length - 1].end,
      calendarDaysPerPage,
      Milliseconds.Day
    );
    try {
      const newSlots = await GetSlots({
        reason: calendar.reason,
        slotKind: calendar.bookingPageConfiguration.kind,
        from: nextRange[0].start.toISOString(),
        to: nextRange[nextRange.length - 1].end.toISOString(),
        profileId: calendar.profileIds,
      });
      const mergedSlots = [...(slots || []), ...newSlots];
      const mergedRanges = [...calendar.ranges, ...nextRange];
      setCalendar({...calendar, ranges: mergedRanges});
      setSlots(mergedSlots);
    } catch (error) {
      logger.error(error);
    }
    setIsSlotsExpanding(false);
  };
  useEffect(() => {
    expandSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, calendar, slots]);

  return (
    <NavWrapper>
      <div className={styles.bookingPageDetails}>
        {calendar && !calendar?.booking && (
          <div className={styles.bookingPageDetailsInner}>
            <h1>{calendar.bookingPageConfiguration.title}</h1>
            {calendar.bookingPageConfiguration.subTitleHTML.length > 0 && (
              <div
                dangerouslySetInnerHTML={{
                  __html: calendar.bookingPageConfiguration.subTitleHTML,
                }}></div>
            )}
          </div>
        )}
        {calendar && calendar.booking && (
          <div className={styles.bookingPageDetailsInner}>
            <h1>Omboka tid</h1>
            <div>
              Du bokar nu om ditt möte som är bokat{" "}
              <Time dateTime={calendar.booking.startsAt} />.
            </div>
          </div>
        )}

        {calendar && calendar.patientProfile && (
          <div className={styles.profileFilterContainer}>
            <div className={styles.profileFilter}>
              {calendar.patientProfile.givenName}{" "}
              {calendar.patientProfile.surname}, Patient
            </div>{" "}
          </div>
        )}

        {calendar && calendar.profiles.length > 0 && (
          <div className={styles.profileFilterContainer}>
            {calendar.profiles.map((profile) => (
              <div className={styles.profileFilter}>
                {profile.profilePicture && (
                  <img
                    src={profile.profilePicture.small}
                    alt={`${profile.givenName} ${profile.surname}`}
                  />
                )}
                {profile.givenName} {profile.surname}
                {calendar.bookingPageConfiguration.uiSettings
                  .hideProfileTitle === false && (
                  <>
                    ,{" "}
                    {profile.medicalRoles.map(localizeMedicalRoles).join(", ")}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {calendar && (
        <>
          <CalendarHeading calendar={calendar} />
          <div className={styles.contentWrapper}>
            {slots && <SlotPicker slots={slots} calendar={calendar} />}
            {!slots && <LoadingIndicator />}
          </div>
        </>
      )}
      {isSlotsExpanding && <LoadingIndicator />}
      <div ref={ref} style={{height: "40px"}}>
        {` `}
      </div>
      <div style={{marginBottom: "40px"}}></div>
    </NavWrapper>
  );
};

const NavWrapper: React.FC = ({children}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const useSubnav = searchParams.get("subNav");
  const backLink = searchParams.get("backLink");
  if (useSubnav === "true") {
    return (
      <SubnavPage backLink={backLink || "/"} title="">
        {children}
      </SubnavPage>
    );
  }
  return (
    <>
      <Heading />
      {children}
    </>
  );
};

const Heading: React.VFC = () => {
  return (
    <div className={styles.heading}>
      <div className={styles.headingInner}>
        <div className={styles.logoContainer}>
          <a href="https://womni.se/">
            <Logo />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CalendarPage;
