import { Wave } from "../Svg";
import styles from "./promos.module.css";
interface PromoProps {
  title: string;
}

export const PromoBackground: React.VFC = () => {
  return (
    <div className={styles.promoBackground}>
      <Wave />
    </div>
  );
};

export const MainPromo: React.FC<PromoProps> = ({ children, title }) => {
  return (
    <div className={styles.mainPromo}>
      <div className={styles.mainPromoInner}>
        <div className={styles.mainPromoTitle}>
          <h3>{title}</h3>
        </div>
        {children}
      </div>
    </div>
  );
};

export const AlternatePromo: React.FC<PromoProps> = ({ children, title }) => {
  return (
    <div className={styles.alternatePromo}>
      <div className={styles.alternatePromoInner}>
        <div className={styles.alternatePromoTitle}>
          <h3>{title}</h3>
        </div>
        {children}
      </div>
    </div>
  );
};
