import Nav, { ContentWrapper } from "../components/layouts/Nav";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import {
  GetQuestionnaireResponse,
  PutQuestionnaireResponse,
  GetQuestionnaire,
} from "../api";
import useLogger from "../hooks/useLogger";
import { QuestionnaireResponse, Questionnaire } from "../types";
import QuestionnaireForm from "../components/QuestionnaireForm";
import { Button } from "../components/Buttons";

import css from "./questionnaireResponse.module.css";
import LoadingIndicator from "../components/LoadingIndicator";
import Box from "../components/Box";
import SubnavPage from "../components/layouts/SubnavPage";

interface Params {
  id: string;
}

const QuestionnaireResponsePage = () => {
  const { id } = useParams<Params>();
  const logger = useLogger();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionnaireResponse, setQuestionnaireResponse] =
    useState<QuestionnaireResponse | null>(null);
  const [questionnaire, setQuestionnare] = useState<Questionnaire | null>(null);

  useEffect(() => {
    GetQuestionnaireResponse(id)
      .then((questionnaireResponse) => {
        GetQuestionnaire(questionnaireResponse.questionnaire.id).then(
          setQuestionnare
        );
        setQuestionnaireResponse(questionnaireResponse);
      })
      .catch(logger.error);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!questionnaireResponse || !questionnaire) {
    return (
      <Nav highlightedSection="quiz">
        {" "}
        <LoadingIndicator />
      </Nav>
    );
  }

  const handleQuestionnaireSubmitted = async (answers: Record<string, any>) => {
    if (!window.confirm("Är du säker på att du vill spara dina ändringar?"))
      return;
    try {
      await PutQuestionnaireResponse(questionnaireResponse.id, {
        questionnaire,
        answers,
      });
      setIsSubmitted(true);
    } catch (error) {
      logger.error(error);
      alert("Något gick fel, var god försök igen");
    }
  };

  return (
    <SubnavPage backLink="/" title={questionnaire.title}>
      <ContentWrapper>
        {!isSubmitted && (
          <QuestionnaireForm
            onSubmit={handleQuestionnaireSubmitted}
            questionnaire={questionnaire}
            previousAnswers={questionnaireResponse.answers}
          />
        )}
        {isSubmitted && (
          <Box>
            <h1>Tack</h1>
            <h3>Dina ändringar är nu sparade.</h3>
            <div className={css.ctaContainer}>
              <Button href="/" primary title="Gå till startsidan" />
              <Button
                outlined
                onClick={() => setIsSubmitted(false)}
                title="Ändra dina svar"
              />
            </div>
          </Box>
        )}
      </ContentWrapper>
    </SubnavPage>
  );
};

export default QuestionnaireResponsePage;
