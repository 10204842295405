import {ContentWrapper} from "../components/layouts/Nav";
import {SectionHeader} from "../components/Typography";
import Box from "../components/Box";
import {FormSection, Label, Select, TextArea} from "../components/Forms";
import {useEffect, useState} from "react";
import {Button} from "../components/Buttons";
import {PostTasks} from "../api";
import useLogger from "../hooks/useLogger";
import SubnavPage from "../components/layouts/SubnavPage";
import styles from "./contact.module.css";
import clsx from "clsx";

interface ContactCategory {
  title: string;
  messagingEnabled: boolean;
  followUpCategories?: ContactCategory[];
  hintMessage?: string;
}

const contactCategories: ContactCategory[] = [
  {
    title: "Jag har frågor gällande bokade möten",
    messagingEnabled: false,
    followUpCategories: [
      {
        title: "Jag önskar omboka ett möte",
        messagingEnabled: false,
        hintMessage: `<b>Omboka ett möte</b>
        <p>
          <b>OBS: du kan omboka ett möte senast 24 timmar före den utsatta tiden</b>
        </p>
        <p>
          Om du önskar avboka ett möte kan du gå till sektionen
          <a href="/bookings">möten</a> och välj det mötet du önskar omboka. 
        </p>
        <p>
          Efter att du valt det möte du önskar omboka klickar du på "Omboka möte". Därefter har du möjlighet att välja en ny tid bland läkaren eller barnmorskans tillgängliga tider.
        </p>`,
      },
      {
        title: "Jag önskar avboka ett möte",
        messagingEnabled: false,
        hintMessage: `<b>Avboka ett möte</b>
        <p>
          <b>OBS: du kan avboka ett möte senast 24 timmar före den utsatta tiden</b>
        </p>
        <p>
          Om du önskar avboka ett möte kan du gå till sektionen
          <a href="/bookings">möten</a> och välj det mötet du önskar avboka. 
        </p>
        <p>
          Efter att du valt det möte du önskar avboka klickar du på "Avboka möte". 
        </p>
        `,
      },
      {
        title: "Jag har en fråga gällande ett av mina bokade möten",
        messagingEnabled: true,
      },
    ],
  },
  {title: "Jag har frågor gällande pågende behandling", messagingEnabled: true},
  {
    title: "Jag har frågor gällande blodprov eller remisser",
    messagingEnabled: true,
  },
  {
    title: "Jag önskar förnya eller ändra ett recept",
    messagingEnabled: false,
    hintMessage: `
    <b>Önskar du förnya eller ändra ett recept?</b>
<p>
  Gå till <a href="/prescription">Receptförnyelse</a> - här kan du fylla i eller ändra ett recept.
</p>
<b>Har du frågor om ett läkemedel som är restnoterade?</b>
<p>
  Gå till <a href="/prescription">Receptförnyelse</a>. Klicka på
  "Jag vill ändra recept" och fyll i restnoterat läkemedel samt
  Orsak längst ner. Din vårdansvarige återkopplar till dig via
  Mina Sidor.
</p>`,
  },
  {
    title: "Övrigt",
    messagingEnabled: true,
  },
];

interface RecursiveCategorySelectorProps {
  categories: ContactCategory[];
  title?: string;
  disabled: boolean;
  onCategorySelected: (c: ContactCategory | null) => void;
}

const RecursiveCategorySelector: React.VFC<RecursiveCategorySelectorProps> = ({
  categories,
  title,
  disabled,
  onCategorySelected,
}) => {
  const [selectedCategory, setSelectedCategory] =
    useState<ContactCategory | null>(null);

  const handleCategoryChanged = (evt: any) => {
    const number = evt.target.value;
    if (number === -1) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(categories[number]);
    }
  };

  useEffect(() => {
    if (!selectedCategory?.followUpCategories) {
      onCategorySelected(selectedCategory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  return (
    <>
      <FormSection>
        <Label htmlFor="category">{title || "Välj ett alternativ"}</Label>{" "}
        <Select
          id="category"
          disabled={disabled}
          onChange={handleCategoryChanged}>
          <option value={-1} onSelect={() => setSelectedCategory(null)}>
            {title || "Välj ett alternativ"}
          </option>
          {categories.map((contactCategory, index) => (
            <option value={index} key={index}>
              {contactCategory.title}
            </option>
          ))}
        </Select>
      </FormSection>
      {selectedCategory?.followUpCategories && (
        <RecursiveCategorySelector
          categories={selectedCategory.followUpCategories}
          disabled={disabled}
          onCategorySelected={onCategorySelected}
        />
      )}
    </>
  );
};

const Contact = () => {
  const taskKind = "contact";
  const [selectedCategory, setSelectedCategory] =
    useState<ContactCategory | null>(null);
  const [message, setMessage] = useState<string>("");

  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const logger = useLogger();

  useEffect(() => {
    setValid(message !== "" && selectedCategory !== null);
  }, [message, selectedCategory]);

  const submit = async () => {
    if (!valid || !selectedCategory) {
      return;
    }
    setLoading(true);
    try {
      await PostTasks({
        taskKind,
        fields: {
          message,
          category: selectedCategory.title,
        },
      });
      setDone(true);
    } catch (error) {
      logger.error(error);
      setError(true);
    }
    setLoading(false);
  };

  if (done) {
    return (
      <SubnavPage backLink="/" title="Kontakta oss">
        <ContentWrapper>
          <Box>
            <SectionHeader>Ärendet är sparat</SectionHeader>
            <p>
              Vi försöker besvara dig så snabbt vi kan. Kräver det bedömning av
              läkare och/eller ändring av recept kan det ta längre tid för svar,
              mellan 5-7 arbetsdagar.
            </p>
            <p>Är ditt ärende akut? Ring 1177 för rådgivning.</p>
            <Button href="/" title="Ok" primary fullWidth centered></Button>
          </Box>
        </ContentWrapper>
      </SubnavPage>
    );
  }

  if (error) {
    return (
      <SubnavPage backLink="/" title="Kontakta oss">
        <ContentWrapper>
          <Box>
            <SectionHeader>Tekniskt fel</SectionHeader>
            <p> Något gick fel, var god försök igen</p>
            <Button
              onClick={() => setError(false)}
              title="Ok"
              primary
              fullWidth
              centered></Button>
          </Box>
        </ContentWrapper>
      </SubnavPage>
    );
  }

  const messageMaxLength = 500;

  return (
    <SubnavPage backLink="/" title="Kontakta oss">
      <ContentWrapper>
        <Box>
          <RecursiveCategorySelector
            categories={contactCategories}
            title="Välj orsak till kontakt"
            onCategorySelected={setSelectedCategory}
            disabled={loading}
          />
          {selectedCategory && selectedCategory.hintMessage && (
            <div
              className={styles.hintMessage}
              dangerouslySetInnerHTML={{
                __html: selectedCategory.hintMessage,
              }}></div>
          )}
          {selectedCategory && selectedCategory.messagingEnabled && (
            <>
              <FormSection>
                <div className={styles.messageInfo}>
                  Skriv kortfattat vad du önskar ha hjälp med. Om du har
                  komplicerade frågor som kräver bedömning av läkare kan du
                  behöva boka ett återbesök.{" "}
                  <a href="/choose-revisit-calendar">
                    Klicka här för att boka in ett återbesök
                  </a>
                </div>
                <Label htmlFor="message">Meddelande</Label>
                <TextArea
                  maxLength={messageMaxLength}
                  id="message"
                  value={message}
                  disabled={loading}
                  onChange={(evt) => setMessage(evt.target.value)}></TextArea>
                <div
                  className={clsx(styles.charCount, {
                    [styles.charCountMax]: message.length === messageMaxLength,
                  })}>
                  {message.length} / {messageMaxLength}
                </div>
              </FormSection>
              <Button
                onClick={submit}
                title="Skicka in"
                primary
                fullWidth
                centered
                disabled={!valid || loading}></Button>
            </>
          )}
        </Box>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default Contact;
