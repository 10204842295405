import {ContentWrapper} from "../../components/layouts/Nav";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import {GetBookingFromId, PostCancelBooking} from "../../api";
import {Booking} from "../../types";
import Box from "../../components/Box";

import SubnavPage from "../../components/layouts/SubnavPage";
import LoadingIndicator from "../../components/LoadingIndicator";
import Time from "../../components/Time";
import {FormSection, Label, TextArea} from "../../components/Forms";
import styles from "./cancelBooking.module.css";
import {Button} from "../../components/Buttons";
import {isCancellationAllowed} from "../../util";
import useLogger from "../../hooks/useLogger";
import {useClaimsMust} from "../../hooks/useClaims";

interface Params {
  bookingId: string;
}

const CancelBooking = () => {
  const logger = useLogger();
  const {bookingId} = useParams<Params>();
  const [reason, setReason] = useState<string>("");
  const [booking, setBooking] = useState<Booking | null>(null);
  const claims = useClaimsMust();
  const loadBooking = async () => {
    const booking = await GetBookingFromId(bookingId);
    setBooking(booking);
  };

  const cancelBooking = async () => {
    setBooking(null);
    try {
      await PostCancelBooking(bookingId, {
        reason,
      });
    } catch (error) {
      logger.error(error);
    }
    window.location.href = `/bookings/${bookingId}`;
  };

  useEffect(() => {
    loadBooking();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!booking) {
    return (
      <SubnavPage backLink={`/bookings/${bookingId}`} title="Avboka möte">
        <LoadingIndicator />
      </SubnavPage>
    );
  }

  if (!isCancellationAllowed(booking, claims)) {
    return (
      <SubnavPage backLink={`/bookings/${bookingId}`} title="Avboka möte">
        <ContentWrapper>
          <Box>
            <h3>Mötet kan avbokas senast 24 timmar före den bokade tiden.</h3>
            <br />
            <Button
              href={`/bookings/${bookingId}`}
              primary
              title="OK"
              fullWidth
              centered
            />
          </Box>
        </ContentWrapper>
      </SubnavPage>
    );
  }
  return (
    <SubnavPage backLink={`/bookings/${bookingId}`} title="Avboka möte">
      <ContentWrapper>
        <Box>
          <div className={styles.cancelBookingForm}>
            <p>
              Du är på väg att avboka följande tid: <br />{" "}
              <Time dateTime={booking.startsAt} />
            </p>

            <FormSection>
              <Label htmlFor="reason">Vänligen berätta varför du avbokar</Label>
              <TextArea
                id="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </FormSection>
            <Button
              disabled={reason === ""}
              onClick={cancelBooking}
              primary
              title="Bekräfta avbokning"
              fullWidth
              centered
            />
          </div>
        </Box>
        <small>
          Avbokning inom 24 timmar från bokad tid debiteras 100% av
          patientpriset.
        </small>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default CancelBooking;
