import { useEffect, useState } from "react";
import { GetInvoices } from "../api";
import Box from "../components/Box";
import InvoiceTable from "../components/InvoiceTable";
import { ContentWrapper } from "../components/layouts/Nav";
import SubnavPage from "../components/layouts/SubnavPage";
import LoadingIndicator from "../components/LoadingIndicator";
import useLogger from "../hooks/useLogger";
import { Invoice } from "../types";

const Invoices = () => {
  const [invoices, setInvoices] = useState<Invoice[] | null>(null);
  const logger = useLogger();

  useEffect(() => {
    GetInvoices()
      .then((invoices) =>
        setInvoices(invoices.filter(byUnpaidStates).sort(byDate))
      )
      .catch((error) => logger.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!invoices) {
    return (
      <SubnavPage backLink="/account" title="Fakturor">
        <ContentWrapper>
          <LoadingIndicator />
        </ContentWrapper>
      </SubnavPage>
    );
  }

  return (
    <SubnavPage backLink="/account" title="Fakturor">
      <ContentWrapper>
        <Box noPadding={invoices.length > 0}>
          {invoices.length === 0 && <p>Du har inga fakturor</p>}
          {invoices.length > 0 && <InvoiceTable invoices={invoices} />}
        </Box>
      </ContentWrapper>
    </SubnavPage>
  );
};

const byUnpaidStates = (a: Invoice): boolean => {
  switch (a.paymentStatus) {
    case "Unpaid":
      return true;
    case "Overdue":
      return true;
    case "PartlyPaid":
      return true;
    case "Reminder_1":
      return true;
    case "Reminder_2":
      return true;
    case "Reminder_3":
      return true;
    case "Reminder_Many":
      return true;
    case "CollectorEnded":
      return true;
    case "Collecting":
      return true;
    case "Collection":
      return true;
    case "Factoring":
      return true;
    default:
      return false;
  }
};

const byDate = (a: Invoice, b: Invoice): number => {
  const da = new Date(a.createdAt);
  const db = new Date(b.createdAt);
  return db.getTime() - da.getTime();
};

export default Invoices;
