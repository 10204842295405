import clsx from "clsx";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {GetBookingFromId, PostRating} from "../api";
import Box from "../components/Box";
import {Button} from "../components/Buttons";
import {ContentWrapper} from "../components/layouts/Nav";
import SubnavPage from "../components/layouts/SubnavPage";
import {Star} from "../Svg";
import {Booking} from "../types";
import styles from "./createRating.module.css";
import useLogger from "../hooks/useLogger";
import {
  FormSection,
  Input,
  Label,
  RadioButton,
  RadioButtonList,
} from "../components/Forms";

const sources = [
  "Av en vän",
  "Sökmotor (google, bing, etc)",
  "Facebook",
  "Instagram",
  "Youtube",
  "Werlabs",
];

interface Params {
  id: string;
}

const CreateRating = () => {
  const {id} = useParams<Params>();
  const logger = useLogger();
  const [booking, setBooking] = useState<Booking | null>(null);
  const [rating, setRating] = useState<Number | null>(null);
  const [text, setText] = useState<string>("");
  const [source, setSource] = useState<string>("");
  const [freeFormSource, setFreeFormSource] = useState<string>("");
  const [wouldRecommend, setWouldRecommend] = useState<Boolean | null>(null);
  const isValid = rating !== null && wouldRecommend !== null;
  const [isPosted, setIsPosted] = useState(false);
  useEffect(() => {
    if (!booking) {
      GetBookingFromId(id).then(setBooking);
    }
  }, [booking, id, logger]);

  const submit = async () => {
    if (!booking) return;
    await PostRating({
      bookingId: booking.id,
      rating,
      wouldRecommend,
      text,
      source: freeFormSource !== "" ? freeFormSource : source,
    });
    setIsPosted(true);
  };

  return (
    <SubnavPage backLink="/bookings" title="Lämna feedback">
      <ContentWrapper>
        <Box>
          {isPosted && (
            <div>
              <h3>Tack för ditt svar</h3>
              <Button href="/" title="Tillbaka till startsidan" />
            </div>
          )}
          {!isPosted && booking && (
            <div className={styles.createRating}>
              <div className={styles.formSection}>
                <h3>
                  Vad tyckte du om mötet med {booking.bookedPerson.firstName}?
                </h3>

                <div className={styles.buttonsContainer}>
                  <Button
                    title={
                      <>
                        <Star />
                      </>
                    }
                    outlined
                    secondary={rating === null}
                    primary={rating === 1}
                    onClick={() => setRating(1)}
                  />
                  <Button
                    outlined
                    title={
                      <>
                        <Star />
                        <Star />
                      </>
                    }
                    secondary={rating === null}
                    primary={rating === 2}
                    onClick={() => setRating(2)}
                    className={clsx({[styles.highlighted]: rating === 2})}
                  />
                  <Button
                    outlined
                    title={
                      <>
                        <Star />
                        <Star />
                        <Star />
                      </>
                    }
                    secondary={rating === null}
                    primary={rating === 3}
                    onClick={() => setRating(3)}
                    className={clsx({[styles.highlighted]: rating === 3})}
                  />
                  <Button
                    outlined
                    title={
                      <>
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                      </>
                    }
                    secondary={rating === null}
                    primary={rating === 4}
                    onClick={() => setRating(4)}
                    className={clsx({[styles.highlighted]: rating === 4})}
                  />
                  <Button
                    outlined
                    title={
                      <>
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                        <Star />
                      </>
                    }
                    secondary={rating === null}
                    primary={rating === 5}
                    onClick={() => setRating(5)}
                    className={clsx({[styles.highlighted]: rating === 5})}
                  />
                </div>
              </div>
              <div className={styles.formSection}>
                <h3>Skulle du rekommendera Womnis rådgivning till andra?</h3>

                <div className={styles.buttonsContainer}>
                  <Button
                    title="Ja"
                    outlined
                    primary={wouldRecommend === true}
                    onClick={() => setWouldRecommend(true)}
                  />
                  <Button
                    title="Nej"
                    outlined
                    primary={wouldRecommend === false}
                    onClick={() => setWouldRecommend(false)}
                  />
                </div>
              </div>
              <div className={styles.formSection}>
                <h3>Har du någon feedback, önskemål eller frågor?</h3>

                <div className={styles.buttonsContainer}>
                  <textarea
                    className={styles.inputTextarea}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.formSection}>
                <h3>Hur hörde du talas om Womni?</h3>
                <RadioButtonList>
                  {sources.map((s, id) => (
                    <RadioButton
                      key={s}
                      name={`${id}`}
                      id={`${id}`}
                      onChange={() => {
                        setSource(s);
                        setFreeFormSource("");
                      }}
                      checked={source === s}
                      label={s}
                    />
                  ))}
                  <RadioButton
                    name={`other`}
                    id={`other`}
                    onChange={() => {
                      setSource("other");
                      setFreeFormSource("");
                    }}
                    checked={source === "other"}
                    label={"På annat sätt"}
                  />
                </RadioButtonList>
                {source === "other" && (
                  <FormSection>
                    <Label htmlFor="name">Jag hörde talas om womni via:</Label>{" "}
                    <Input
                      type="text"
                      placeholder="Ange vart du hörde talas om Womni"
                      onChange={(e) => setFreeFormSource(e.target.value)}
                      value={freeFormSource}
                    />
                  </FormSection>
                )}
              </div>

              <div className={styles.ctaContainer}>
                <Button
                  primary={isValid}
                  disabled={!isValid}
                  fullWidth
                  centered
                  onClick={submit}
                  outlined
                  title="Skicka svar"
                />
              </div>
            </div>
          )}
        </Box>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default CreateRating;
