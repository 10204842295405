import React, {useContext, useEffect, useState} from "react";
import {BrowserRouter as Router, Route, useLocation} from "react-router-dom";
import {
  GetBookings,
  GetMessages,
  GetMyFollowUpQueue,
  ValidateAuth,
} from "./api";

import Login from "./pages/Login";
import Forbidden from "./pages/Forbidden";
import Home from "./pages/Home";
import UploadFile from "./pages/UploadFile";
import CreateRating from "./pages/CreateRating";
import QuestionnairePage from "./pages/QuestionnairePage";
import QuestionnaireResponse from "./pages/QuestionnaireResponse";
import Settings from "./pages/Settings";
import ViewBooking from "./pages/bookings/ViewBooking";
import QuizResultsPage from "./pages/QuizResults";
import Bookings from "./pages/Bookings";
import Hormones from "./pages/info/Hormones";
import PrescriptionPage from "./pages/Prescription";
import Contact from "./pages/Contact";
import Account from "./pages/Account";
import Messages from "./pages/Messages";
import MyHealth from "./pages/MyHealth";
import {MessagesContext} from "./contexts/MessagesContext";
import {Booking, FollowUp, Message} from "./types";
import Invoices from "./pages/Invoices";
import {BookingsContext} from "./contexts/BookingsContext";
import {isFuture} from "./util";
import Calendar from "./pages/bookings/Calendar";
import ConfirmBooking from "./pages/bookings/ConfirmBooking";
import Elements from "./pages/examples/Elements";
import ChooseRevisitCalendar from "./pages/ChooseRevisitCalendar";
import RescheduleBooking from "./pages/bookings/RescheduleBooking";
import CancelBooking from "./pages/bookings/CancelBooking";
import Samples from "./pages/Samples";
import Sample from "./pages/Sample";

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    ValidateAuth().then(setIsAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      const minute = 60 * 1000;
      const interval = setInterval(() => {
        ValidateAuth();
      }, 5 * minute);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated]);

  return (
    <Providers>
      <Router>
        <LocationChangeHandler isAuthenticated={isAuthenticated} />
        {process.env.REACT_APP_IS_LOCAL && (
          <Route exact path="/ui/elements" component={Elements} />
        )}
        <Route exact path="/forbidden" component={Forbidden} />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/choose-revisit-calendar"
          component={ChooseRevisitCalendar}
        />
        <Route exact path="/calendar/:slotKind" component={Calendar} />
        <Route exact path="/booking/confirm" component={ConfirmBooking} />
        {!isAuthenticated && <Route exact path="/login" component={Login} />}
        {isAuthenticated && (
          <Route exact path="/login">
            <LoginRedirect />
          </Route>
        )}

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/"
          component={Home}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/bookings"
          component={Bookings}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/bookings/:bookingId"
          component={ViewBooking}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/bookings/:bookingId/reschedule"
          component={RescheduleBooking}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/bookings/:bookingId/cancel"
          component={CancelBooking}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/questionnaire/:id"
          component={QuestionnairePage}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/prescription"
          component={PrescriptionPage}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/questionnaire-responses/:id"
          component={QuestionnaireResponse}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/quiz-results/:id"
          component={QuizResultsPage}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/upload"
          component={UploadFile}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/rate/:id"
          component={CreateRating}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/samples"
          component={Samples}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/samples/:testMethodCode"
          component={Sample}
        />

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/settings"
          component={Settings}></ProtectedRoute>

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/account"
          component={Account}></ProtectedRoute>

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/messages"
          component={Messages}></ProtectedRoute>

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/my-health"
          component={MyHealth}></ProtectedRoute>

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/quiz"
          component={Redirect}
          redirectURL="/my-health"></ProtectedRoute>

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/invoices"
          component={Invoices}></ProtectedRoute>

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/info/hormones"
          component={Hormones}></ProtectedRoute>

        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/contact"
          component={Contact}></ProtectedRoute>
      </Router>
    </Providers>
  );
};

interface RedirectParams {
  redirectURL: string;
}

const Redirect: React.VFC<RedirectParams> = ({redirectURL}) => {
  window.location.assign(redirectURL);
  return <></>;
};

const LoginRedirect = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectURL = urlParams.get("redirectURL") || "/";
  window.location.assign(redirectURL);
  return <></>;
};

const ProtectedRoute = ({component, isAuthenticated, ...rest}: any) => {
  const routeComponent = (props: any) => {
    if (isAuthenticated === true) {
      return React.createElement(component, {...props, ...rest});
    } else if (isAuthenticated === false) {
      window.location.assign(
        "/login?redirectURL=" + encodeURIComponent(window.location.href)
      );
      return <div></div>;
    } else {
      return <div></div>;
    }
  };
  return <Route {...rest} render={routeComponent} />;
};

const Providers: React.FC = ({children}) => {
  const [messages, setMessages] = useState<Message[] | null>(null);
  const [bookings, setBookings] = useState<Booking[] | null>(null);
  const [futureBookings, setFutureBookings] = useState<Booking[] | null>(null);
  const [pastBookings, setPastBookings] = useState<Booking[] | null>(null);
  const [followUpQueue, setFollowUpQueue] = useState<FollowUp[] | null>(null);
  return (
    <MessagesContext.Provider value={{messages, setMessages}}>
      <BookingsContext.Provider
        value={{
          bookings,
          futureBookings,
          pastBookings,
          followUpQueue,
          setBookings,
          setFutureBookings,
          setPastBookings,
          setFollowUpQueue,
        }}>
        {children}
      </BookingsContext.Provider>
    </MessagesContext.Provider>
  );
};

// Triggers to be handled when location of the app changes.
const LocationChangeHandler = ({isAuthenticated}: any) => {
  const location = useLocation();
  const {setMessages} = useContext(MessagesContext);
  const {setBookings, setFutureBookings, setPastBookings, setFollowUpQueue} =
    useContext(BookingsContext);

  useEffect(() => {
    if (!isAuthenticated) return;
    GetMessages().then(setMessages);
    GetBookings().then((bookings) => {
      setBookings(bookings);
      setFutureBookings(bookings.filter((booking) => isFuture(booking)));
      setPastBookings(bookings.filter((booking) => !isFuture(booking)));
    });
    GetMyFollowUpQueue().then(setFollowUpQueue);
    window.scrollTo({top: 0, behavior: "auto"});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key, isAuthenticated]);
  return <></>;
};

export default App;
