import { CheckIcon } from "../Svg";
import { Button } from "./Buttons";
import { AlternatePromo } from "./Promos";
import styles from "./noBookingsPromo.module.css";

const NoBookingsPromo: React.VFC = () => {
  return (
    <AlternatePromo title="Ta kontroll över klimakteriet">
      <div className={styles.noBookingsPromo}>
        <p>
          Womni erbjuder digital klimakterierådgivning och individuell
          behandling av vårdpersonal med specialutbildning inom klimakteriet.
        </p>
        <Button
          fullWidth
          primary
          centered
          href="https://womni.se/boka-tid"
          title="Läs mer &amp; Boka tid"
        />
        <ul>
          <li>
            <CheckIcon />
            Video- eller telefonmöte
          </li>
          <li>
            <CheckIcon />
            Snabbt och enkelt
          </li>
          <li>
            <CheckIcon />
            Oavsett var i Sverige du bor
          </li>
        </ul>
      </div>
    </AlternatePromo>
  );
};
export default NoBookingsPromo;
