import styles from "./loadingIndicator.module.css";

const AnimatedSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 50">
      <circle fill="#9f59b3" stroke="none" cx="10" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="1;0;1;1;"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#9f59b3" stroke="none" cx="30" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="1;0;1;1;"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="#9f59b3" stroke="none" cx="50" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="1;0;1;1;"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
      <circle fill="#9f59b3" stroke="none" cx="70" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="1;0;1;1;"
          repeatCount="indefinite"
          begin="0.4"
        />
      </circle>
      <circle fill="#9f59b3" stroke="none" cx="90" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="2s"
          values="1;0;1;1;"
          repeatCount="indefinite"
          begin="0.5"
        />
      </circle>
    </svg>
  );
};

const LoadingIndicator: React.VFC = () => {
  return (
    <div className={styles.loadingIndicator}>
      <AnimatedSvg />
    </div>
  );
};

export default LoadingIndicator;
