import styles from "./nav.module.css";
import {
  Heart,
  HomeIcon,
  Logo,
  MessagesIcon,
  TimeIcon,
  UserIcon,
} from "../../Svg";
import clsx from "clsx";
import {isMobile} from "react-device-detect";
import {Link} from "react-router-dom";
import {useContext} from "react";
import {MessagesContext} from "../../contexts/MessagesContext";
import {PromoBackground} from "../Promos";

export type Section = "messages" | "home" | "account" | "quiz" | "bookings";

interface NavProps {
  highlightedSection: Section;
}

const Nav: React.FC<NavProps> = ({children, highlightedSection}) => {
  return (
    <div
      className={clsx(styles.root, {
        [styles.mobile]: isMobile,
      })}>
      <div className={styles.header}>
        <div className={styles.headerInner}>
          <Link to="/">
            <Logo className={styles.logo} />
          </Link>
        </div>
        <MainNav highlightedSection={highlightedSection} />
      </div>
      <PromoBackground />
      {children}
    </div>
  );
};

export const ContentMaxWidth: React.FC = ({children}) => {
  return <div className={styles.contentMaxWidth}>{children}</div>;
};

export const ContentWrapper: React.FC<React.ComponentProps<"div">> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={clsx(className, styles.content)} {...rest}>
      {children}
    </div>
  );
};

const MainNav: React.VFC<NavProps> = ({highlightedSection}) => {
  const {messages} = useContext(MessagesContext);
  const unreadCount =
    (messages && messages.filter((m) => !m.viewedByReceiver).length) || 0;

  return (
    <div className={clsx(styles.mainNav, {[styles.mobile]: isMobile})}>
      <Link
        to="/"
        className={clsx(styles.mainNavButton, {
          [styles.highlighted]: highlightedSection === "home",
        })}>
        <HomeIcon />
        <span>Startsida</span>
      </Link>
      <Link
        to="/bookings"
        className={clsx(styles.mainNavButton, {
          [styles.highlighted]: highlightedSection === "bookings",
        })}>
        <TimeIcon />
        <span>Möten</span>
      </Link>
      <Link
        to="/messages"
        className={clsx(styles.mainNavButton, {
          [styles.highlighted]: highlightedSection === "messages",
        })}>
        <MessagesIcon />
        {unreadCount !== 0 && (
          <div className={styles.notifiationBadge}>{unreadCount}</div>
        )}
        <span>Meddelanden</span>
      </Link>
      <Link
        to="/my-health"
        className={clsx(styles.mainNavButton, {
          [styles.highlighted]: highlightedSection === "quiz",
        })}>
        <Heart />
        <span>Min hälsa</span>
      </Link>
      <Link
        to="/account"
        className={clsx(styles.mainNavButton, {
          [styles.highlighted]: highlightedSection === "account",
        })}>
        <UserIcon />
        <span>Mitt konto</span>
      </Link>
    </div>
  );
};

export default Nav;
