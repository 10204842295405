import styles from "./focusPage.module.css";
import { Logo } from "../../Svg";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const FocusPage: React.VFC<Props> = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Logo className={styles.logo} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default FocusPage;
