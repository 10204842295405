import React from "react";
import {Button, ButtonGroup} from "./Buttons";
import {ChevronLeft, ChevronRight} from "../Svg";
import styles from "./calendarHeading.module.css";
import BookingHeader from "./BookingHeader";
import {Calendar} from "../types";

export interface CalendarHeadingProps {
  calendar: Calendar;
}

export const CalendarHeading: React.VFC<CalendarHeadingProps> = ({
  calendar,
}) => {
  return (
    <BookingHeader
      className={styles.calendarHeading}
      leftCol={<></>}
      centerCol={
        <h3 className={styles.monthNames}>{calendar.monthNameSummary}</h3>
      }
      rightCol={
        <div className={styles.jumpButtons}>
          <ButtonGroup>
            <Button
              outlined
              title={<ChevronLeft />}
              href={calendar.prevPageURL}
              disabled={calendar.isPrevNavigationDisabled}
              className={styles.midButton}
            />
            <Button
              outlined
              title={<ChevronRight />}
              href={calendar.nextPageURL}
            />
          </ButtonGroup>
        </div>
      }
    />
  );
};
