import { useEffect, useState } from "react";
import { GetMyProfile, PatchMyProfile } from "../api";
import Box from "../components/Box";
import { Button } from "../components/Buttons";
import { FormSection, Input, Label } from "../components/Forms";
import { ContentWrapper } from "../components/layouts/Nav";
import SubnavPage from "../components/layouts/SubnavPage";
import LoadingIndicator from "../components/LoadingIndicator";
import { Profile } from "../types";

const Settings = () => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [email, setEmail] = useState<string>("");
  const [telephoneNumber, setTelephoneNumber] = useState<string>("");
  useEffect(() => {
    GetMyProfile().then(setProfile);
  }, []);

  useEffect(() => {
    if (!profile) {
      return;
    }

    setEmail(profile.email);
    setTelephoneNumber(profile.telephoneNumber);
  }, [profile]);

  const handleSaveClicked = async () => {
    PatchMyProfile({ email, telephoneNumber }).then(() => {
      alert("Dina ändringar är nu sparade");
    });
  };

  if (!profile) {
    return (
      <SubnavPage backLink="/account" title="Inställningar">
        <LoadingIndicator />
      </SubnavPage>
    );
  }

  return (
    <SubnavPage backLink="/account" title="Inställningar">
      <ContentWrapper>
        <Box>
          <p>
            {profile && (
              <>
                <h3>
                  {profile.givenName} {profile.surname}
                </h3>
                <span>{profile.personalNumber}</span>
              </>
            )}
          </p>
          <FormSection>
            <Label htmlFor="email">E-postaddress</Label>
            <Input
              id="email"
              type="text"
              value={email || ""}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </FormSection>
          <FormSection>
            <Label htmlFor="telephoneNumber">Telefonnummer</Label>
            <Input
              id="telephoneNumber"
              type="text"
              value={telephoneNumber || ""}
              onChange={(evt) => setTelephoneNumber(evt.target.value)}
            />
          </FormSection>
          <p>
            Vänligen säkerställ att din kontaktinformation är korrekt, då denna
            används för att vi skall kunna nå dig med viktig information.
          </p>
          <Button
            onClick={handleSaveClicked}
            title="Spara ändringar"
            primary
            fullWidth
            centered
          />
        </Box>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default Settings;
