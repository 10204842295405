import clsx from "clsx";
import styles from "./bookingHeader.module.css";

interface CalendarHeaderProps extends React.ComponentProps<"div"> {
  leftCol: React.ReactNode;
  centerCol: React.ReactNode;
  rightCol: React.ReactNode;
}

const BookingHeader: React.VFC<CalendarHeaderProps> = ({
  leftCol,
  centerCol,
  rightCol,
  className,
  ...rest
}) => {
  return (
    <div className={styles.alternateBackground}>
      <div className={styles.contentWrapper}>
        <div className={clsx(className, styles.bookingHeader)} {...rest}>
          <div className={styles.bookingHeaderColumn}>{leftCol}</div>
          <div className={styles.bookingHeaderColumn}>{centerCol}</div>
          <div className={styles.bookingHeaderColumn}>{rightCol}</div>
        </div>
      </div>
    </div>
  );
};

export default BookingHeader;
