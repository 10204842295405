import { createContext } from "react";
import { Booking, FollowUp } from "../types";

export interface BookingsContextData {
  bookings: Booking[] | null;
  futureBookings: Booking[] | null;
  pastBookings: Booking[] | null;
  followUpQueue: FollowUp[] | null;
  setBookings: (booking: Booking[]) => void;
  setFutureBookings: (booking: Booking[]) => void;
  setPastBookings: (booking: Booking[]) => void;
  setFollowUpQueue: (FollowUpQueueItem: FollowUp[]) => void;
}

export const BookingsContext = createContext<BookingsContextData>({
  bookings: null,
  futureBookings: null,
  pastBookings: null,
  followUpQueue: null,
  setBookings: () => {},
  setFutureBookings: () => {},
  setPastBookings: () => {},
  setFollowUpQueue: () => {},
});
