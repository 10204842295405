import { isMobile } from "react-device-detect";
import { Button } from "./Buttons";
import { MainPromo } from "./Promos";

const QuestionnaireReminder = () => {
  return (
    <MainPromo title="Hälsoenkät">
      <p>
        För att du ska få ut så mycket som möjligt av ditt bokade möte ber vi
        dig fylla i vår hälsoenkät.
      </p>
      <Button
        fullWidth={isMobile}
        centered={isMobile}
        primary
        href="/questionnaire/pre-appointment"
        title="Fyll i hälsoenkät"
      />
    </MainPromo>
  );
};

export default QuestionnaireReminder;
