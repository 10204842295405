import Rollbar from "rollbar";
import { Logger } from "../types";

const rollbar =
  process.env.REACT_APP_ROLLBAR_ENV === "development"
    ? console
    : new Rollbar({
        accessToken: "0f242b1217f347ebb15548e75c7f6d69",
        environment: "production",
        captureUncaught: true,
        captureUnhandledRejections: true,
      });

const useLogger = (): Logger => {
  return rollbar;
};

export default useLogger;
