import {
  BankIDLoginSession,
  Booking,
  CollectResponse,
  ErrorResponse,
  Invoice,
  Message,
  Profile,
  UpdateProfileRequest,
  FileWithProfile,
  QuestionnaireResponse,
  QuizResults,
  Questionnaire,
  SlotQuery,
  Slot,
  BookingPageConfiguration,
  CreateBookingRequest,
  PublicProfile,
  FollowUp,
  CancelBookingRequest,
  RescheduleBookingRequest,
  InvoiceFull,
  RebateApplicability,
  RebateApplicabilityRequest,
  Sample,
  AnalysisDescription,
} from "./types";

export const ValidateAuth = async () => {
  try {
    const response = await request(
      `${process.env.REACT_APP_API_URL}/auth/validate`,
      "GET",
      null,
      true
    );
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const GetMySamples = async (): Promise<Sample[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/samples`,
    "GET"
  );
  return response.json();
};

export const GetAnalysisDescriptions = async (): Promise<
  AnalysisDescription[]
> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/public/analysis-descriptions`,
    "GET"
  );
  return response.json();
};

export const PostCancelBooking = async (
  id: string,
  data: CancelBookingRequest
): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/bookings/${id}/cancel`,
    "POST",
    JSON.stringify(data)
  );
};

export const PostRescheduleBooking = async (
  id: string,
  data: RescheduleBookingRequest
): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/bookings/${id}/reschedule`,
    "POST",
    JSON.stringify(data)
  );
};

export const PostBookings = async (
  data: CreateBookingRequest
): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/public/bookings`,
    "POST",
    JSON.stringify(data)
  );
};

export const GetBookingPageConfigurations = async (): Promise<
  BookingPageConfiguration[]
> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/public/booking-page-configurations?v=2`,
    "GET"
  );
  return response.json();
};

export const PostTasks = async (data: any): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/tasks`,
    "POST",
    JSON.stringify(data)
  );
};

export const GetQuestionnaire = async (id: string): Promise<Questionnaire> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}//questionnaires/${id}`,
    "GET"
  );
  return response.json();
};

export const GetSlots = async (options: SlotQuery): Promise<Slot[]> => {
  const params = serializeQueryString({...options, r: Math.random()});
  const response = await request(
    `${process.env.REACT_APP_API_URL}/public/bookings/slots?${params}`
  );
  return response.json();
};

export const GetPublicProfile = async (id: string): Promise<PublicProfile> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/public/profile/${id}`
  );
  return response.json();
};

export const GetProfileFromId = async (id: string): Promise<Profile> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/profiles/id/${id}`
  );
  return response.json();
};

export const PostAuthBankID = async (): Promise<BankIDLoginSession> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/auth/bankid`,
    "POST",
    JSON.stringify({})
  );
  return response.json();
};

export const PostRebateApplicability = async (
  options: RebateApplicabilityRequest
): Promise<RebateApplicability> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/rebates/is-applicable`,
    "POST",
    JSON.stringify(options)
  );
  return response.json();
};

export const GetInvoices = async (): Promise<Invoice[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/invoices`
  );
  return response.json();
};

export const GetInvoice = async (id: string): Promise<InvoiceFull> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/invoices/${id}`
  );
  return response.json();
};

export const GetBookings = async (): Promise<Booking[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/bookings`
  );
  return response.json();
};

export const GetMyFollowUpQueue = async (): Promise<FollowUp[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/follow-up-queue`
  );
  return response.json();
};

export const GetQuestionnaireResponses = async (): Promise<
  QuestionnaireResponse[]
> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/questionnaire-responses`
  );
  return response.json();
};

export const GetMessages = async (): Promise<Message[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/messages`
  );
  return response.json();
};

export const GetQuizResults = async (): Promise<QuizResults[]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/me/quiz-results`
  );
  return response.json();
};

export const GetQuizResultsById = async (id: string): Promise<QuizResults> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/quiz-results/${id}`
  );
  return response.json();
};

export const GetMyProfile = async (): Promise<Profile> => {
  const response = await request(`${process.env.REACT_APP_API_URL}/me/profile`);
  return response.json();
};

export const PatchMyProfile = async (
  updateProfileRequest: UpdateProfileRequest
) => {
  await request(
    `${process.env.REACT_APP_API_URL}/me/profile`,
    "PATCH",
    JSON.stringify(updateProfileRequest)
  );
};

export const PostRating = async (options: any): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/ratings`,
    "POST",
    JSON.stringify(options)
  );
};

export const PostQuestionnaireResponse = async (
  options: any
): Promise<QuestionnaireResponse> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/questionnaire-responses`,
    "POST",
    JSON.stringify(options)
  );
  return response.json();
};

export const PutQuestionnaireResponse = async (
  id: string,
  options: any
): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/questionnaire-responses/${id}`,
    "PUT",
    JSON.stringify(options)
  );
};

export const GetQuestionnaireResponse = async (
  id: string
): Promise<QuestionnaireResponse> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/questionnaire-responses/${id}`
  );
  return response.json();
};

export const GetBookingFromId = async (id: string): Promise<Booking> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/bookings/${id}`
  );
  return response.json();
};

export const PostMessageViewed = async (id: string): Promise<void> => {
  await request(
    `${process.env.REACT_APP_API_URL}/messages/${id}/viewed`,
    "POST",
    JSON.stringify({})
  );
};

export const PostAuthBankIDCollect = async (
  orderRef: string
): Promise<[CollectResponse | null, ErrorResponse | undefined]> => {
  const response = await request(
    `${process.env.REACT_APP_API_URL}/auth/bankid/collect?login=true`,
    "POST",
    JSON.stringify({orderRef}),
    true
  );
  if (!response.ok) {
    const errorResponse = (await response.json()) as ErrorResponse;
    return [null, errorResponse];
  }
  const collectResponse = await response.json();
  return [collectResponse, undefined];
};

export const PostAuthBankIDCancel = async (orderRef: string): Promise<void> => {
  try {
    await request(
      `${process.env.REACT_APP_API_URL}/auth/bankid/cancel`,
      "POST",
      JSON.stringify({orderRef})
    );
  } catch (error) {}
};

export const PostFiles = async (fileList: FileList): Promise<void> => {
  for (var i = 0; i < fileList.length; i++) {
    const file = fileList.item(i);
    if (!file) return;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file.name);
    formData.append("description", "uppladdad via app.womni.se");
    await request(`${process.env.REACT_APP_API_URL}/files`, "POST", formData);
  }
};

export const GetMyFiles = async (): Promise<FileWithProfile[]> => {
  const response = await request(`${process.env.REACT_APP_API_URL}/me/files`);
  return response.json();
};

const request = async (
  url: string,
  method: string = "GET",
  body: string | FormData | null = null,
  skipValidation: boolean = false,
  cache: RequestCache = "no-store"
) => {
  const response = await fetch(url, {
    cache,
    credentials: "include",
    method,
    body,
  });
  if (skipValidation) return response;
  if (response.status === 401) {
    window.location.href = "/login";
  }
  if (!response.ok) {
    throw new Error(
      `invalid response from ${method} ${url}: ${response.status}`
    );
  }
  return response;
};

const serializeQueryString = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      const value = obj[p];
      if (Array.isArray(value)) {
        for (var i = 0; i < value.length; i++) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value[i]));
        }
      } else {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value));
      }
    }
  return str.join("&");
};
