import Nav, { ContentWrapper } from "../components/layouts/Nav";
const Forbidden = () => {
  return (
    <Nav highlightedSection="home">
      <ContentWrapper>
        <h3>Ogiltig roll</h3>
        <p>Du saknar rättigheter att utföra åtgärden.</p>
      </ContentWrapper>
    </Nav>
  );
};

export default Forbidden;
