export const FormattedDate: React.VFC<{ date: string }> = ({ date }) => {
  const parsedDate = new Date(date);
  return <>{parsedDate.toLocaleDateString()}</>;
};

interface VisibleIfProps {
  condition: boolean;
}

export const VisibleIf: React.FC<VisibleIfProps> = ({
  condition,
  children,
}) => {
  return <>{condition && children}</>;
};
