import {useCookies} from "react-cookie";
import {Rebate} from "../types";

const useRebate = (): Rebate | undefined => {
  const [cookies] = useCookies(["rebate"]);
  if (!cookies.rebate) return;
  try {
    return JSON.parse(window.atob(cookies.rebate)) as Rebate;
  } catch (error) {
    return;
  }
};

export default useRebate;
