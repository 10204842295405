import {ContentWrapper} from "../../components/layouts/Nav";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

import {GetBookingFromId} from "../../api";
import {Booking} from "../../types";
import Box from "../../components/Box";
import Time from "../../components/Time";

import styles from "./viewBooking.module.css";
import {Button} from "../../components/Buttons";
import {isMobile} from "react-device-detect";
import clsx from "clsx";
import {EmptyProfile} from "../../Svg";
import SubnavPage from "../../components/layouts/SubnavPage";
import {
  isCancellationAllowed,
  isFuture,
  localizeMedicalRoles,
  nanoseconds,
} from "../../util";
import LoadingIndicator from "../../components/LoadingIndicator";
import {useClaimsMust} from "../../hooks/useClaims";
interface Params {
  bookingId: string;
}

const ViewBooking = () => {
  const claims = useClaimsMust();
  const {bookingId} = useParams<Params>();
  const [booking, setBooking] = useState<Booking | null>(null);
  const loadBooking = async () => {
    const booking = await GetBookingFromId(bookingId);
    setBooking(booking);
  };
  useEffect(() => {
    loadBooking();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!booking) {
    return (
      <SubnavPage backLink="/bookings" title="Bokat möte">
        <LoadingIndicator />
      </SubnavPage>
    );
  }

  return (
    <SubnavPage backLink="/bookings" title="Bokat möte">
      <ContentWrapper>
        <Box noPadding>
          <div
            className={clsx(styles.booking, {
              [styles.cancelled]: booking.cancelled,
              [styles.mobile]: isMobile,
              [styles.desktop]: !isMobile,
            })}>
            <div className={styles.bookingHeader}>
              <div className={styles.bookedPerson}>
                <div className={styles.profilePicture}>
                  {booking.bookedPerson.profilePicture && (
                    <img
                      src={booking.bookedPerson.profilePicture.small}
                      alt={booking.bookedPerson.firstName}
                    />
                  )}
                  {!booking.bookedPerson.profilePicture && <EmptyProfile />}
                </div>
                <Time dateTime={booking.startsAt} />
                <h3>
                  {booking.bookedPerson.firstName}{" "}
                  {booking.bookedPerson.lastName}
                </h3>
                <div className={styles.role}>
                  {booking.bookedPerson.medicalRoles
                    .map(localizeMedicalRoles)
                    .join(",")}
                </div>
              </div>
              {booking.cancelled && (
                <div className={styles.cancelledWarning}>
                  Detta möte är avbokat
                </div>
              )}
              {!booking.cancelled && booking.meetingKind === "phone" && (
                <p>
                  Telefonmöte, du kommer bli uppringd på{" "}
                  {booking.telephoneNumber} när mötet börjar.
                </p>
              )}
              {!booking.cancelled && booking.meetingKind === "video" && (
                <>
                  <p>
                    Du har valt att ha ett videomöte. Vänligen klicka på knappen
                    "Starta videomöte" för att starta.
                  </p>
                  <p>
                    Kontrollera gärna att du har mikrofon och kamera påslagen
                    innan du går med i mötet.
                  </p>
                </>
              )}
            </div>
            {booking.cancelled === false && (
              <div>
                <div className={styles.ctaContainer}>
                  <div className={styles.leftCol}>
                    {booking.meetingKind === "video" && (
                      <Button
                        primary
                        fullWidth={isMobile}
                        centered={isMobile}
                        title="Starta videomöte"
                        href={`${process.env.REACT_APP_MEET_URL}/${booking.id}`}
                      />
                    )}
                  </div>
                  <div className={styles.rightCol}>
                    {isFuture(booking) && (
                      <>
                        <Button
                          outlined
                          fullWidth={isMobile}
                          centered={isMobile}
                          disabled={!isCancellationAllowed(booking, claims)}
                          disabledTitle={`Mötet kan ombokas senast ${nanoseconds(
                            booking.bookingPageConfiguration
                              .minCancellationNoticeNs
                          ).hours()} timmar före bokad tid`}
                          title="Omboka möte"
                          href={`/bookings/${booking.id}/reschedule`}
                        />
                        <Button
                          outlined
                          fullWidth={isMobile}
                          centered={isMobile}
                          disabled={!isCancellationAllowed(booking, claims)}
                          disabledTitle={`Mötet kan avbokas senast ${nanoseconds(
                            booking.bookingPageConfiguration
                              .minCancellationNoticeNs
                          ).hours()} timmar före bokad tid`}
                          title="Avboka möte"
                          href={`/bookings/${booking.id}/cancel`}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Box>
        <small>
          Avbokning inom 24 timmar från bokad tid debiteras 100% av
          patientpriset.
        </small>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default ViewBooking;
