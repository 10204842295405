import { useContext } from "react";
import Nav, {
  ContentMaxWidth,
  ContentWrapper,
} from "../components/layouts/Nav";
import LoadingIndicator from "../components/LoadingIndicator";
import MessageList from "../components/MessageList";
import { AlternatePromo } from "../components/Promos";
import { VisibleIf } from "../components/Util";
import { MessagesContext } from "../contexts/MessagesContext";

const Messages = () => {
  const { messages } = useContext(MessagesContext);
  if (!messages) {
    return (
      <Nav highlightedSection="messages">
        <LoadingIndicator />
      </Nav>
    );
  }

  return (
    <Nav highlightedSection="messages">
      <VisibleIf condition={messages.length === 0}>
        <ContentMaxWidth>
          <AlternatePromo title="Inga meddelanden">
            <p>Du har inga meddelanden.</p>
          </AlternatePromo>
        </ContentMaxWidth>
      </VisibleIf>
      <VisibleIf condition={messages.length > 0}>
        <ContentWrapper>
          <MessageList messages={messages} />
        </ContentWrapper>
      </VisibleIf>
    </Nav>
  );
};

export default Messages;
