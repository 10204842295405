import clsx from "clsx";
import styles from "./box.module.css";

interface BoxProps extends React.ComponentProps<"div"> {
  noPadding?: boolean;
  noHeaderColor?: boolean;
}

const Box: React.FC<BoxProps> = ({
  children,
  noPadding,
  noHeaderColor,
  className,
  ...rest
}) => {
  return (
    <div
      className={clsx(className, styles.box, {
        [styles.noPadding]: noPadding,
        [styles.noHeaderColor]: noHeaderColor,
      })}
      {...rest}>
      {children}
    </div>
  );
};

export const BoxSpacing = () => {
  return <div className={styles.boxSpacing} />;
};

export default Box;
