import {
  Booking,
  BookingPageConfiguration,
  BookingReason,
  Claims,
  DateRange,
  Duration,
  MedicalRole,
} from "./types";

// Note: breakpoint also defined in css
export const mobileBreakpoint = 800;

export const DayNames = [
  "Söndag",
  "Måndag",
  "Tisdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lördag",
];

export const DayNamesShort = ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"];

export const MonthNames = [
  "Januari",
  "Februari",
  "Mars",
  "April",
  "Maj",
  "Juni",
  "Juli",
  "Augusti",
  "September",
  "Oktober",
  "November",
  "December",
];

export const getBookingReasonFromSearchParams = (
  searchParams: URLSearchParams,
  conf: BookingPageConfiguration
): BookingReason => {
  const p = searchParams.get("reason");
  switch (p) {
    case "pms":
      return "pms";
    case "menopause":
      return "menopause";
    case "fertility":
      return "fertility";
    default:
      return conf.defaultBookingReason;
  }
};

export enum Milliseconds {
  Second = 1000,
  Minute = 60 * Second,
  Hour = 60 * Minute,
  Day = 24 * Hour,
}

export const millisecondsFromNanos = (nanos: number): Milliseconds =>
  nanos / 1000000;

export const isFuture = (booking: Booking): Boolean => {
  const starts = new Date(booking.startsAt);
  const now = new Date();

  const diff = now.getTime() - starts.getTime();
  const padding = 5 * Milliseconds.Hour;
  return diff < padding;
};

export const clearTime = (date: Date): Date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const buildRanges = (
  s: Date,
  increments: number,
  incrementSize: Milliseconds
): DateRange[] => {
  let startDate = new Date(s.getTime());
  clearTime(startDate);
  let endDate = addMillisecondsToDate(startDate, increments * incrementSize);
  let ranges: DateRange[] = [];
  while (startDate < endDate) {
    let rangeEnd = addMillisecondsToDate(startDate, incrementSize);
    let startDateOffset = startDate.getTimezoneOffset();
    let endDateOffset = rangeEnd.getTimezoneOffset();

    if (startDateOffset !== endDateOffset) {
      if (startDateOffset - endDateOffset > 0) {
      } else {
        rangeEnd = addMillisecondsToDate(
          rangeEnd,
          (endDateOffset - startDateOffset) * Milliseconds.Minute
        );
      }
    }
    ranges.push({
      start: new Date(startDate.getTime()),
      end: rangeEnd,
    });
    startDate = rangeEnd;
  }
  return ranges;
};

export const addDaysToDate = (date: Date, numberOfDays: number): Date => {
  let futureDate = new Date(date);
  futureDate.setDate(futureDate.getDate() + numberOfDays);
  return futureDate;
};

export const addMillisecondsToDate = (date: Date, millis: number): Date => {
  let futureDate = new Date(date);
  futureDate.setTime(futureDate.getTime() + millis);
  return futureDate;
};

export const isCancellationAllowed = (
  booking: Booking,
  claims: Claims
): boolean => {
  if (claims.role === "admin") {
    return true;
  }
  const diff = new Date().getTime() - new Date(booking.startsAt).getTime();
  const minCancellationNoticeMS = nanoseconds(
    booking.bookingPageConfiguration.minCancellationNoticeNs
  ).milliseconds();
  const isCancellationAllowed = diff < -minCancellationNoticeMS;
  return isCancellationAllowed;
};

export const localizeMedicalRoles = (m: MedicalRole): string => {
  switch (m) {
    case "medical-doctor":
      return "Läkare";
    case "gynecologist":
      return "Gynekolog";
    case "midwife":
      return "Barnmorska";
    case "sexologist":
      return "Sexolog";
    case "therapist":
      return "Samtalsterapeut";
    case "tester":
      return "Testare";
    case "patient-support":
      return "Patientsupport";
    default:
      return m;
  }
};

export const localizeMedicalRolesPlural = (m: MedicalRole): string => {
  switch (m) {
    case "medical-doctor":
      return "Läkare";
    case "gynecologist":
      return "Gynekologer";
    case "midwife":
      return "Barnmorskor";
    case "sexologist":
      return "Sexologer";
    case "therapist":
      return "Samtalsterapeuter";
    case "tester":
      return "Testare";
    case "patient-support":
      return "Patientsupport";
    default:
      return m;
  }
};

export const tryParseDate = (s: string | undefined | null, def: Date): Date => {
  if (!s || s === "") return def;
  try {
    const timestamp = Date.parse(s);
    if (isNaN(timestamp) === false) {
      return new Date(timestamp);
    }
    return def;
  } catch {
    return def;
  }
};

const msInNs = 1000000;
const secondInNs = 1000 * msInNs;
const minuteInNs = 60 * secondInNs;
const hourInNs = 60 * minuteInNs;
const dayInNs = 24 * hourInNs;

export const nanoseconds = (ns: number) => {
  return {
    nanoseconds: () => ns,
    milliseconds: () => ns / msInNs,
    seconds: () => {
      return ns / secondInNs;
    },
    minutes: () => {
      return ns / minuteInNs;
    },
    hours: () => {
      return ns / hourInNs;
    },
  };
};

export const milliseconds = (ms: number): Duration => nanoseconds(ms * msInNs);

export const seconds = (s: number): Duration => nanoseconds(s * secondInNs);
export const minutes = (m: number): Duration => nanoseconds(m * minuteInNs);
export const hours = (h: number): Duration => nanoseconds(h * hourInNs);
export const days = (d: number): Duration => nanoseconds(d * dayInNs);
