import { ContentWrapper } from "../components/layouts/Nav";
import { SectionHeader } from "../components/Typography";
import Box from "../components/Box";
import {
  FormSection,
  Input,
  Label,
  RadioButton,
  RadioButtonList,
} from "../components/Forms";
import { useEffect, useState } from "react";
import { Button } from "../components/Buttons";
import { PostTasks } from "../api";
import useLogger from "../hooks/useLogger";
import SubnavPage from "../components/layouts/SubnavPage";

type RenewalKind = "renew" | "edit" | "unknown";

const Prescription = () => {
  const taskKind = "prescription";
  const [renewalKind, setRenewalKind] = useState<RenewalKind>("unknown");
  const [name, setName] = useState<string>("");
  const [strength, setStrength] = useState<string>("");
  const [dosage, setDosage] = useState<string>("");
  const [preparationForm, setPreparationForm] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [valid, setValid] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);
  const logger = useLogger();

  useEffect(() => {
    setValid(
      name !== "" &&
        ((renewalKind === "edit" && reason !== "") || renewalKind === "renew")
    );
  }, [renewalKind, name, reason]);

  const submit = async () => {
    if (!valid) {
      return;
    }
    setLoading(true);
    try {
      await PostTasks({
        taskKind,
        fields: {
          renewalKind,
          name,
          dosage,
          strength,
          preparationForm,
          reason,
        },
      });
      setDone(true);
    } catch (error) {
      logger.error(error);
      setError(true);
    }
    setLoading(false);
  };

  if (done) {
    return (
      <SubnavPage backLink="/" title="Recept">
        <ContentWrapper>
          <Box>
            <SectionHeader>Receptförnyelse skickad</SectionHeader>
            <p>
              Vi har nu mottagit ditt ärende, vi kommer förnya ditt recept inom
              7 dagar. Du kommer få svaret på din förfrågan via Mina Sidor.
            </p>
            <Button href="/" title="Ok" primary fullWidth centered></Button>
          </Box>
        </ContentWrapper>
      </SubnavPage>
    );
  }

  if (error) {
    return (
      <SubnavPage backLink="/" title="Recept">
        <ContentWrapper>
          <Box>
            <SectionHeader>Tekniskt fel</SectionHeader>
            <p> Något gick fel, var god försök igen</p>
            <Button
              onClick={() => setError(false)}
              title="Ok"
              primary
              fullWidth
              centered
            ></Button>
          </Box>
        </ContentWrapper>
      </SubnavPage>
    );
  }

  return (
    <SubnavPage backLink="/" title="Recept">
      <ContentWrapper>
        <Box>
          <FormSection>
            <RadioButtonList>
              <RadioButton
                name="renewalKind"
                id="renewalKindRenewal"
                checked={renewalKind === "renew"}
                onChange={() => setRenewalKind("renew")}
                label={<b>Jag vill förnya ett recept</b>}
                description="Jag har ett recept som löper ut som jag vill förnya."
              />
              <RadioButton
                name="renewalKind"
                id="renewalKindEdit"
                checked={renewalKind === "edit"}
                onChange={() => setRenewalKind("edit")}
                label={<b>Jag vill ändra recept</b>}
                description="Jag önskar att ändra preparat på ett av mina recept."
              />
            </RadioButtonList>
          </FormSection>
          {renewalKind !== "unknown" && (
            <div>
              <FormSection>
                <Label htmlFor="name">Läkemedlets namn (obligatoriskt)</Label>{" "}
                <Input
                  id="name"
                  type="text"
                  value={name}
                  disabled={loading}
                  onChange={(evt) => setName(evt.target.value)}
                ></Input>
              </FormSection>
              <FormSection>
                <Label htmlFor="strength">Styrka (t ex. 500mg)</Label>{" "}
                <Input
                  id="strength"
                  type="text"
                  value={strength}
                  disabled={loading}
                  onChange={(evt) => setStrength(evt.target.value)}
                ></Input>
              </FormSection>
              <FormSection>
                <Label htmlFor="dosage">Dosering (t ex. 5ml 3ggr/dag)</Label>{" "}
                <Input
                  id="dosage"
                  type="text"
                  value={dosage}
                  disabled={loading}
                  onChange={(evt) => setDosage(evt.target.value)}
                ></Input>
              </FormSection>
              <FormSection>
                <Label htmlFor="preparationForm">
                  Beredningsform (t ex tabletter)
                </Label>{" "}
                <Input
                  id="preparationForm"
                  type="text"
                  value={preparationForm}
                  disabled={loading}
                  onChange={(evt) => setPreparationForm(evt.target.value)}
                ></Input>
              </FormSection>
              {renewalKind === "edit" && (
                <FormSection>
                  <Label htmlFor="reason">
                    Vad är orsaken till att du vill ändra preparat?
                    (obligatoriskt)
                  </Label>{" "}
                  <Input
                    id="reason"
                    type="text"
                    value={reason}
                    disabled={loading}
                    onChange={(evt) => setReason(evt.target.value)}
                  ></Input>
                </FormSection>
              )}
              <Button
                onClick={submit}
                title="Skicka in"
                primary
                fullWidth
                centered
                disabled={!valid || loading}
              ></Button>
            </div>
          )}
        </Box>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default Prescription;
