import {ChangeEvent, useEffect, useState} from "react";
import {GetMyFiles, PostFiles} from "../api";
import {FileWithProfile} from "../types";
import Box, {BoxSpacing} from "../components/Box";
import {Button} from "../components/Buttons";
import {ContentWrapper} from "../components/layouts/Nav";
import styles from "./uploadFile.module.css";
import {UploadFile as UploadFileIcon} from "../Svg";
import SubnavPage from "../components/layouts/SubnavPage";
import LoadingIndicator from "../components/LoadingIndicator";
import {VisibleIf} from "../components/Util";
import {SectionHeader} from "../components/Typography";
import Time from "../components/Time";

type UploadState = "init" | "uploading" | "done";

const UploadFile = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [selectedFilesList, setSelectedFilesList] = useState<File[] | null>(
    null
  );
  const [uploadState, setUploadState] = useState<UploadState>("init");
  const [files, setFiles] = useState<FileWithProfile[] | null>(null);
  const onFileChanged = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFiles(event.target.files);
    setSelectedFilesList(
      Array.prototype.map.call(
        event.target.files,
        (file: File) => file
      ) as File[]
    );
  };

  useEffect(() => {
    GetMyFiles().then(setFiles);
  }, [uploadState]);

  const setInitState = () => {
    setSelectedFiles(null);
    setSelectedFilesList(null);
    setUploadState("init");
  };

  const handleUploadFilesClicked = async () => {
    if (!selectedFiles) return;
    setUploadState("uploading");
    try {
      await PostFiles(selectedFiles);
      setUploadState("done");
    } catch (error: any) {
      alert("Något gick fel, var god försök igen.");
      setInitState();
    }
  };

  return (
    <SubnavPage backLink="/" title="Ladda upp dokument">
      <ContentWrapper>
        <VisibleIf condition={uploadState === "uploading"}>
          <div className={styles.paddedSection}>
            <h3>Laddar upp filen</h3>
            <LoadingIndicator />
          </div>
        </VisibleIf>
        <VisibleIf condition={uploadState === "done"}>
          <Box>
            <div className={styles.paddedSection}>
              <h3>Filen är nu uppladdad</h3>
              <Button
                outlined
                primary
                fullWidth
                centered
                title="Ladda upp fler filer"
                onClick={setInitState}
              />
              <Button
                outlined
                centered
                fullWidth
                title="Tillbaka till startsidan"
                href="/"
              />
            </div>
          </Box>
        </VisibleIf>
        <VisibleIf condition={uploadState === "init"}>
          <Box noPadding>
            <div className={styles.paddedSection}>
              <p>
                Dela information med din läkare eller barnmorska på ett säkert
                och tryggt sätt. <br />
                Kom ihåg att det är viktigt att ditt namn, personnummer och
                datum framgår tydligt i inscannade dokument.
              </p>
              <div className={styles.fileSection}>
                {(!selectedFiles || selectedFiles.length === 0) && (
                  <>
                    <h3>Välj den fil du vill dela</h3>

                    <label htmlFor="file">
                      <div className={styles.svgContainer}>
                        <UploadFileIcon />
                      </div>
                      Välj fil
                    </label>
                    <input
                      type="file"
                      id="file"
                      name="file"
                      onChange={onFileChanged}></input>
                  </>
                )}
                {selectedFilesList && selectedFilesList.length >= 1 && (
                  <>
                    <h3>Följande fil(er) kommer laddas upp:</h3>
                    {selectedFilesList.map((file, i) => (
                      <p key={i}>{file.name}</p>
                    ))}
                    <Button
                      outlined
                      fullWidth
                      centered
                      primary
                      title="Starta uppladdning"
                      onClick={handleUploadFilesClicked}
                    />
                    <Button
                      outlined
                      centered
                      fullWidth
                      title="Välj en annan fil"
                      onClick={setInitState}
                    />
                  </>
                )}
              </div>
            </div>
          </Box>
        </VisibleIf>{" "}
        {files && files.length > 0 && (
          <>
            <BoxSpacing />
            <SectionHeader>Tidigare uppladdade filer</SectionHeader>
            <Box noPadding>
              <div className={styles.paddedSection}></div>
              <table className={styles.filesTable}>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Namn</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file) => (
                    <tr key={file.file.id}>
                      <td width="100px">
                        <Time
                          dateTime={file.file.uploadedAt}
                          options={{
                            dateStyle: "medium",
                            timeStyle: undefined,
                          }}
                        />
                      </td>
                      <td>{file.file.name}</td>
                      <td>
                        {file.file.acknowledged ? "Hanterad" : "Granskas"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </>
        )}
      </ContentWrapper>
    </SubnavPage>
  );
};

export default UploadFile;
