import { QuizResults } from "../types";

import styles from "./quizList.module.css";
import { Button } from "./Buttons";
import Time from "./Time";

interface QuizResultsListProps {
  quizResults: QuizResults[];
}

const QuizList: React.VFC<QuizResultsListProps> = ({ quizResults }) => {
  return (
    <div className={styles.quizResultsList}>
      {quizResults.map((qr) => (
        <div className={styles.quizResultsListItem} key={qr.id}>
          <div className={styles.quizResultsListItemColLeft}>
            <div className={styles.quizResultsType}>
              Självskattningstest för klimakteriet
            </div>
            <Time
              dateTime={qr.createdAt}
              options={{ dateStyle: "medium", timeStyle: undefined }}
            />
          </div>
          <div className={styles.quizResultsListItemColRight}>
            <Button href={`/quiz-results/${qr.id}`} primary title="Visa" />
          </div>
        </div>
      ))}
    </div>
  );
};
export default QuizList;
