import {useCookies} from "react-cookie";
import {Claims} from "../types";

const useClaims = (): Claims | undefined => {
  const [cookies] = useCookies(["user"]);
  if (!cookies.user) return;
  try {
    return JSON.parse(atob(cookies.user)) as Claims;
  } catch (error) {
    return;
  }
};

export const useClaimsMust = (): Claims => {
  const claims = useClaims();
  if (!claims) {
    throw new Error("Claims not available");
  }
  return claims;
};

export default useClaims;
