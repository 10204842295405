import {useEffect, useRef, useState} from "react";
import {ContentWrapper} from "../components/layouts/Nav";
import LoadingIndicator from "../components/LoadingIndicator";
import {Analysis, AnalysisDescription} from "../types";
import {GetAnalysisDescriptions, GetMySamples} from "../api";
import styles from "./sample.module.css";
import Box from "../components/Box";
import {useParams} from "react-router-dom";
import SubnavPage from "../components/layouts/SubnavPage";
import Time from "../components/Time";

interface AnalysisWithDescription extends Analysis, AnalysisDescription {
  drawTime: string;
  parsedValue: number;
  parsedValueIsLte: boolean;
  valueStatus: "low" | "high" | "normal";
}

interface Params {
  testMethodCode: string;
}

const Sample = () => {
  const {testMethodCode} = useParams<Params>();
  const [analysisWithDescription, setAnalysisWithDescription] =
    useState<AnalysisWithDescription | null>(null);

  const loadAnalysisSummaries = async () => {
    const samples = await GetMySamples();
    const analysesDescriptions = await GetAnalysisDescriptions();
    const matchesTestCode = (a: Analysis) => {
      return (
        a.testMethodCode === testMethodCode &&
        !isNaN(Number.parseFloat(a.value.replace("<", "")))
      );
    };
    const sample = samples.find((s) => s.analyses.find(matchesTestCode));
    if (!sample) {
      return;
    }
    const analysis = sample.analyses.find(matchesTestCode);
    if (!analysis) {
      return;
    }
    const description = analysesDescriptions.find(
      (ad) => ad.name === analysis.name
    );
    if (!description) {
      return;
    }
    const parsedValue = Number.parseFloat(analysis.value.replace("<", ""));
    const parsedValueIsLte = analysis.value[0] === "<";
    if (isNaN(parsedValue)) {
      return;
    }
    const as: AnalysisWithDescription = {
      ...analysis,
      ...description,
      parsedValue,
      parsedValueIsLte,
      valueStatus:
        parsedValue > description.referenceValueHigh
          ? "high"
          : parsedValue < description.referenceValueLow
          ? "low"
          : "normal",
      drawTime: sample.drawTime,
    };
    setAnalysisWithDescription(as);
  };

  useEffect(() => {
    loadAnalysisSummaries();
  }, []);

  if (analysisWithDescription === null) {
    return (
      <SubnavPage backLink="/samples" title="Laddar...">
        <LoadingIndicator />
      </SubnavPage>
    );
  }

  return (
    <SubnavPage backLink="/samples" title={analysisWithDescription.name}>
      <ContentWrapper>
        {analysisWithDescription.parsedValueIsLte ||
          (!analysisWithDescription.referenceValuesVisible && (
            <Box>
              <div className={styles.lteValue}>
                <h3>
                  {analysisWithDescription.parsedValueIsLte && <>&lt;</>}
                  {analysisWithDescription.parsedValue}
                  {` `}

                  <span
                    dangerouslySetInnerHTML={{
                      __html: analysisWithDescription.unit,
                    }}></span>
                </h3>
              </div>
            </Box>
          ))}
        {!analysisWithDescription.parsedValueIsLte &&
          analysisWithDescription.referenceValuesVisible && (
            <SampleValueIndicator
              analysisWithDescription={analysisWithDescription}
            />
          )}

        <div className={styles.sampleDate}>
          Test utfört:{` `}
          <Time
            dateTime={analysisWithDescription.drawTime}
            options={{dateStyle: "short"}}
          />
        </div>
        <p>
          <FormattedText text={analysisWithDescription.description} />
        </p>
        {analysisWithDescription.descriptionHigh !== "" && (
          <>
            <h3>Högt värde</h3>
            <p>
              <FormattedText text={analysisWithDescription.descriptionHigh} />
            </p>
          </>
        )}
        {analysisWithDescription.descriptionLow !== "" && (
          <>
            <h3>Lågt värde</h3>
            <p>
              {" "}
              <FormattedText text={analysisWithDescription.descriptionLow} />
            </p>
          </>
        )}
      </ContentWrapper>
    </SubnavPage>
  );
};

interface FormattedTextProps {
  text: string;
}
const FormattedText: React.VFC<FormattedTextProps> = ({text}) => {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: text.replaceAll("\n", "<br />"),
      }}></span>
  );
};

interface SampleValueIndicatorProps {
  analysisWithDescription: AnalysisWithDescription;
}

const SampleValueIndicator: React.VFC<SampleValueIndicatorProps> = ({
  analysisWithDescription,
}) => {
  const refScaleRef = useRef<HTMLDivElement | null>(null);
  const valueIndicatorRef = useRef<HTMLDivElement | null>(null);
  const [refScaleWidth, setRefScaleWidth] = useState<number | null>(null);
  useEffect(() => {
    if (refScaleRef.current) {
      setRefScaleWidth(refScaleRef.current.offsetWidth);
    }
  }, [refScaleRef]);
  let offsetPixels: number = 0;
  if (refScaleWidth) {
    const refValueDiff =
      analysisWithDescription.referenceValueHigh -
      analysisWithDescription.referenceValueLow;
    const refScaleStart =
      analysisWithDescription.referenceValueLow - refValueDiff;
    const refScaleEnd =
      analysisWithDescription.referenceValueHigh + refValueDiff;
    const normalized =
      (analysisWithDescription.parsedValue - refScaleStart) /
      (refScaleEnd - refScaleStart);
    offsetPixels =
      normalized * refScaleWidth - valueIndicatorRef.current!.offsetWidth / 2;
  }
  return (
    <Box noHeaderColor>
      <div
        ref={valueIndicatorRef}
        className={styles.valueIndicator}
        style={{marginLeft: `${offsetPixels}px`}}>
        {analysisWithDescription.parsedValue}
        {` `}
        <span
          dangerouslySetInnerHTML={{
            __html: analysisWithDescription.unit,
          }}></span>
        <div className={styles.valueIndicatorLine}></div>
      </div>
      <div className={styles.refScaleContainer}>
        <div className={styles.refScale} ref={refScaleRef}>
          <div className={styles.refScaleZone}>
            <span className={styles.refScaleZoneLow}>
              {analysisWithDescription.referenceValueLow}
            </span>
            <span className={styles.refScaleZoneHigh}>
              {analysisWithDescription.referenceValueHigh}
            </span>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Sample;
