import styles from "./dialogBox.module.css";
interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const DialogBox: React.VFC<Props> = ({ children }) => {
  return <div className={styles.root}>{children}</div>;
};

export default DialogBox;
