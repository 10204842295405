import { useContext } from "react";
import BookingList from "../components/BookingList";
import Box, { BoxSpacing } from "../components/Box";
import Nav, {
  ContentMaxWidth,
  ContentWrapper,
} from "../components/layouts/Nav";
import LoadingIndicator from "../components/LoadingIndicator";
import { AlternatePromo } from "../components/Promos";
import { SectionHeader } from "../components/Typography";
import { VisibleIf } from "../components/Util";
import { BookingsContext } from "../contexts/BookingsContext";

const Account = () => {
  const { futureBookings, pastBookings } = useContext(BookingsContext);
  if (!futureBookings || !pastBookings) {
    return (
      <Nav highlightedSection="bookings">
        <LoadingIndicator />
      </Nav>
    );
  }
  const pastAndCancelled = [
    ...futureBookings.filter((b) => b.cancelled === true),
    ...pastBookings,
  ];
  const futureNonCancelledBookings = futureBookings.filter(
    (b) => b.cancelled === false
  );
  return (
    <Nav highlightedSection="bookings">
      <VisibleIf condition={futureNonCancelledBookings.length === 0}>
        <ContentMaxWidth>
          <AlternatePromo title="Inga möten">
            <p>Du har inga möten planerade</p>
          </AlternatePromo>
        </ContentMaxWidth>
      </VisibleIf>
      <ContentWrapper>
        <VisibleIf condition={futureNonCancelledBookings.length > 0}>
          <SectionHeader>Kommande möten</SectionHeader>
          <Box noPadding>
            <BookingList bookings={futureNonCancelledBookings} />
          </Box>
        </VisibleIf>
        <BoxSpacing />
        <VisibleIf condition={pastAndCancelled.length > 0}>
          <SectionHeader>Tidigare möten</SectionHeader>
          <Box noPadding>
            <BookingList bookings={pastAndCancelled} />
          </Box>
        </VisibleIf>
      </ContentWrapper>
    </Nav>
  );
};

export default Account;
