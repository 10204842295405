import styles from "./navTable.module.css";

interface NavTableProps {}
export const NavTable: React.FC<NavTableProps> = ({ children }) => {
  return <div className={styles.navTable}>{children}</div>;
};

interface NavTableItemProps {}

export const NavTableItem: React.FC<NavTableItemProps> = ({ children }) => {
  return <div className={styles.navTableItem}>{children}</div>;
};
