import { createContext } from "react";
import { Message } from "../types";

export interface MessagesContextData {
  messages: Message[] | null;
  setMessages: (messages: Message[]) => void;
}

export const MessagesContext = createContext<MessagesContextData>({
  messages: null,
  setMessages: () => {},
});
