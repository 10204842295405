import {useCallback, useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useLocation} from "react-router-dom";
import {
  GetBookingFromId,
  GetBookingPageConfigurations,
  GetProfileFromId,
  GetPublicProfile,
  PostBookings,
  PostRebateApplicability,
  PostRescheduleBooking,
} from "../../api";
import {Button} from "../../components/Buttons";
import {
  Checkbox,
  FormSection,
  Input,
  Label,
  RadioButton,
  RadioButtonList,
  ValidationMessage,
} from "../../components/Forms";
import {ContentWrapper} from "../../components/layouts/Nav";
import LoadingIndicator from "../../components/LoadingIndicator";
import Time from "../../components/Time";
import useClaims from "../../hooks/useClaims";
import {
  Booking,
  BookingPageConfiguration,
  BookingReason,
  MeetingKind,
  Profile,
  PublicProfile,
  Rebate,
  RebateApplicabilityWithError,
  SlotKind,
} from "../../types";
import {
  Milliseconds,
  getBookingReasonFromSearchParams,
  localizeMedicalRoles,
  millisecondsFromNanos,
} from "../../util";
import {
  maskCharacters,
  masker,
  length,
  notEmpty,
  validator,
  validEmail,
  validPhoneNumber,
  maskLength,
  validSwedishPersonalNumber,
} from "../../validators";
import styles from "./confirmBooking.module.css";
import BookingHeader from "../../components/BookingHeader";
import {ChevronLeft} from "../../Svg";
import useLogger from "../../hooks/useLogger";
import {AlternatePromo} from "../../components/Promos";
import clsx from "clsx";
import useRebate from "../../hooks/useRebate";

const ensureString = (a: any): string => {
  if (typeof a === "string") return a;
  return JSON.stringify(a);
};

const ConfirmBooking = () => {
  const logger = useLogger();
  const search = useLocation().search;
  const claims = useClaims();
  const rebateFromCookie = useRebate();
  const searchParams = new URLSearchParams(search);
  const profileId = searchParams.get("profileId");
  const patientProfileId = searchParams.get("patientProfileId");
  const [reason, setReason] = useState<BookingReason | null>(null);
  const bookingId = searchParams.get("bookingId");
  const kind = searchParams.get("kind");
  const start = searchParams.get("start");
  const returnUrl = searchParams.get("returnUrl") || "/calendar";
  const showProfile = Boolean(searchParams.get("showProfile"));
  const [booking, setBooking] = useState<Booking | null>(null);
  const [isConfirmingOwnBooking, setIsConfirmingOwnBooking] = useState(true);
  const [profile, setProfile] = useState<PublicProfile | null>(null);
  const [patientProfile, setPatientProfile] = useState<Profile | null>(null);
  const [rebate, setRebate] = useState<Rebate | null>(null);
  const [bookingPageConfiguration, setBookingPageConfiguration] =
    useState<BookingPageConfiguration | null>(null);
  const [utmCookies] = useCookies([
    "utm_source",
    "utm_content",
    "utm_campaign",
    "utm_medium",
    "utm_term",
  ]);

  const initializeForm = async () => {
    try {
      const bookingPageConfigurations = await GetBookingPageConfigurations();

      const bookingPageConfiguration = bookingPageConfigurations.find(
        (b) => b.kind === kind
      );
      if (bookingPageConfiguration) {
        setReason(
          getBookingReasonFromSearchParams(
            searchParams,
            bookingPageConfiguration
          )
        );
      }

      setBookingPageConfiguration(bookingPageConfiguration || null);
      if (
        rebateFromCookie?.allowedProductIds.includes(
          bookingPageConfiguration?.billingProductId || ""
        ) &&
        !patientProfileId
      ) {
        setRebate(rebateFromCookie);
      }
    } catch (error) {
      logger.error(error);
      setError(error);
    }

    try {
      if (showProfile && profileId) {
        const profile = await GetPublicProfile(profileId);
        setProfile(profile);
      }
    } catch (error) {
      logger.error(error);
    }

    try {
      if (patientProfileId) {
        const patientProfile = await GetProfileFromId(patientProfileId);
        setPatientProfile(patientProfile);
        form.givenName = patientProfile.givenName;
        form.surname = patientProfile.surname;
        form.email = patientProfile.email;
        form.telephoneNumber = patientProfile.telephoneNumber;
        form.personalNumber = patientProfile.personalNumber;
        form.hasAcceptedTerms = false;
        form.meetingKind = "choose";
      }
    } catch (error) {
      logger.error(error);
      setError(error);
    }

    try {
      if (bookingId) {
        const booking = await GetBookingFromId(bookingId);
        setIsConfirmingOwnBooking(
          claims?.personalNumber === booking.personalNumber
        );
        form.meetingKind = booking.meetingKind;
        form.hasAcceptedTerms = true;
        setBooking(booking);
      }
    } catch (error) {
      logger.error(error);
      setError(error);
    }
    setForm({...form});
  };

  useEffect(() => {
    initializeForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialForm = patientProfileId
    ? {
        givenName: "",
        surname: "",
        email: "",
        telephoneNumber: "",
        personalNumber: "",
        hasAcceptedTerms: false,
        meetingKind: "choose" as MeetingKind,
      }
    : {
        givenName: claims?.givenName || "",
        surname: claims?.surname || "",
        email: claims?.email || "",
        telephoneNumber: claims?.telephoneNumber || "",
        personalNumber: claims?.personalNumber || "",
        hasAcceptedTerms: false,
        meetingKind: "choose" as MeetingKind,
      };

  const [form, setForm] = useState(initialForm);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  type formKey = keyof typeof form;

  const [formValidationResults, setFormValidationResults] = useState<
    Partial<Record<formKey, boolean>>
  >({});
  const validators: Partial<Record<formKey, validator[]>> = {
    givenName: [notEmpty],
    surname: [notEmpty],
    email: [validEmail],
    telephoneNumber: [validPhoneNumber],
    personalNumber: [length(12), validSwedishPersonalNumber],
    hasAcceptedTerms: [(s: boolean) => s === true],
    meetingKind: [(s: MeetingKind) => s === "phone" || s === "video"],
  };
  const masks: Partial<Record<formKey, masker[]>> = {
    telephoneNumber: [maskCharacters("0123456789-+")],
    personalNumber: [maskCharacters("0123456789"), maskLength(12)],
  };

  const [rebateApplicability, setRebateApplicability] =
    useState<RebateApplicabilityWithError | null>(null);

  const checkRebateApplicability = useCallback(async () => {
    if (!rebate || !bookingPageConfiguration) {
      return;
    }
    try {
      const applicability = await PostRebateApplicability({
        code: rebate?.code,
        personalNumber: form.personalNumber,
        slotKind: bookingPageConfiguration.kind,
      });
      setRebateApplicability({...applicability, error: false});
    } catch (error) {
      setRebateApplicability({
        isApplicableToSlotKind: true,
        isConsumed: false,
        isExpired: false,
        error: true,
      });
    }
  }, [rebate, bookingPageConfiguration, form.personalNumber]);

  const rebateIsApplicable = (): boolean => {
    return (
      rebateApplicability === null ||
      (rebateApplicability.error === false &&
        rebateApplicability.isApplicableToSlotKind === true &&
        rebateApplicability.isExpired === false &&
        rebateApplicability.isConsumed === false)
    );
  };

  useEffect(() => {
    if (rebate && formValidationResults.personalNumber) {
      checkRebateApplicability();
    }
  }, [formValidationResults, rebate, checkRebateApplicability]);

  useEffect(() => {
    if (claims && rebate) {
      checkRebateApplicability();
    }
  }, [bookingPageConfiguration, claims, rebate, checkRebateApplicability]);

  const eventExtract = (f: (key: formKey, value: any) => void) => {
    return (evt: React.ChangeEvent<any>) => {
      const key = evt.target.name as formKey;
      const value =
        evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
      f(key, value);
    };
  };

  const handleFormFieldChanged = (key: formKey, value: any) => {
    if (formValidationResults[key] === false) {
      validateFormField(key, value);
    }

    const maskedValue =
      masks[key]?.reduce((prev, m) => m(prev), value) || value;
    setFormField(key, maskedValue);
  };

  const validateFormField = (key: formKey, value: any) => {
    const valid = validators[key]?.every((v) => v(value));
    setFormValidationResults({...formValidationResults, [key]: valid});
  };

  const setFormField = (key: formKey, v: any) => {
    setForm({...form, [key]: v});
  };

  const formField = (key: formKey) => {
    return {
      name: key,
      disabled: isSubmitting,
      value: form[key] as string,
      onChange: eventExtract(handleFormFieldChanged),
      onBlur: eventExtract(validateFormField),
    };
  };

  const [error, setError] = useState<any>();

  if (!start || !profileId || !kind) {
    window.location.href = "/calendar";
    return <></>;
  }
  const submitBooking = async () => {
    if (isSubmitting) {
      return;
    }
    if (!reason || !bookingPageConfiguration) {
      return;
    }

    let mutableResults = {...formValidationResults};
    const formIsValid = Object.keys(form)
      .map((key: string) => {
        const valid = validators[key as formKey]?.every((v) =>
          v(form[key as formKey])
        );
        mutableResults = {...mutableResults, [key]: valid};
        return valid;
      })
      .every(Boolean);
    setFormValidationResults(mutableResults);
    if (!formIsValid) {
      return;
    }
    setIsSubmitting(true);
    if (bookingId) {
      try {
        await PostRescheduleBooking(bookingId, {
          ...form,
          profileId,
          start: new Date(start).toISOString(),
        });
        window.location.href = `/bookings/${bookingId}`;
        setIsSubmitted(true);
      } catch (error) {
        logger.error(error);
        setError(error);
      }
    } else {
      try {
        const slotKind = kind as SlotKind;
        await PostBookings({
          ...form,
          profileId,
          reason,
          slotKind,
          start: new Date(start).toISOString(),
          meta: {
            // Make sure the cookie values are stringified
            utm_source: ensureString(utmCookies.utm_source),
            utm_content: ensureString(utmCookies.utm_content),
            utm_campaign: ensureString(utmCookies.utm_campaign),
            utm_medium: ensureString(utmCookies.utm_medium),
            utm_term: ensureString(utmCookies.utm_term),
          },
        });

        window.location.href =
          bookingPageConfiguration.uiSettings.confirmationPageURL;
        setIsSubmitted(true);
      } catch (error) {
        logger.error(error);
        setError(error);
      }
    }
    setIsSubmitting(false);
  };

  if (!bookingPageConfiguration) {
    return (
      <>
        <BookingHeader
          leftCol={
            <>
              <Button
                outlined
                className={styles.backButtonDesktop}
                href={returnUrl}
                title="Välj en annan tid"
                startIcon={<ChevronLeft />}
              />

              <Button
                outlined
                className={styles.backButtonMobile}
                href={returnUrl}
                title={<ChevronLeft />}
              />
            </>
          }
          centerCol={
            <>
              {!bookingId && <h3>Bekräfta din bokning</h3>}
              {bookingId && <h3>Bekräfta din ombokning</h3>}
            </>
          }
          rightCol={<></>}
        />
        <ContentWrapper>
          <LoadingIndicator />
        </ContentWrapper>
      </>
    );
  }

  if (isSubmitted) {
    return (
      <>
        <BookingHeader
          leftCol={<></>}
          centerCol={
            <>
              {!bookingId && <h3>Bekräfta din bokning</h3>}
              {bookingId && <h3>Bekräfta din ombokning</h3>}
            </>
          }
          rightCol={<></>}
        />
        <ContentWrapper>
          <LoadingIndicator />
        </ContentWrapper>
      </>
    );
  }

  if (error) {
    const isBannedError = error && error.toString().endsWith("403");
    return (
      <>
        <BookingHeader
          leftCol={
            <>
              <Button
                outlined
                className={styles.backButtonDesktop}
                href={returnUrl}
                title="Välj en annan tid"
                startIcon={<ChevronLeft />}
              />

              <Button
                outlined
                className={styles.backButtonMobile}
                href={returnUrl}
                title={<ChevronLeft />}
              />
            </>
          }
          centerCol={<></>}
          rightCol={<></>}
        />
        <ContentWrapper>
          <AlternatePromo title="Bokningen kunde ej genomföras">
            {isBannedError && (
              <>
                <p>
                  Tyvärr kunde bokningen inte genomföras med ditt konto.
                  Vänligen kontakta vår kundtjänst för mer information.
                </p>
                <Button
                  primary
                  outlined
                  className={styles.backButtonDesktop}
                  href={"/contact"}
                  title="Kontakta kundtjänst"
                />
              </>
            )}
            {!isBannedError && (
              <>
                <p>
                  Tyvärr kunde bokningen inte genomföras då tiden inte längre är
                  tillgänglig.
                </p>
                <Button
                  primary
                  outlined
                  className={styles.backButtonDesktop}
                  href={returnUrl}
                  title="Välj en annan tid"
                />
              </>
            )}
          </AlternatePromo>
        </ContentWrapper>
      </>
    );
  }

  const endDate = new Date(
    new Date(start).getTime() +
      millisecondsFromNanos(bookingPageConfiguration.duration)
  );

  const originalPrice = bookingPageConfiguration.price;
  let price = originalPrice;
  if (rebate && rebateIsApplicable()) {
    if (rebate.isPercentage) {
      price = price - price * (rebate.value / 100);
    } else {
      price = price - rebate.value;
    }
  }

  const priceString =
    price === originalPrice
      ? `${price} SEK`
      : `<s>${originalPrice}</s> <b>${price}</b> SEK`;

  const termsText =
    bookingPageConfiguration.uiSettings.termsText !== ""
      ? bookingPageConfiguration.uiSettings.termsText
      : bookingPageConfiguration.isRevisit
      ? `Jag godkänner att besöket kostar ${priceString}. Frikort gäller ej.`
      : `Jag godkänner <a href="https://womni.se/anvandarvillkor" target="_blank">Womnis integritetspolicy</a>, att besöket är ${
          millisecondsFromNanos(bookingPageConfiguration.duration) /
          Milliseconds.Minute
        } minuter och kostar ${priceString}. Frikort gäller ej.`;

  return (
    <>
      <BookingHeader
        leftCol={
          <>
            <Button
              outlined
              className={styles.backButtonDesktop}
              href={returnUrl}
              title="Välj en annan tid"
              startIcon={<ChevronLeft />}
            />

            <Button
              outlined
              className={styles.backButtonMobile}
              href={returnUrl}
              title={<ChevronLeft />}
            />
          </>
        }
        centerCol={
          <>
            {!bookingId && <h3>Bekräfta din bokning</h3>}
            {bookingId && <h3>Bekräfta din ombokning</h3>}
          </>
        }
        rightCol={<></>}
      />
      <ContentWrapper className={styles.confirmBookingWrapper}>
        <div className={styles.bookingSummary}>
          {!bookingId && <h3>{bookingPageConfiguration.title}</h3>}
          {bookingId && <h3>Bekräfta ombokning av tid</h3>}
          {booking && (
            <div className={styles.previousBooking}>
              <Time
                className={styles.summaryDate}
                dateTime={booking.startsAt}
                options={{dateStyle: "full", timeStyle: undefined}}
              />
              <div className={styles.summaryHour}>
                <Time
                  dateTime={booking.startsAt}
                  options={{dateStyle: undefined, timeStyle: "short"}}
                />
                {` - `}
                <Time
                  dateTime={booking.endsAt}
                  options={{dateStyle: undefined, timeStyle: "short"}}
                />
              </div>
            </div>
          )}
          <div>
            <Time
              className={styles.summaryDate}
              dateTime={start}
              options={{dateStyle: "full", timeStyle: undefined}}
            />
            <div className={styles.summaryHour}>
              <Time
                dateTime={start}
                options={{dateStyle: undefined, timeStyle: "short"}}
              />
              {!bookingPageConfiguration.isRevisit && (
                <>
                  {` - `}
                  <Time
                    dateTime={endDate.toISOString()}
                    options={{dateStyle: undefined, timeStyle: "short"}}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        {rebate && rebateIsApplicable() && (
          <div className={styles.rebate}>
            <h3>Din rabatt är tillagd</h3>
            <p>
              Ditt besök rabateras med
              {rebate.isPercentage && <span> {rebate.value}%</span>}
              {!rebate.isPercentage && <span> {rebate.value} SEK</span>}.
              Rabatten är giltig t om{" "}
              <Time options={{dateStyle: "short"}} dateTime={rebate.endsAt} />
              .
              <br />
              <span className={styles.rebateCode}>
                Rabattkod: {rebate.code}
              </span>
            </p>
          </div>
        )}
        {rebate && rebateApplicability?.isConsumed && (
          <div className={styles.rebateError}>
            <h3>Du har redan använt denna rabattkod</h3>
            <p>
              Din rabatt kommer tyvärr ej läggas till då den redan är använd.
              <br />
              <span className={styles.rebateCode}>
                Rabattkod: {rebate.code}
              </span>
            </p>
          </div>
        )}
        {rebate && rebateApplicability?.isExpired && (
          <div className={styles.rebateError}>
            <h3>Rabattkoden har utgått</h3>
            <p>
              Din rabatt kommer tyvärr ej läggas till då den har utgått.
              Rabatten var giltig t om{" "}
              <Time options={{dateStyle: "short"}} dateTime={rebate.endsAt} />
              <br />
              <span className={styles.rebateCode}>
                Rabattkod: {rebate.code}
              </span>
            </p>
          </div>
        )}
        {profile && (
          <div className={styles.profile}>
            {profile.profilePicture && (
              <img
                src={profile.profilePicture.small}
                alt={`${profile.givenName} ${profile.surname}`}
              />
            )}
            {profile.givenName} {profile.surname}
            {bookingPageConfiguration.uiSettings.hideProfileTitle === false && (
              <>, {profile.medicalRoles.map(localizeMedicalRoles).join(", ")}</>
            )}
          </div>
        )}
        {patientProfile && (
          <div className={clsx(styles.profile, styles.patient)}>
            {patientProfile.givenName} {patientProfile.surname}, Patient
          </div>
        )}
        {claims && !patientProfileId && isConfirmingOwnBooking && (
          <div className={styles.claims}>
            <>
              Inloggad som {claims.givenName} {claims.surname}{" "}
              <a
                href={`${
                  process.env.REACT_APP_API_URL
                }/auth/logout?redirectURL=${encodeURIComponent(
                  window.location.href
                )}`}>
                inte du?
              </a>
            </>
          </div>
        )}
        <div className={styles.bookingForm}>
          <>
            {!claims && (
              <>
                <FormSection>
                  <Label htmlFor="givenName">Förnamn (obligatoriskt)</Label>
                  <Input {...formField("givenName")} type="text" />
                  {formValidationResults["givenName"] === false && (
                    <ValidationMessage>Skriv ditt förnamn</ValidationMessage>
                  )}
                </FormSection>
                <FormSection>
                  <Label htmlFor="surname">Efternamn (obligatoriskt)</Label>
                  <Input type="text" {...formField("surname")} />
                  {formValidationResults["surname"] === false && (
                    <ValidationMessage>Skriv ditt efternamn</ValidationMessage>
                  )}
                </FormSection>
              </>
            )}

            <FormSection>
              <Label htmlFor="email">E-postadress (obligatoriskt)</Label>
              <Input type="email" {...formField("email")} />
              {formValidationResults["email"] === false && (
                <ValidationMessage>
                  Skriv en giltig e-postadress
                </ValidationMessage>
              )}
            </FormSection>
            <FormSection>
              <Label htmlFor="telephoneNumber">
                Telefonnummer (obligatoriskt)
              </Label>
              <Input type="text" {...formField("telephoneNumber")} />
              {formValidationResults["telephoneNumber"] === false && (
                <ValidationMessage>Skriv ditt telefonnummer</ValidationMessage>
              )}
            </FormSection>
            {!claims && (
              <FormSection>
                <Label htmlFor="firstName">Personnummer (obligatoriskt)</Label>
                <Input
                  type="text"
                  maxLength={12}
                  placeholder="ÅÅÅÅMMDDXXXX"
                  {...formField("personalNumber")}
                />
                {formValidationResults["personalNumber"] === false && (
                  <ValidationMessage>
                    <ul>
                      <li>
                        Skriv ditt personnummer i följande format: ÅÅÅÅMMDDXXXX.{" "}
                      </li>
                      <li>
                        {" "}
                        Kontrollera att du inte råkat skriva en siffra fel.
                      </li>
                    </ul>
                  </ValidationMessage>
                )}
              </FormSection>
            )}
          </>

          <FormSection>
            <Label>
              Önskar du ha mötet över telefon eller digitalt videomöte?
            </Label>
            <RadioButtonList className={styles.meetingKindSelector}>
              <RadioButton
                disabled={isSubmitting}
                name="meetingKind"
                id="meetingKindVideo"
                checked={form.meetingKind === "phone"}
                onChange={() => handleFormFieldChanged("meetingKind", "phone")}
                label={"Telefonmöte"}
              />
              <RadioButton
                disabled={isSubmitting}
                name="meetingKind"
                id="meetingKindPhone"
                checked={form.meetingKind === "video"}
                onChange={() => handleFormFieldChanged("meetingKind", "video")}
                label={"Digitalt videomöte"}
              />
            </RadioButtonList>
            {formValidationResults["meetingKind"] === false && (
              <ValidationMessage className={styles.spacedValidationMessage}>
                Välj ett alternativ
              </ValidationMessage>
            )}
          </FormSection>

          <FormSection>
            <Checkbox
              id="hasAcceptedTerms"
              checked={form.hasAcceptedTerms}
              {...formField("hasAcceptedTerms")}
              dangerouslySetInnerHTML={{__html: termsText}}
            />
            {formValidationResults["hasAcceptedTerms"] === false && (
              <ValidationMessage className={styles.spacedValidationMessage}>
                Du behöver godkänna villkoren
              </ValidationMessage>
            )}
          </FormSection>

          <div className={styles.infoSection}>
            {bookingPageConfiguration.uiSettings.cancellationText}
          </div>

          <div className={styles.ctaContainer}>
            <Button
              disabled={isSubmitting}
              primary
              fullWidth
              title={booking ? "Bekräfta ombokning" : "Bekräfta bokning"}
              centered
              onClick={submitBooking}
            />
          </div>
          {isSubmitting && <LoadingIndicator />}
        </div>
      </ContentWrapper>
    </>
  );
};

export default ConfirmBooking;
