import Box from "../../components/Box";
import { ContentWrapper } from "../../components/layouts/Nav";
import SubnavPage from "../../components/layouts/SubnavPage";
import styles from "./infoPage.module.css";
const Hormones = () => {
  return (
    <SubnavPage backLink="/" title="Hormonbehandling">
      <ContentWrapper>
        <Box>
          <div className={styles.infoPage}>
            <h2>Allmänna råd Hormonbehandling</h2>
            <ul>
              <li>
                <a href="#behandling">Behandling med könshormoner</a>
              </li>
              <li>
                <a href="#information">
                  Information om behandling av könshormoner
                </a>
              </li>
              <li>
                <a href="#reaktioner">
                  Reaktioner vid uppstart av behandling med könshormoner
                </a>
              </li>
              <li>
                <a href="#blodning">Blödning</a>
              </li>
              <li>
                <a href="#hur-ta">Hur tar du de olika preparaten?</a>
                <ul>
                  <li>
                    <a href="#ostrogen">Östrogen</a>
                  </li>
                  <li>
                    <a href="#progesteron">Progesteron</a>
                  </li>
                  <li>
                    <a href="#ultrogestan">Utrogestan 200 mg</a>
                  </li>
                  <li>
                    <a href="#progesteron-100">Progesteron 100 mg</a>
                  </li>
                  <li>
                    <a href="#dhea">DHEA/ Testosteron</a>
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Om du varit på besök hos Womni och blivit ordinerad behandling med
              könshormoner så kan det vara bra att läsa på om behandlingen och
              kanske få svar på frågor som kan komma upp i efterhand. I texten
              nedan hittar du information om behandling med könshormoner baserad
              på vanliga frågor från våra patienter.
            </p>
            <h2 id="behandling">Behandling med könshormoner</h2>
            <p>
              Det är viktigt att förstå att behandling med könshormoner enbart
              har effekt om orsaken till ett förändrat mående beror på sviktande
              produktion av könshormoner. Det finns flera tillstånd som ger
              symtom som liknar klimakteriet men om orsaken är en annan
              föreligger inga skäl för att behandla med könshormoner. Till
              exempel är stress en vanlig orsak till att kvinnors mående
              påverkas. Då behöver du titta över hela din livssituation, kanske
              med hjälp av en terapeut.
            </p>
            <p>
              Hur behandling med könshormoner går till beror på vilken fas du
              befinner dig i och om din livmoder finns kvar. Är du i slutet av
              premenopaus ges vanligtvis behandling med progesteron under 14
              dagar per månad. Längre fram under perimenopaus eller efter
              menopaus kompletteras behandlingen med östrogen. Det är inte helt
              säkert att den första ordinationen passar dig utan ibland behövs
              justeringar eller byte av preparat.
            </p>
            <p>
              Det finns olika sätt till administrering men grunden i
              behandlingen är densamma. Har du din livmoder kvar behövs både
              östrogen och progesteron. Om livmodern är bortopererad räcker det
              med enbart östrogen. Östrogenet ger dig de största hälsovinsterna
              medan progesteron skyddar din livmodern mot cellförändringar genom
              att hålla slemhinnan i livmodern tunn.
            </p>
            <p>
              Förenklat går det att säga att östrogenet har effekt på
              värmevallningar, nattsvettningar, hjärtklappningar, nedstämdhet
              och sömn. Östrogen minskar risken att dö i förtid med ca 40% så
              länge du står på behandling.
            </p>
            <h2 id="information">Information om behandling av könshormoner</h2>
            <p>
              Könshormoner är potenta läkemedel och det är därför viktigt att du
              följer den ordination som läkaren förskrivit och inte laborerar på
              eget bevåg.
            </p>
            <p>
              Oavsett vad som står i bipacksedeln eller om farmaceuten säger
              något tar du det utskriva läkemedlet enligt läkarens ordination.
              Bipacksedeln är en vägledning men det är läkaren som har den
              reella kompetensen för var du behöver och på vilket sätt du ska ta
              det ordinerade läkemedlet.
            </p>
            <p>
              Det kan ta ett tag innan läkemedlet har effekt så fortsätt med
              ordinationen fram till det planerade uppföljningsbesöket. Under
              uppföljningsbesöket går du tillsammans med läkare eller barnmorska
              igenom vilka effekter läkemedlet har haft.
            </p>
            <p>
              Vid insättning av könshormoner skrivs det ut recept för 3 månader
              initialt och du bokas för ett återbesök efter 6 veckor och sedan
              12 veckor. Vid uppföljning efter 3 månader skrivs recept ut för
              max 9 månader och du sätts upp på väntelistan för tid 12 månader
              efter ditt första besök.
            </p>
            <h2 id="reaktioner">
              Reaktioner vid uppstart av behandling med könshormoner
            </h2>
            <p>
              Det är vanligt med initiala bieffekter vilka brukar vara
              övergående och inget att oroa sig över. De flesta biverkningarna
              förväntas försvinna inom 6-8 veckor. Om däremot bieffekten håller
              i sig längre eller blir för påfrestande kan dos eller läkemedel
              behöva justeras och uppföljningsbesöket kan behöva tidigareläggas.
              Drabbas du av svåra bieffekter behöver du sluta direkt och ta
              kontakt med WOMNI eller 1177 för vidare handläggning.
            </p>
            <p>Exempel på vanliga bieffekter;</p>
            <p>
              Av östrogen Spända, ömma bröst, speedad känsla, darrighet,
              svullen, hjärtklappning och blödning.
            </p>
            <p>
              Av progesteron eller gestagen Trötthet, huvudvärk, ömma bröst,
              nedstämd, ångest, illamående och blödning.{" "}
            </p>
            <h2 id="blodning">Blödning</h2>
            <p>
              Om blödning inträffar när du inte haft mens på länge eller om du
              får en blödning på en annan tidpunkt än vad du brukar behöver du
              snarast kontakta gynekolog för ultraljud och provtagning från
              livmoder.
            </p>
            <h2 id="hur-ta">Hur tar du de olika preparaten?</h2>
            <p>
              Det är viktigt att följa ordinationen som står på förpackningen.
              Här följer en kort summering över hur de vanligaste preparaten bör
              användas.
            </p>
            <h3 id="ostrogen">Östrogen</h3>
            <p>
              Östrogen finns att tillgå i tre olika varianter, alla innehåller
              bioidentiskt östrogen.
            </p>
            <ol>
              <li>
                I tablettform, enbart östrogen eller i kombination med gestagen
                eller dydrogesteron
              </li>
              <li>För tillförsel via huden med plåster, gel och spray.</li>
              <li>Lokalt östrogen för torra slemhinnor i slidan.</li>
            </ol>
            <p>
              Östrogenplåster byts två gånger per vecka, t.ex. söndag och
              onsdag.
            </p>
            <p>
              Lägg gärna in en påminnelse i kalendern i mobilen så kommer en
              notis när det är dags att byta.
            </p>
            <p>Östrogenplåster tas med fördel på morgonen.</p>
            <p>
              Huden ska vara torr och utan hudkräm. Håll gärna en hand på
              plåstret några sekunder så att plåstret fäster ordentligt.
              Observera att det förbrukade plåstret ska lämnas in till apoteket
              av miljöskäl.
            </p>
            <p>
              Östrogenspray och östrogengel tas med fördel dagligen på morgonen.
            </p>
            <p>
              Sprayen ska appliceras på torr och frisk hud på insidan av
              underarmen. Om det inte är möjligt kan det appliceras på insidan
              av låret. Gelen appliceras på insidan och utsidan av armen från
              handleden till axeln, och/eller insidan av låren. Sprid ut den så
              mycket som möjligt. Det är viktigt att gelen och sprayen har
              torkat in innan ett eventuellt husdjur bör komma nära området.
            </p>
            <h3 id="progesteron">Progesteron</h3>
            <p>
              Behandling med progesteron kan ske med tre olika typer av
              progesteron:
            </p>
            <ol>
              <li>bioidentiskt progesteron</li>
              <li>gestagen, syntetiskt framställt </li>
              <li>dydrogesteron, snarlikt det kroppsegna progesteronet</li>
            </ol>
            <p>
              Progesteron går att få som kapslar, tabletter, hormonspiral och
              tas antingen dagligen, några dagar per månad eller var tredje
              månad beroende på fas, dosering och orsak till behandling.
            </p>
            <h3 id="ultrogestan">Utrogestan 200 mg </h3>
            <p>
              Tar du med fördel på kvällen eftersom man kan bli trött av
              preparatet.
            </p>
            <p>
              Vaginalt administrering är mest effektivt för att motverka
              uppbyggnad av slemhinnan i uterus.
            </p>
            <p>Utrogestan 200 mg tages under 14 dagar i månaden.</p>
            <ol>
              <li>
                Om du har regelbunden blödning en gång per månad, börja räkna
                nästa första blödningsdag som dag 1 och börja med läkemedlet
                från dag 14 till dag 28. Räkna därefter kontinuerligt
                intervaller om 28 dagar med mensapp/kalender oavsett om det
                kommer blödning eller ej.
              </li>
              <li>
                Om du blöder oregelbundet eller inte haft blödning på länge
                (dock inte mer än 12 mån) börja med Utrogest när som helst och
                räkna det som dag 14 och följ sedan samma mönster som ovan.
              </li>
            </ol>
            <p>
              Räkna med att det kommer blödning ibland, men inte varje cykel.
              Normalt startar blödning dag 3-5 efter det man tagit första
              tabletten.
            </p>
            <h3 id="progesteron-100">Progesteron 100 mg</h3>
            <p>Utrogest 100 mg kan enbart fås med licensförskrivning.</p>
            <p>
              Det tar ungefär en vecka efter läkarbesöket innan
              licensförskrivningen är färdig och skickas in. Därefter
              ytterligare en tid innan läkemedelsverket har godkänt licensen.
            </p>
            <p>
              Under tiden går du till det apotek du vill hämta ut kapslarna från
              och informerar om att en licensförskrivning kommer att utfärdas
              för dig. Apoteket bekräftar licensansökan när den är inskickad,
              beställer hem och kontaktar dig när kapslarna finns att hämta ut.
            </p>
            <h3 id="dhea">DHEA/ Testosteron</h3>
            <p>
              Det finns ytterligare preparat att tillgå om vissa symtom kvarstår
              t.ex. om behandling inte har haft tillräcklig effekt på sexuell
              hälsa. Vi pratar då om testosteron och dehydroepiandrosteron,
              DHEA, vilket är ett förhormon till både testosteron och östrogen.
              Det finns inget vetenskapligt underlag för att testosteron har
              effekt på kognition, driv eller mental skärpa utan ges enbart för
              sexuell dysfunktion. DHEA kan provas om inte lokalt östrogen har
              effekt.
            </p>
            <p>
              Salvan ska inte strykas på samma ställe på kroppen utan variera.
              Annars risk för lite ökad hårväxt på stället.
            </p>
          </div>
        </Box>
      </ContentWrapper>
    </SubnavPage>
  );
};

export default Hormones;
