import Nav, {
  ContentMaxWidth,
  ContentWrapper,
} from "../components/layouts/Nav";
import QuestionnaireReminder from "../components/QuestionnaireReminder";
import InfoNav from "../components/InfoNav";
import { QuestionnaireResponse } from "../types";
import { GetQuestionnaireResponses } from "../api";
import { useContext, useEffect, useState } from "react";
import UpcomingBookingsPromo from "../components/UpcomingBookingsPromo";
import { BookingsContext } from "../contexts/BookingsContext";
import LoadingIndicator from "../components/LoadingIndicator";
import NoBookingsPromo from "../components/NoBookingsPromo";

const Home = () => {
  const [questionnaireResponses, setQuestionnaireResponses] = useState<
    QuestionnaireResponse[] | null
  >();
  useEffect(() => {
    GetQuestionnaireResponses().then(setQuestionnaireResponses);
  }, []);

  const { futureBookings, pastBookings } = useContext(BookingsContext);
  if (!questionnaireResponses || !futureBookings || !pastBookings) {
    return (
      <Nav highlightedSection="home">
        <LoadingIndicator />
      </Nav>
    );
  }
  const nextBooking = futureBookings.find((b) => b.cancelled === false);
  const totalNumBookings = futureBookings.length + pastBookings.length;
  const showQuestionnaireReminder =
    totalNumBookings > 0 && questionnaireResponses.length === 0;

  return (
    <Nav highlightedSection="home">
      <ContentMaxWidth>
        {showQuestionnaireReminder && <QuestionnaireReminder />}
        {nextBooking && <UpcomingBookingsPromo booking={nextBooking} />}
        {totalNumBookings === 0 && <NoBookingsPromo />}
      </ContentMaxWidth>
      <ContentWrapper>
        <InfoNav />
      </ContentWrapper>
    </Nav>
  );
};

export default Home;
