export type validator = (s: string | any) => boolean;
export type masker = (s: string) => string;

const emailRegex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
const phoneRegex = /^\+?([0-9]{2})\)?([0-9]{1,10})$/;

export const length =
  (len: number): validator =>
  (s: string) =>
    s.length === len;

export const notEmpty: validator = (s: string) => s.length > 0;

export const maxLength =
  (len: number): validator =>
  (s: string) =>
    s.length <= len;

export const minLength =
  (len: number): validator =>
  (s: string) =>
    s.length >= len;

export const validEmail: validator = (s: string) =>
  emailRegex.test(s.toLowerCase());

export const validSwedishPersonalNumber: validator = (s: string) => {
  // Sanity check reasonable ages.
  const year = Number(s.slice(0, 4));
  const currentYear = new Date().getFullYear();
  if (year > currentYear || currentYear - year > 120) {
    return false;
  }

  const sliced = s.slice(2);

  let sum = 0;
  const parity = sliced.length % 2;
  for (let i = 0; i < sliced.length; i++) {
    const n = parseInt(sliced.charAt(i));
    if (i % 2 !== parity) {
      sum += n;
    } else if (n > 4) {
      sum += 2 * n - 9;
    } else {
      sum += 2 * n;
    }
  }
  return sum % 10 === 0;
};

export const validPhoneNumber = (s: string) => phoneRegex.test(s);

export const maskCharacters =
  (allowed: string): masker =>
  (s: string) => {
    return s
      .split("")
      .map((c) => (allowed.includes(c) ? c : ""))
      .join("");
  };

export const maskLength =
  (len: number): masker =>
  (s: string) => {
    return s.substring(0, len);
  };
