import clsx from "clsx";
import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { BankIDSmall } from "../Svg";

import styles from "./buttons.module.css";

interface ButtonProps {
  title: React.ReactNode;
  disabled?: boolean;
  onClick?: MouseEventHandler | undefined;
}

export const BankIDButton: React.VFC<ButtonProps> = (props) => {
  const { title, ...rest } = props;
  return (
    <button className={styles.bankIDButton} {...rest}>
      <span>{title}</span>
      <BankIDSmall />
    </button>
  );
};

export const ButtonGroup: React.FC = ({ children }) => {
  return <div className={styles.buttonGroup}>{children}</div>;
};

interface GenericButtonProps extends ButtonProps {
  outlined?: boolean;
  startIcon?: React.ReactNode;
  primary?: boolean;
  secondary?: boolean;
  fullWidth?: boolean;
  centered?: boolean;
  className?: string;
  noWrap?: boolean;
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  disabledTitle?: string;
}

export const Button: React.VFC<GenericButtonProps> = (props) => {
  const {
    outlined,
    fullWidth,
    primary,
    secondary,
    centered,
    className,
    href,
    target,
    startIcon,
    title,
    noWrap,
    disabled,
    disabledTitle,
    ...rest
  } = props;

  if (href && !disabled) {
    if (isExternal(href)) {
      return (
        <a
          href={href}
          target={target}
          className={clsx(styles.button, className, {
            [styles.outlined]: outlined,
            [styles.primary]: primary,
            [styles.secondary]: secondary,
            [styles.fullWidth]: fullWidth,
            [styles.centered]: centered,
            [styles.hasStartIcon]: typeof startIcon !== "undefined",
          })}
          {...rest}
        >
          {startIcon && <div className={styles.startIcon}>{startIcon}</div>}
          <span className={clsx({ [styles.noWrap]: noWrap })}>{title}</span>
        </a>
      );
    }
    return (
      <Link
        to={href}
        target={target}
        title={(disabled && disabledTitle) || ""}
        className={clsx(styles.button, className, {
          [styles.outlined]: outlined,
          [styles.primary]: primary,
          [styles.secondary]: secondary,
          [styles.fullWidth]: fullWidth,
          [styles.centered]: centered,
          [styles.hasStartIcon]: typeof startIcon !== "undefined",
        })}
        {...rest}
      >
        {startIcon && <div className={styles.startIcon}>{startIcon}</div>}
        <span className={clsx({ [styles.noWrap]: noWrap })}>{title}</span>
      </Link>
    );
  }
  return (
    <button
      disabled={disabled}
      title={(disabled && disabledTitle) || ""}
      className={clsx(styles.button, className, {
        [styles.outlined]: outlined,
        [styles.primary]: primary,
        [styles.secondary]: secondary,
        [styles.fullWidth]: fullWidth,
        [styles.centered]: centered,
        [styles.hasStartIcon]: typeof startIcon !== "undefined",
      })}
      {...rest}
    >
      {startIcon && <div className={styles.startIcon}>{startIcon}</div>}
      <span className={clsx({ [styles.noWrap]: noWrap })}>{title}</span>
    </button>
  );
};

const isExternal = (url: string): boolean => {
  const tmp = document.createElement("a");
  tmp.href = url;
  return tmp.host !== window.location.host;
};
